<template>
  <div class="dashboard-content">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-md-2">Blogs</h4>
            <div class="row mb-md-2">
              <div class="col-6 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <span class="mr-1">Show</span>
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    ><span class="ml-1">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-6 col-md-6">
                <div
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">

                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  table-class="table table-centered w-100"
                  :busy="!isLoading"
                  :items="blogs"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template v-slot:cell(title)="data">
                  <div class="">
                    <img class="img-thumbnail img-fluid mr-2" :src="data.item.image"
                         style="width:50px; height:50px;" itemprop="thumbnail" alt="Image
                                          description"/>
                    {{
                      data.item.title
                    }}
                  </div>
                </template>

                <template v-slot:cell(body)="data">
                  <div class="text-capitalize" v-html="data.item.body.slice(0,300)"></div>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ formatDate(data.item['created_at']) }}
                </template>
                <template v-slot:cell(Action)="data">
                  <router-link :to="'/blog/'+data.item['slug']" class="btn btn-primary btn-sm">Edit</router-link>
                  <button class="btn btn-danger btn-sm" @click="deleteBlog(data.item.id)">Delete</button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Invoices -->
    </div>
    <!-- <admin-mobile-footer/> -->
  </div>
</template>

<script>
import moment from "moment";
import {destroy, index} from "@/api/resource";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      blogs: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      fields: [
        {
          key: "title",
          sortable: true,
          label: "Title",
        },
        {
          key: "body",
          sortable: true,
          label: "Content",
        },
        {
          key: "created_at",
          sortable: true,
          label: "Published Date",

        },
        'Action'
      ],
      isLoading: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.blogs.length;
    },
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteBlog(blog_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          destroy(`/api/delete-blog`, blog_id).then(res => {
            this.getBlogs();
            Swal.fire(
                "Success!",
                "Blog has been deleted successfully.",
                "success"
            );
          });
        }
      });
    },
    getBlogs() {
      this.isLoading = true;
      index("/api/get-blogs")
          .then(res => {
            console.log(res.data)
            this.blogs = res.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    formatDate(d) {
      return d ? moment(d).format("Do MMMM, YYYY") : "";
    }
  },
  created() {
    this.getBlogs();
  }
};
</script>

import Vue from "vue";
import Router from "vue-router";

//routes
import frontendRoutes from "./frontend";
import adminendRoutes from "./admin";
import customerRoutes from "./customer";
import BookSalon from "../views/pages/BookSalon";
import superAdmin from "./superAdmin";
import SetUpProfileDetails from "../views/adminPages/SetUpProfileDetails";

// session components
const SignUp = () => import("Views/session/SignUp");
const SignUpAsSaloon = () => import("Views/session/SignUpAsSaloon");
const Login = () => import("Views/session/Login");
const Logout = () => import("Views/session/Logout");
const ErrorPage = () => import("Views/session/ErrorPage");
const ForgotPassword = () => import("Views/session/ForgotPassword");
const ComingSoon = () => import("Views/session/ComingSoon");

Vue.use(Router);

export const constantRoutes = [
  frontendRoutes,
  adminendRoutes,
  customerRoutes,
  superAdmin,

  {
    path: "/booking/:slug",
    component: BookSalon,
    meta: {
      title: "Salon Booking"
      // requiresVisitor: true
    }
  },

  {
    path: "/setup-profile",
    component: SetUpProfileDetails,
    meta: {
      requiresAuth: true,
      requiresProvider: true,
      title: "Setup Profile"
    }
  },



  {
    path: "/sign-up",
    component: SignUp,
    meta: {
      title: "Signup",
      requiresVisitor: true
      // breadcrumb: 'Session / Sign Up'
    }
  },
  {
    path: "/sign-up-business",
    component: SignUpAsSaloon,
    meta: {
      title: "Salon Signup",
      requiresVisitor: true
      // breadcrumb: 'Session / Sign Up'
    }
  },
  {
    path: "/login",
    component: Login,
    meta: {
      title: "Login",
      requiresVisitor: true
      // breadcrumb: 'Session / Login'
    }
  },
  {
    path: "/logout",
    component: Logout,
    meta: {
      title: "Logout",
      requiresAuth: true
      // breadcrumb: 'Session / Login'
    }
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
      requiresVisitor: true
      // breadcrumb: 'Session / Forgot Password'
    }
  },
  {
    path: "/session/coming-soon",
    component: ComingSoon,
    meta: {
      title: "Coming Soon",
      requiresVisitor: true
      // breadcrumb: 'Session / Reset Password'
    }
  },
  {
    path: "/404",
    component: ErrorPage
  },
  {
    path: "*",
    redirect: "/404"
  }
];
// })

export const asyncRoutes = [
  adminendRoutes,
  // tableRouter,
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/", hidden: true }
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

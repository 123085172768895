<template>
  <div class="dashboard-content">
    <div class="container-fluid">
      <div class="row responsive-row">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="">
              <full-calendar :config="config" :events="events" ref="calendar"/>
            </div>
          </div>
        </div>
      </div>
      <admin-mobile-footer/>
      <!--  successful appointment  modal-->
      <b-modal v-if="bookingDetails"
               id="modal-1"
               size="md"
               v-model="bookingDetailsModal"
               title="Appointment Details"
               header-close-variant="light"
               title-class="font-18"
               hide-footer
      >
        <div class="row">
          <div class="col-md-12">
            <h6></h6>
          </div>
          <div class="col-md-12">
            <div class="mb-2">Reference: {{ bookingDetails.appointment_reference }}</div>
            <div class="mb-2">Date: <i class="fa fa-calendar-o"/> {{ bookingDetails.date_time | toBookingDateTime }}
            </div>
            <div class="mb-2">Time: {{ bookingDetails.start_time }}</div>
            <div class="mb-2">Status: <span class="badge badge-info">{{ bookingDetails.status }}</span></div>
          </div>
        </div>
        <h6 class="border-bottom mt-4 mb-3">Customer Info</h6>
        <div class="row">
          <div class="col-9">
            <div>
              {{ bookingDetails.user.name }}
            </div>
          </div>
          <div class="col-3 text-right">
            <a v-if="bookingDetails.phone_number"
               :href="`tel:${bookingDetails.phone_number}`">{{ bookingDetails.phone_number }}</a>
            <div v-else>N/A</div>
          </div>
        </div>
        <h6 class="border-bottom mt-4 mb-3">Services Requested</h6>
        <div class="row" v-for="(s,i) in bookingDetails.service" :key="i">
          <div class="col-9">
            <div>
              {{ s.service_name }}
            </div>
          </div>
          <div class="col-3 text-right">
            {{ s.service_price | toCurrencyFormatRaw }}
          </div>
        </div>
        <div v-if="bookingDetails.service.length < 1">N/A</div>


        <h6 class="border-bottom mt-4 mb-3">Payment Info</h6>
        <div class="row">
          <div class="col-8">
            <div class="h6">Sub Total</div>
          </div>
          <div class="col-4">
            <div class="text-right">{{ bookingDetails.sub_total | toCurrencyFormatRaw }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="h6">Service Charge</div>
          </div>
          <div class="col-4">
            <div class="text-right">{{ bookingDetails.service_charge | toCurrencyFormatRaw }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="h5">Total</div>
          </div>
          <div class="col-4">
            <div class="text-right text-primary">{{ bookingDetails.total | toCurrencyFormatRaw }}</div>
          </div>
        </div>
        <div class="row" v-if="bookingDetails.deposit >0">
          <div class="col-8">
            <div class="h6">Deposit</div>
          </div>
          <div class="col-4">
            <div class="text-right text-danger"> {{ bookingDetails.deposit | toCurrencyFormatRaw }}</div>
          </div>
        </div>
        <div class="row" v-if="bookingDetails.outstanding_balance >0">
          <div class="col-8">
            <div class="h5">Outstanding Balance</div>
          </div>
          <div class="col-4">
            <div class="text-right text-primary">{{ bookingDetails.outstanding_balance| toCurrencyFormatRaw }}</div>
          </div>
        </div>
      </b-modal>

      <!--  end of successful appointment  modal-->
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from "vuex";
import {FullCalendar} from "vue-full-calendar";

const {mapActions} = createNamespacedHelpers("appointment");
import {update, index, store, destroy} from "@/api/resource";

export default {
  data() {
    var self = this;
    return {
      salonInfo: this.$store.state.user.salon,
      events: [],
      bookingDetails: null,
      bookingDetailsModal: false,
      config: {
        defaultView: "month",
        header: {
          left: "prev,next,today",
          center: "title",
          right: "agendaWeek,agendaDay,month",
        },

        slotDuration: "00:30:00",
        minTime: "06:00:00",
        maxTime: "22:00:00",
        selectable: false,
        editable: true,
        contentHeight: "auto",
        height: "auto",
        eventRender: function (info, event, element) {
          event.find(".fc-time").append(" (<span>" + info.status + "</span>)");
          event
              .find(".fc-title")
              .append(
                  '<div class="hr-line-solid-no-margin"></div><span style="font-size: 12px">' +
                  info.description +
                  "</span></div><div>" + process.env.VUE_APP_PRICE_SYMBOL +
                  info.price +
                  "</div>"
              );
        },
        eventClick: function (info) {
          self.bookingDetails = info.data;
          self.bookingDetailsModal = true;
        },
      },
    };
  },
  components: {
    FullCalendar,
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter"),
  },
  methods: {
    ...mapActions(["delete", "index"]),
  },

  mounted() {
    this.$loadScript(
        "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {
      let url = "/api/appointments/";
      if (this.$store.state.user.user.role === "ADMIN") {
        url =
            "/api/appointments?user_id=" + this.$store.state.user.salon.user_id;
      }
      index(url).then((res) => {
        let appointments = res.data.appointments;
        this.events = appointments.map((e) => {
          const serviceNames = e.service.map((service) => service.service_name).join(', ');

          return {
            title: (e.user || {}).name,
            description: serviceNames,
            additional_notes: e.additional_notes,
            price: e.sub_total,
            data: e,
            start_time: e.start_time,
            end_time: e.end_time,
            date: e.date_time + " " + e.start_time,
            start: moment(e.date_time + " " + e.start_time).toDate(),
            end: moment(e.date_time + " " + e.end_time).toDate(),
            editable: false,
            className:
                new Date(e.date_time) < new Date() ? "event-rose" : "event-green",
            status:
                new Date(e.date_time) < new Date()
                    ? "Past Booking"
                    : "Active Booking",
          };
          // e.title = e.name;
        });
        // console.log(this.events, "events");
      });
    });
  },
};
</script>

<style scoped>
@media screen and (min-width: 300px) and (max-width: 500px) {
  #calendar {
    width: 100% !important;
    margin: 0 auto;
    font-size: 17px;
  }
}

>>> .fc-toolbar .fc-center h2 {
  margin-top: 10px !important;
}


</style>

export const menus = [
    // {
    // 	state: '/home',
    // 	name: 'Home',
    // 	type: 'sub',
    // 	children: [
    // 		{state: '/home/version1', name: 'Search by Style', type: 'link'},
    // 		{state: '/home/version2', name: 'Search by Salon', type: 'link'}
    // 	]
    // },

    {
        state: '/',
        name: 'Home',
        type: 'link',
    },
    {
        state: '/salons',
        name: 'Salons',
        type: 'link',
    },

    // {
    //     state: '/home',
    //     name: 'Search',
    //     type: 'sub',
    //     children: [{
    //             state: '/listing/list/with-sidebar',
    //             name: 'Search By Style',
    //             type: 'link'
    //         },
    //         {
    //             state: '/listing/grid/full-width-map',
    //             name: 'Search By Salon',
    //             type: 'link'
    //         },
    //     ]
    // },
    // {
    //     state: '/list',
    //     name: 'List Layout',
    //     type: 'sub',
    //     children: [{
    //             state: '/listing/list/with-sidebar',
    //             name: 'With Sidebar',
    //             type: 'link'
    //         },
    //         {
    //             state: '/listing/list/full-width',
    //             name: 'Full Width',
    //             type: 'link'
    //         },
    //         {
    //             state: '/listing/list/full-width-map',
    //             name: 'Full Width + Map',
    //             type: 'link'
    //         }
    //     ]
    // },
    // {
    //     state: '/grid',
    //     name: 'Grid Layout',
    //     type: 'sub',
    //     children: [
    //         {state: '/listing/grid/with-sidebar', name: 'With Sidebar', type: 'link'},
    //         {state: '/listing/grid/full-width', name: 'Full Width', type: 'link'},
    //         {state: '/listing/grid/full-width-map', name: 'Full Width + Map', type: 'link'}
    //     ]
    // },
    // {
    //     state: '/half-map',
    //     name: 'Half Screen Map',
    //     type: 'sub',
    //     children: [
    //         {state: '/listing/half-map/list', name: 'Layout 1', type: 'link'},
    //         {state: '/listing/half-map/grid', name: 'Layout 2', type: 'link'}
    //     ]
    // },
    // {
    //     state: '/detail',
    //     name: 'Detail',
    //     type: 'sub',
    //     children: [{
    //             state: '/listing/detail/version1/1',
    //             name: 'Version1',
    //             type: 'link'
    //         },
    //         {
    //             state: '/listing/detail/version2',
    //             name: 'Version2',
    //             type: 'link'
    //         }
    //     ]
    // },
    // ]

    // {
    //     state: '/admin',
    //     name: 'Salon Panel',
    //     type: 'sub',
    //     children: [{
    //             state: '/admin/dashboard',
    //             name: 'Dashboard',
    //             type: 'link'
    //         },
    //         // {state: '/admin/messages', name: 'Messages', type: 'link'},
    //         {
    //             state: '/admin/bookings',
    //             name: 'Bookings',
    //             type: 'link'
    //         },
    //         // {state: '/admin/reviews', name: 'Reviews', type: 'link'},
    //         {
    //             state: '/admin/bookmarks',
    //             name: 'Favourites',
    //             type: 'link'
    //         },
    //         {
    //             state: '/admin/list',
    //             name: 'My Posts',
    //             type: 'link'
    //         },
    //         // {state: '/admin/add-list', name: 'Add List', type: 'link'},
    //         {
    //             state: '/admin/profile',
    //             name: 'Edit Salon Profile',
    //             type: 'link'
    //         }
    //     ]
    // },
    {
        state: '/pages',
        name: 'Pages',
        type: 'sub',
        children: [
            // {state: '/pages/pricing', name: 'Pricing', type: 'link'},
            // {state: '/pages/invoice', name: 'Invoice', type: 'link'},
            {
                state: '/pages/booking',
                name: 'Booking',
                type: 'link'
            },
            {
                state: '/pages/add-listing',
                name: 'Add Listing',
                type: 'link'
            },
            {
                state: '/pages/user-profile',
                name: 'Personal Profile',
                type: 'link'
            },
            // {
            // 	state: '/blog',
            // 	name: 'blog',
            // 	type: 'sub',
            // 	children: [
            // 		{state: '/pages/blog/list', name: 'List', type: 'link'},
            // 		{state: '/pages/blog/detail', name: 'Detail', type: 'link'}
            // 	]
            // },
            // {state: '/pages/about-us', name: 'About', type: 'link'},
            // {state: '/pages/contact-us', name: 'Contact', type: 'link'}
        ]
    },
    {
        state: '/session',
        name: 'Session',
        type: 'sub',
        children: [{
                state: '/session/login',
                name: 'Login',
                type: 'link'
            },
            {
                state: '/session/sign-up',
                name: 'Signup',
                type: 'link'
            },
            {
                state: '/session/sign-up-saloon',
                name: 'Saloon Signup',
                type: 'link'
            },
            {
                state: '/session/forgot-password',
                name: 'Forgot Password',
                type: 'link'
            },
            {
                state: '/session/coming-soon',
                name: 'Coming Soon',
                type: 'link'
            }
        ]
    },
    {
        state: '/session',
        name: 'Profile',
        type: 'sub',
        children: [{
                state: '/admin/dashboard',
                name: 'Dashboard',
                type: 'link'
            },
            {
                state: '/session/logout',
                name: 'LogOut',
                type: 'link'
            },
        ]
    }
]

// export default menus;

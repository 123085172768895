<!-- Header Structure -->
<template>
  <nav id="navigation" class="style-1">
    <ul id="responsive">
<!--      <li>-->
<!--        <router-link class="current blink_me" to="/giveaway">**GIVEAWAYS**</router-link>-->
<!--      </li>-->
      <li
        v-for="(menuitem, i) in menuItems"
        v-bind:key="i"
        :class="{ 'open-menu': selectedMenu == menuitem.name }"
      >
        <router-link
          class="current"
          :to="menuitem.state"
          v-if="menuitem.type === 'link'"
          >{{ menuitem.name }}</router-link
        >

        <a
          class="current"
          v-if="menuitem.type === 'sub'"
          @click="menuClick(menuitem.name)"
          >{{ menuitem.name }}</a
        >
        <ul v-if="menuitem.type === 'sub'">
          <li
            v-for="childitem in menuitem.children"
            v-bind:key="childitem.name"
            :class="{ 'open-menu': selectedSubMenu == childitem.name }"
          >
            <router-link :to="childitem.state" v-if="childitem.type === 'link'"
              >{{ childitem.name }}
            </router-link>
            <a
              v-if="childitem.type === 'sub'"
              @click="subMenuClick(childitem.name)"
              >{{ childitem.name }}</a
            >
            <ul v-if="childitem.type === 'sub'">
              <li
                v-for="subchilditem in childitem.children"
                v-bind:key="subchilditem.name"
              >
                <router-link :to="subchilditem.state">{{
                  subchilditem.name
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { menus } from "./frontend-menu-items";
import { mapGetters } from "vuex";

export default {
  name: "FrontEndMenu",
  data() {
    return {
      menuItems: menus,
      selectedMenu: null,
      selectedSubMenu: null
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  methods: {
    filterMenu() {
      if (this.$store.state.user.user == null) {
        this.menuItems = menus.filter(e => {
          if (e.name !== "Log Out" && e.name !== "Dashboard") {
            return e;
          }
        });
      } else {
        let user_role = this.$store.state.user.user.role;
        this.menuItems = menus.filter(e => {
          if (
            e.name !== "Sign Up" &&
            e.name !== "Login" &&
            e.query !== "partner"
          ) {
            return e;
          }
        });
        if (user_role.toLowerCase() === "admin") {
          this.menuItems = this.menuItems.filter(e => {
            if (e.auth !== "provider" && e.auth !== "consumer") {
              return e;
            }
          });
        } else if (user_role.toLowerCase() === "provider") {
          this.menuItems = this.menuItems.filter(e => {
            if (e.auth !== "admin" && e.auth !== "consumer") {
              return e;
            }
          });
        } else if (user_role.toLowerCase() === "consumer") {
          this.menuItems = this.menuItems.filter(e => {
            if (e.auth !== "provider" && e.auth !== "admin") {
              return e;
            }
          });
        }
      }
    },
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(error => {
          console.log(error);
        });
    },
    menuClick(value) {
      if (this.selectedMenu === value) {
        this.selectedMenu = null;
      } else {
        this.selectedMenu = value;
      }
    },

    subMenuClick(value) {
      if (this.selectedSubMenu === value) {
        this.selectedSubMenu = null;
      } else {
        this.selectedSubMenu = value;
      }
    }
  },

  created() {
    this.filterMenu();
  }
};
</script>

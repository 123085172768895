<template>
  <div style="background: #eeee">
    <div class="container p-0">
      <div class="col-md-12" v-if="$store.state.user.user.role === 'ADMIN'">
        <div class="pt-4 text-muted">
          <router-link to="/super-admin/home">
            <i class="fa fa-arrow-circle-left" /> Go to dashboard
          </router-link>
        </div>
      </div>
      <div class="col-md-12" v-else>
        <div class="pt-4 text-muted">
          <router-link to="/">
            <i class="fa fa-arrow-circle-left" /> Go to home
          </router-link>
        </div>
      </div>
      <div class="d-flex align-items-center" style="min-height: 100vh">
        <div class="col-md-12">
          <div v-if="isLoading" class="mb-1 text-center">
            <b-spinner small ></b-spinner> Please wait...
          </div>
          <!-- Section -->
          <div
            class="add-listing-section mb-4 text-center"
            v-if="sign_up_stage === 1"
          >
            <!-- Title -->
            <img
              src="/static/images/setup-profile.png"
              class="img-fluid"
              width="100"
              alt=""
            />
            <h3>Let's create your Business Bio page...</h3>
            <p>
              Before you begin we recommend you have all the information about
              your business to hand, such as images. This will take
              approximately 3 mins to complete.
            </p>
            <button class="btn btn-primary" @click="sign_up_stage = 2">
              Begin
            </button>
          </div>
          <!-- Section / End -->

          <!-- Section -->
          <form
            class=""
            v-if="sign_up_stage == 2"
            @submit.prevent="createBusinessProfile()"
          >
            <div class="text-center mb-4">
              <img
                src="/static/images/logo.png"
                class="img-fluid"
                width="65"
                alt=""
              />
            </div>
            <div class="row add-listing-section mb-4 mt-0">
              <div class="col-md-8 offset-md-2">
                <div class="text-right">
                  <div class="badge badge-primary">Required</div>
                </div>
                <h5>What is the name of your business?</h5>
                <input
                  type="text"
                  required
                  v-model="bio_info.business_name"
                  class="form-control form-control-alternative"
                />
              </div>
            </div>

            <div class="row add-listing-section mb-4 mt-0">
              <div class="col-md-8 offset-md-2">
                <div class="text-right">
                  <div class="badge badge-primary">Required</div>
                </div>
                <h5>Which city is your business located in?</h5>
                <input
                    type="text"
                    required
                    v-model="bio_info.region"
                    class="form-control form-control-alternative"
                />
              </div>
            </div>

            <div class="row add-listing-section mb-4">
              <div class="col-md-8 offset-md-2">
                <div class="text-right">
                  <div class="badge badge-primary">Required</div>
                </div>
                <h5>What is the phone number of your business?</h5>
                <input
                  type="text"
                  v-model="bio_info.phone_number"
                  required
                  class="form-control form-control-alternative"
                />
              </div>
            </div>

            <div class="row add-listing-section mb-4">
              <div class="col-md-8 offset-md-2">
                <h5>Enter a short description of your business</h5>
                <textarea
                  class="form-control form-control-alternative"
                  placeholder="Description"
                  rows="3"
                  v-model="bio_info.description"
                />
              </div>
            </div>

            <div class="text-center" style="margin-bottom: 7rem">
              <button
                  type="submit" :disabled="isLoading"
                  class="btn btn-primary"
              >
                <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Next' }} <i class="fa fa-arrow-right"/>
              </button>
            </div>
          </form>
          <!-- Section / End -->
          <!-- Section -->
          <div class="mb-4" v-if="sign_up_stage == 3">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>What services do you offer ?</h3>
              <p>Select all that apply</p>
            </div>
            <div class="row">
              <div
                class="col-md-2 col-6"
                v-for="(b, i) in business_categories"
                :key="i"
              >
                <div
                  class="card mb-4 cursor-pointer"
                  style="height: 155px"
                  @click="selectCategory(b)"
                  :class="[
                    getSelectedCategoryClass(b) ? 'activate-category' : '',
                  ]"
                >
                  <img
                    :src="b['service_category_image']"
                    class="card-img-top"
                    style="height: 85px; object-fit: cover"
                    alt="..."
                  />
                  <div class="card-body text-center">
                    <h6>{{ b["service_category_name"] }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3" style="margin-bottom: 7rem">
              <button
                  type="submit"
                  @click="createSalonCategory()" :disabled="isLoading"
                  class="btn btn-primary"
              >
                <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Save and continue' }}  <i class="fa fa-arrow-circle-right" v-if="!isLoading" />
              </button>
            </div>
          </div>
          <form
            class=""
            v-if="sign_up_stage === 4"
            @submit.prevent="createBusinessLocation()"
          >
            <div class="text-center mb-4">
              <img
                src="/static/images/logo.png"
                class="img-fluid"
                width="65"
                alt=""
              />
            </div>
            <div class="row add-listing-section mb-4 mt-0">
              <div class="col-md-12 mb-3">
                <div class="text-right">
                  <div class="badge badge-primary">Required</div>
                </div>
              </div>
              <div class="col-md-7">
                <h5>What is the address of your Business?</h5>
                <input
                  type="text"
                  id="autocomplete"
                  required
                  class="form-control form-control-alternative mb-3"
                  placeholder="Start typing an address..."
                />
              </div>
              <div class="col-md-5">
                <div id="map"></div>
              </div>
            </div>

            <div class="text-center" style="margin-bottom: 7rem">
              <button
                  type="submit" :disabled="isLoading"
                  class="btn btn-primary"
              >
                <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Next' }}  <i class="fa fa-arrow-circle-right" v-if="!isLoading" />
              </button>
            </div>
          </form>
          <div v-if="sign_up_stage === 5">
            <div class="text-center mb-4">
              <img
                src="/static/images/logo.png"
                class="img-fluid"
                width="65"
                alt=""
              />
            </div>
            <div class="row add-listing-section mb-4 mt-0">
              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-8">
                    <h3 style="font-weight: normal">Set Your Availability</h3>
                    <p>
                      Let your users know when you're typically available for
                      booking
                    </p>
                  </div>
                  <div class="col-md-4">
                    <img
                      src="/static/images/availability.png"
                      class="img-fluid"
                      style="width: 100px"
                      alt=""
                    />
                  </div>
                </div>
                <h6 style="font-weight: normal">Available Hours</h6>
                <div class="row">
                  <div class="col-6">
                    <input
                      type="time"
                      id="time1"
                      v-model="start_time"
                      class="form-control"
                      value="09:00"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="time"
                      v-model="end_time"
                      id="time2"
                      class="form-control"
                      value="17:00"
                    />
                  </div>
                </div>
                <h6 style="font-weight: normal" class="mt-3">Available Days</h6>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="list-group list-group-horizontal-sm text-center">
                      <li
                        class="list-group-item"
                        v-for="(data, index) in all_time_slot.timeslot"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          v-model="data.checked"
                          :id="data.day"
                        />
                        <br />
                        <label :for="data.day">{{ data.day }}</label>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-12 mt-5">
                    <p class="text-center">
                      <i class="fa fa-bullhorn" /> Don't worry! You'll be able
                      to further customize your availability later on.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center" style="margin-bottom: 7rem">
              <button @click="skipBooking" :disabled="isLoading"
                      class="btn btn-link text-muted"
              >
                <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Set up later' }}
              </button>
              <button @click="addBooking" :disabled="isLoading"
                  class="btn btn-primary"
              >
                <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Next' }}  <i class="fa fa-arrow-circle-right" v-if="!isLoading" />
              </button>
            </div>
          </div>

          <div v-if="sign_up_stage === 6">
            <form @submit.prevent="submitBusinessImages()">
              <div class="row add-listing-section mb-4 mt-0">
                <div class="col-md-12">
                  <!-- Dropzone -->
                  <h5>Select images for your business</h5>
                  <small class="text-danger" v-if="(3-results.length) === 1">Upload {{(3-results.length)}} more image</small>
                  <small class="text-danger" v-else-if="(3-results.length) === 2">Upload {{(3-results.length)}} more image</small>
                  <small class="text-danger" v-else>Upload 3 or more images</small>

                  <div class="d-flex mt-2">
                    <div class="roll-element" v-for="(r,i) in results" :key="i">
                      <div>
                        <img :src="r" @click="recreateImage(r)" alt="" class="img-fluid img-thumbnail result-img">
                        <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number" @click="removeCroppedImage(i)"><i class="fa fa-close"></i></span>
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="roll-element">
                      <div @click="$refs.file.click()">
                        <input type="file" accept="image/*" ref="file" class="d-none" @change="selectedFile"/>
                        <button type="button" class="cg-btn-upload" style="height:57px;"><i class="fa fa-image"></i>
                        </button>
                        <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number"><i class="fa fa-plus-circle" style="color: #adb5bdcc;"/></span>
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="text-center" style="margin-bottom: 7rem">
                <button type="submit" :disabled="isLoading || results.length<3"
                        class="btn btn-primary"
                >
                  <b-spinner small v-if="isLoading"></b-spinner> {{ isLoading?'Submitting...':'Submit' }}
                </button>
                </div>
            </form>
          </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Service Images</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="img-container">
                  <img id="cropper" :src="displayImage" class="img-fluid img-thumbnail" style="max-height:350px"/>
                </div>
                <div class="mt-3 mb-3 text-center">
                  <button type="button" class="btn btn-info btn-sm" @click="cropImage()">Crop Image</button>
                </div>
                <hr class="mt-0 mb-3"/>
                <div class="d-flex">
                  <div class="roll-element" v-for="(r,i) in results" :key="i">
                    <div>
                      <img :src="r" @click="recreateImage(r)" alt="" class="img-fluid img-thumbnail result-img">
                      <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number" @click="removeCroppedImage(i)"><i class="fa fa-close"></i></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="roll-element">
                    <div @click="$refs.file.click()">
                      <input type="file" accept="image/*" ref="file" class="d-none" @change="selectedFile"/>
                      <button class="cg-btn-upload"><i class="fa fa-image"></i></button>
                      <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number"><i class="fa fa-plus-circle" style="color: #adb5bdcc;"/></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Copyrights -->
        <mobilefooter />
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "vue2-dropzone";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { store } from "@/api/resource";
import { setUser, setSalon } from "@/utils/auth";
import $ from "jquery";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import {convertToHoursAndMinutes, convertToMinutes} from "@/utils/externalFxns";

export default {
  name: "set-up-profile",
  components: {
    Dropzone,
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      results: [],
      cropper: null,
      displayImage: '/img/picture.jpg',
      fileDetails: {
        mimeType: 'image/jpg',
        name: ''
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 2,
        headers: { "My-Awesome-Header": "header value" },
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,",
        addRemoveLinks: true,
      },
      timeSlotCorrect: false,
      booking_days: [],
      start_time: "09:00",
      end_time: "17:00",
      all_time_slot: {
        booking_limit: 1,
        slot_hours: 0,
        slot_mins: 60,
        available_days: [],
        disabled_days: [],
        available_days_strings: [],
        disabled_days_strings: [],
        advanced_booking: 1,
        timeslot: [
          {
            day: 'monday',
            value: 1,
            checked: true,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'tuesday',
            value: 2,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'wednesday',
            value: 3,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'thursday',
            value: 4,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'friday',
            value: 5,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'saturday',
            value: 6,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          {
            day: 'sunday',
            value: 0,
            checked: false,
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          }
        ],
      },
      disableButton: true,
      business_categories: [],
      selectedCategories: [],
      service: {
        images: [],
      },
      sign_up_stage: 0,
      bio_info: {
        business_name: "",
        phone_number: "",
        region: "",
        description: "",
        user_id: "",
        sign_up_stage: 1,
      },
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      autocomplete: "",
      business_address: {
        lat: 6.765809,
        lng: -1.107311,
      },
      salonInfo: this.$store.state.user.salon,
      userInfo: this.$store.state.user.salon.user,
    };
  },
  watch: {
    sign_up_stage: function () {
      if (this.sign_up_stage === 4) {
        setTimeout(() => {
          this.executeMap();
          this.loadSideMap();
        }, 1000);
      }
    },
  },
  created() {
    if (this.userInfo["sign_up_stage"] < 7) {
      this.bio_info.user_id = this.userInfo["id"];
      this.sign_up_stage = this.userInfo["sign_up_stage"];

      this.getList();
      this.$loadScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_MAPS_KEY +
          "&libraries=places"
      )
        .then(() => {})
        .catch(() => {});
    } else {
      this.$router.push("/admin/dashboard");
    }
  },
  methods: {
    cropImage() {
      let croppedImageDataUrl = this.cropper.getCroppedCanvas().toDataURL(this.fileDetails.mimeType, 0.6);
      this.service.images.push({dataURL: croppedImageDataUrl, upload: {uuid: Date.now()}})
      this.results.push(croppedImageDataUrl);
    },

    removeCroppedImage(index) {
      this.results.splice(index, 1);
      this.service.images.splice(index, 1);
    },
    recreateImage(img) {
      this.displayImage = img;
      this.initializeCropper();
    },
    //deployment
    initializeCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
      setTimeout(() => {
        let image = document.querySelector('#cropper');
        this.cropper = new Cropper(image, {
          dragMode: 'move',
          aspectRatio: 16/9,
          background: false,
          autoCropArea: 0.9,
          restore: false,
          guides: false,
          center: false,
          highlight: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          toggleDragModeOnDblclick: false,
        });
      }, 200)
    },

    selectedFile(event) {
      this.displayImage = URL.createObjectURL(event.target.files[0]);
      this.fileDetails.type = event.target.files[0].type;
      this.fileDetails.name = event.target.files[0].name;

      $("#exampleModal").modal("show");
      this.initializeCropper();

    },
    submitBusinessImages() {
      if (this.service.images.length < 3) {
        alert("Please upload at least 3 images of your business to proceed");
      } else {
        this.isLoading = true;

        this.$store
          .dispatch("user/saveSalonDetails", {
            images: this.service.images,
            sign_up_stage: 6,
            user_id: this.userInfo["id"],
          })
          .then(() => {
            this.sign_up_stage = 7;
            this.$router.push("/admin/dashboard");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    skipBooking() {
      store("/api/save-salon-details", {
        sign_up_stage: 5,
        skip_sign_up_stage: true,
        user_id: this.userInfo["id"],
      }).then((res) => {
        setUser(res.data);
        this.sign_up_stage = 6;
      });
    },

    compareTimes() {
      const [hours1, minutes1] = this.start_time.split(":").map(Number);
      const [hours2, minutes2] = this.end_time.split(":").map(Number);
      return hours1 > hours2 || (hours1 === hours2 && minutes1 > minutes2);
    },

    addHours(time, hours) {
      const [currentHours, minutes] = time.split(":").map(Number);
      const newHours = (currentHours + hours) % 24;
      return `${newHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    },

    checkOnChange() {
      if (this.compareTimes()) {
        Swal.fire({
          title: "Error",
          text: "Start time cannot be greater than end time",
          icon: "error",
        });
        this.end_time = this.addHours(this.start_time, 8)
        return;
      }
      let opening = this.start_time;
      let closing = this.end_time;
      let slotLength = this.all_time_slot.slot_hours + ":" + this.all_time_slot.slot_mins; //slot length should be the days slot length
      // find timeSlot from main timeSlots

      let slotInMinutes = convertToMinutes(slotLength);
      if (
          opening === "" ||
          closing === "" ||
          slotLength === "" ||
          slotInMinutes === 0
      ) {
        return;
      }

      this.all_time_slot.timeslot.forEach((e,dayIndex) => {
        if (e.checked) {
          this.all_time_slot.timeslot[dayIndex].slots = [];
          this.all_time_slot.timeslot[dayIndex].start_time = this.start_time;
          this.all_time_slot.timeslot[dayIndex].end_time = this.end_time;
          let openMinutes = convertToMinutes(opening);
          let closeMinutes = convertToMinutes(closing);

          let new_time = openMinutes + slotInMinutes;
          let max_slots = 100;

          if (new_time > 0 && slotInMinutes > 0) {
            let s = 1;
            while (new_time <= closeMinutes) {
              this.all_time_slot.timeslot[dayIndex].slots.push({
                start: convertToHoursAndMinutes(openMinutes),
                end: convertToHoursAndMinutes(new_time),
                booking_limit: this.all_time_slot.booking_limit,
                checked: true
              });

              openMinutes = openMinutes + slotInMinutes;
              new_time = new_time + slotInMinutes;

              if (s > max_slots) {
                break;
              }
              s++;
            }
          }

          if (new_time > closeMinutes) {
            if (closeMinutes - openMinutes >= slotInMinutes) {
              this.all_time_slot.timeslot[dayIndex].slots.push({
                start: convertToHoursAndMinutes(new_time - slotInMinutes),
                end: convertToHoursAndMinutes(closeMinutes),
                booking_limit: this.all_time_slot.booking_limit,
                checked: false
              });
            }
          }
        }else{
          this.all_time_slot.timeslot[dayIndex].slots = [];
          this.all_time_slot.timeslot[dayIndex].start_time = '';
          this.all_time_slot.timeslot[dayIndex].end_time = '';
        }
      });
    },


    addBooking() {
      this.checkOnChange();
      let availableSlots = this.all_time_slot.timeslot.filter(
          e => e.checked
      );

      if (availableSlots.length < 1) {
        Swal.fire({
          title: "Error",
          text: "Please select booking days",
          icon: "error"
        });
        return;
      }

      let unavailableSlots = this.all_time_slot.timeslot.filter(
          e => !e.checked
      );

      this.all_time_slot.disabled_days = unavailableSlots.map(
          e => e.value
      );

      this.all_time_slot.disabled_days_strings = unavailableSlots.map(
          e => e.day
      );

      this.all_time_slot.available_days = availableSlots.map(
          e => e.value
      );

      this.all_time_slot.available_days_strings = availableSlots.map(
          e => e.day
      );
      this.all_time_slot.customTimeSlots = [];
      let data = {
        type: "setup",
        user_id: this.userInfo["id"],
        slots: this.all_time_slot,
      };

      this.isLoading = true;
      this.$store
          .dispatch("salon/updateSalonBooking", data)
          .then((res) => {
            setUser(res.user);
            this.sign_up_stage = 6;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getList() {
      this.$store.dispatch("serviceCategory/index").then((res) => {
        this.business_categories = res;
      });
    },
    getSelectedCategoryClass(item) {
      let index = this.selectedCategories.indexOf(item);
      return index > -1;
    },

    selectCategory(item) {
      let index = this.selectedCategories.indexOf(item);
      if (index > -1) {
        this.selectedCategories.splice(index, 1);
      } else {
        this.selectedCategories.push(item);
      }
    },
    createSalonCategory() {
      if (this.selectedCategories.length < 1) {
        Swal.fire({
          title: "Error!",
          text: "Select at least (one) category to proceed.",
          icon: "error",
          confirmButtonText: "ok",
        });
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("user/saveSalonDetails", {
          salon_category: this.selectedCategories,
          sign_up_stage: 3,
          user_id: this.userInfo["id"],
        })
        .then(() => {
          this.sign_up_stage = 4;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createBusinessProfile() {
      this.bio_info.sign_up_stage = 2;
      this.isLoading = true;
      this.$store
        .dispatch("user/saveSalonDetails", this.bio_info)
        .then((res) => {
          this.sign_up_stage = 3;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    errorFile(file, message) {
      this.$refs.myVueDropzone.removeFile(file);
      this.disableButton = false;
      alert(message);
    },
    createBusinessLocation() {
      if (
        this.business_address.lat === "" ||
        this.business_address.lng === ""
      ) {
        Swal.fire({
          title: "Error!",
          text: "Type an address and select from the dropdown list...",
          icon: "error",
          confirmButtonText: "ok",
        });
        return;
      }
      this.isLoading = true;

      this.$store
        .dispatch("user/saveSalonDetails", {
          business_address: this.business_address,
          sign_up_stage: 4,
          user_id: this.userInfo["id"],
        })
        .then(() => {
          this.sign_up_stage = 5;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    addingFiles() {
      this.disableButton = true;
    },
    removedFile(file) {
      this.service.images.splice(this.service.images.indexOf(file), 1);
    },
    upload_avatar(file) {
      this.service.images.push(file);
      this.disableButton = false;
    },

    loadSideMap() {
      let map = new google.maps.Map(document.getElementById("map"), {
        zoom: 11,
        center: {
          lat: this.business_address.lat,
          lng: this.business_address.lng,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          style: google.maps.ZoomControlStyle.LARGE,
        },
        scaleControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: "roadmap",
        fullscreenControl: false,
      });
      new google.maps.Marker({
        position: this.business_address,
        map: map,
        center: this.business_address,
        icon: "/static/images/shop.png",
      });
    },

    executeMap() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          componentRestrictions: {
            country: "uk",
          },
          fields: [
            "address_components",
            "geometry",
            "icon",
            "name",
            "formatted_address",
          ],
        }
      );
      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      this.business_address["lat"] = place.geometry.location.lat();
      this.business_address["lng"] = place.geometry.location.lng();
      this.business_address.location =
        place.name + " (" + place.formatted_address + ")";
      this.loadSideMap();
    },

    addService() {
      this.isLoading = true;
      let data = {
        service: this.service,
        user_id: this.salonDetails["user_id"],
      };
      this.$store
        .dispatch("service/store", data)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Service has been added successfully",
            icon: "success",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.push("/admin/services");
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.activate-category {
  border: 1px solid #7593db;
  background: #7593db;
}
.activate-category h6 {
  color: white !important;
}
#map {
  height: 250px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row !important;
  }
}

.list-group-item:last-child {
  /* margin-bottom: 0;
 */
}

.list-group-item {
  margin-bottom: -0.0625rem !important;
  flex: 1 1 auto !important;
  /*border: 1px solid rgba(0,0,0,.125) !important;*/
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}
 .roll-element {
   width: 100px;
   height: 62px;
   margin: 1px;
   position: relative;
   user-select: none;
 }

.reo-wrapper {
  position: relative;
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1.25em;
}

.roll-element-order {
  position: absolute;
  top: 0px;
  right: 2px;
  z-index: 2;
  color: white;
}

.cg-btn-upload {
  width: 100px;
  height: 58px;
  margin: 1px;
  position: relative;
  color: rgba(173, 181, 189, 0.8);
  border: 2px dashed #adb5bd;
  border-radius: 3px;
  font-size: 22px;
  background-color: transparent;
  font-weight: 400;
}

.cg-btn-upload:before {
  border-radius: 3px;
  width: 100px;
  height: 58px;
  border: 2px solid #adb5bd;
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  opacity: 0;
  transition: all 0.5s ease, opacity 0.3s ease-out;
}

</style>

<template>
  <div class="main-wrapper" style="min-height: 100vh">
    <header class="header-global">
      <nav
          id="navbar-main"
          style="z-index: 2"
          class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom"
      >
        <div class="container">
          <div id="logo1">
            <div
                class="text-light"
                style="cursor: pointer"
                @click="getPreviousPage"
                v-if="stage > 1"
            >
              <i class="fa fa-arrow-left"/> Previous
            </div>
          </div>

          <router-link
              :to="'/salon/' + $route.params.slug"
              class="d-block d-sm-none btn btn-neutral pl-2 pr-2 pt-1 pb-1 mb-2"
          ><i class="fa fa-close"/>
          </router-link>

          <div class="navbar-collapse collapse" id="navbar_global">
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <div id="logo">
                    <router-link to="/"
                    ><img
                        class="img-fluid"
                        width="65"
                        src="/static/images/logo.png"
                        height="25"
                        alt=""
                    />
                    </router-link>
                  </div>
                </div>
                <div class="col-6 collapse-close">
                  <button
                      type="button"
                      class="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                  >
                    <span/>
                    <span/>
                  </button>
                </div>
              </div>
            </div>
            <div
                class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto"
            >
              <ul class="m-0 p-0">
                <!--<app-menu></app-menu>-->
              </ul>
              <div class="header-widget">
                <router-link
                    :to="'/salon/' + $route.params.slug"
                    class="btn btn-neutral"
                ><i class="fa fa-close"
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
    />
    <div class="container">
      <div class="content">
        <div class="row sticky-wrapper">
          <div class="col-md-7 mb-3 offset-md-1">
            <div class="row sticky-top" style="top:70px">

              <div class="col-md-12" v-if="stage === 1">
                <div class="card shadow mb-3" style="margin-top: -40px;">
                  <div class="card-body pl-5 pr-5">
                    <i
                        class="fa fa-arrow-left arrow-left"
                        v-if="!hide_left_arrow"
                        @click="scrollToLeft"
                    />
                    <ul
                        class="scrolling-wrapper"
                        id="tabs-icons-text"
                        role="tablist"
                    >
                      <li
                          class="nav-item nav-pills mycard mr-4"
                          v-for="(category, i) in salon.salon_category"
                          :key="i"
                      >
                        <a
                            class="nav-link mb-sm-3 mb-md-0 active show"
                            id="tab-1"
                            data-toggle="tab"
                            href="#tab-1"
                            role="tab"
                            aria-controls="tabs-icons-text-1"
                            aria-selected="true"
                        >{{ category.service_category_name }}</a
                        >
                      </li>
                    </ul>
                    <div
                        v-if="((salon || {})['salon_category'] || []).length < 1"
                    >
                      No services at the moment
                    </div>
                    <i
                        class="fa fa-arrow-right arrow-right"
                        @click="scrollToRight"
                        v-if="!hide_right_arrow"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="stage === 2">
                <div class="card shadow mb-3" style="margin: -15px; margin-top: -40px;">

                  <div class="card-header text-center">
                    Select date and time
                  </div>
                  <div :class="classNames">
                    <div class="row">
                      <div class="col-12" v-if="salon && salon['booking_time']['available_days'].length <= 0">
                        <h6 class="text-danger text-center">Sorry! Shop has no booking schedules</h6>

                      </div>
                      <div class="col-md-7 md-border-right">

                        <div class="datepicker"></div>
                      </div>
                      <div class="col-md-5 ">
                        <div class="time mt-3 mb-2" v-if="booking_slot.slots.length > 0">
                          Time:
                        </div>
                        <div v-if="booking_slot.slots.length > 0" :class="dtclass">
                          <span style="cursor: pointer" :class="[
                              'badge badge-pill badge-dark mr-1 times',
                              i === selected ? 'timeActive' : ''
                            ]"
                                v-for="(t, i) in booking_slot.slots"
                                @click="setTime(t, i)"
                                :key="i"
                          >{{ t.start }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="stage === 3">
                <div
                    class="card shadow mb-3border-0"
                    style="margin-top: -40px;"
                    v-if="showForm === 'login'"
                >
                  <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-muted mb-4">
                      <h6>Sign in to continue</h6>
                    </div>
                    <form role="form">
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-envelope"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              v-model="loginForm.email"
                              placeholder="Email"
                              type="email"
                              required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-unlock-alt"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              v-model="loginForm.password"
                              placeholder="Password"
                              type="password"
                              min="8"
                              required
                          />
                        </div>
                      </div>
                      <div>
                        Don't have a an account?
                        <a
                            @click="showForm = 'register'"
                            class="text-info cursor-pointer"
                        >Sign up here</a
                        >
                      </div>
                      <div class="text-center">
                        <button
                            type="button"
                            @click="login"
                            class="btn btn-primary mt-4"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                    class="card bg-secondary shadow border-0"
                    style="margin-top: -40px;"
                    v-else
                >
                  <div class="card-header bg-white">
                    <div class="text-muted text-center">
                      <h6>Sign up to continue</h6>
                    </div>
                  </div>

                  <div class="card-body px-lg-5 py-lg-5">
                    <form role="form" @submit.prevent="register">
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-user"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              placeholder="Name"
                              v-model="loginForm.name"
                              type="text"
                              required
                          />
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-envelope"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              v-model="loginForm.email"
                              placeholder="Email"
                              type="email"
                              required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-unlock-alt"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              v-model="loginForm.password"
                              placeholder="Password"
                              type="password"
                              min="8"
                              required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                            ><i class="fa fa-unlock-alt"
                            /></span>
                          </div>
                          <input
                              class="form-control"
                              v-model="loginForm.password_confirmation"
                              placeholder="Confirm Password"
                              type="password"
                              min="8"
                              required
                          />
                        </div>
                      </div>
                      <div>
                        Already have a an account?
                        <a
                            @click="showForm = 'login'"
                            class="text-info cursor-pointer"
                        >Login here</a
                        >
                      </div>

                      <div class="text-center">
                        <button type="submit" class="btn btn-primary mt-4">
                          Register Me
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="stage === 4">
                <div class="card shadow mb-3" style="margin-top: -40px;">
                  <div class="card-body pl-5 pr-5">
                    <div class="row">
                      <div class="col-md-10 offset-md-1">
                        <form ref="bookingInfoSubmit" @submit.prevent="initSquare">
                          <h5 class="text-center mb-4">Booking Information</h5>
                          <h6 class="mt-2 mb-0">Phone number <span class="text-danger">*</span></h6>
                          <div class="mb-2"><small>(The shop will contact you via this number)</small></div>
                          <input
                              type="text"
                              class="form-control form-control-alternative"
                              v-model="user_appointment.phone_number"
                              placeholder="Phone number"
                              required
                          />
                          <h6 class="mt-2">Add Note (Optional)</h6>
                          <textarea
                              placeholder="Extra details to your booking"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              style="height: 70px"
                              class="form-control form-control-alternative"
                              v-model="user_appointment.additional_notes"
                              spellcheck="false"
                          />
                          <div class="mt-3">
                            <input
                                type="checkbox"
                                v-model="terms"
                                required
                                id="terms"
                                class="mr-1"
                            />
                            <label for="terms" style="vertical-align: middle;"
                            >I agree to the <a
                                href="https://docs.google.com/document/d/1yf3F5jre41I-_d4olGWAYH5yBihTbOOG-s6ZXKgNeFM">terms
                              and conditions</a>
                            </label>
                          </div>
                          <button type="submit" ref="buttonToClick" style="display: none">Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="stage === 5">
                <div class="card shadow mb-3" style="margin-top: -40px;">

                  <div class="card-body pl-5 pr-5">
                    <div class="row">
                      <div class="col-md-10 offset-md-1">
                        <h5 class="text-center">Payment</h5>
                        <div class="alert alert-danger" v-if="paymentError.length>0">
                          <div>{{ paymentErrorMessage }}</div>

                          <ul class="m-0">
                            <li v-for="(error,i) in paymentError" :key="i">{{ error.detail }}</li>
                          </ul>

                        </div>

                        <h6>Card Details</h6>
                        <div class="photo shine mb-5" style="height: 109px" v-if="cardLoader"/>
                        <div id="card"></div>
                        <h6>Billing contact information</h6>
                        <div>
                          <form @submit.prevent="makePayment" v-if="!cardLoader" class="mb-4">
                            <div class="row">
                              <div class="col-md-6">
                                <label for="">First Name <span class="text-danger">*</span></label>
                                <input type="text" required v-model="billing_contact.givenName" name=""
                                       class="form-control">
                              </div>
                              <div class="col-md-6">
                                <label for="">Family Name <span class="text-danger">*</span></label>
                                <input type="text" required v-model="billing_contact.familyName" name=""
                                       class="form-control">
                              </div>
                              <div class="col-md-6">
                                <label for="">Email <span class="text-danger">*</span></label>
                                <input type="email" required v-model="billing_contact.email" name=""
                                       class="form-control">
                              </div>
                              <div class="col-md-6">
                                <label for="">Phone <span class="text-danger">*</span></label>
                                <input type="text" required v-model="billing_contact.phone" name=""
                                       class="form-control">
                              </div>
                              <div class="col-md-6">
                                <label for="">Address Line 1 <span class="text-danger">*</span></label>
                                <input type="text" required v-model="billing_contact.address_line_1"
                                       class="form-control">
                              </div>

                              <div class="col-md-6">
                                <label for="">City</label>
                                <input type="text" v-model="billing_contact.city" class="form-control">
                              </div>

                              <div class="col-12">
                                <div>
                                  <input
                                      type="checkbox"
                                      v-model="billing_contact.remember_address"
                                      id="remember_address"
                                      class="mr-1"
                                  />
                                  <label for="remember_address" style="vertical-align: middle;"
                                  >Remember this address for later use
                                  </label>
                                </div>

                              </div>
                            </div>
                            <div class="text-center mt-3">
                              <button type="submit" :disabled="paymentLoader" class="btn btn-primary">
                                {{
                                  paymentLoader ? 'Submitting...' : 'Make payment ' + getCurrency + (Number(amountToPay()).toFixed(2))
                                }}
                              </button>
                            </div>

                          </form>
                          <div v-else>
                            <div class="lines shine"></div>
                            <div class="lines shine"></div>
                            <div class="lines shine"></div>
                            <div class="lines shine"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow mb-3" v-if="serviceLoader">
              <div class="card-body">
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <hr>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <hr>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <hr>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
              </div>
            </div>
            <div v-else>
              <div
                  class="card shadow mb-3"
                  v-if="stage === 1"
                  v-for="(category, i) in salon.salon_category"
                  :key="i"
              >
                <div class="text-center mt-2 mb-2 font-weight-600" v-if="i === 0">
                  Select a service
                </div>

                <div class="card-header">
                  {{ category.service_category_name }}
                </div>
                <div class="card-body">
                  <div class="pricing-list-container">
                    <ul>
                      <li
                          class="border-bottom d-flex justify-content-between"
                          v-for="service in category.services"
                          :key="service.service_id"
                      >
                        <div class="custom-control custom-checkbox">
                          <input
                              type="checkbox"
                              class="custom-control-input"
                              :id="'customCheck' + service.service_id"
                              v-model="user_appointment.service"
                              :value="service"
                              name="example1"
                          />
                          <label
                              class="custom-control-label"
                              :for="'customCheck' + service.service_id"
                          >
                            <div class="ml-4 service-item-header">
                              {{ service.service_name }}
                            </div>
                            <div class="ml-4 text-muted" style="margin-top: -3px">
                              {{
                                service.service_time_taken
                                    ? service.service_time_taken + " mins"
                                    : "N/A"
                              }}
                            </div>
                          </label>
                        </div>
                        <span class="font-weight-600"
                        >{{ getCurrency }}{{ service.service_price }}</span
                        >
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-4 col-lg-4">
            <div class="row sticky-top d-none d-md-block" style="top:70px">
              <div class="col-md-12">
                <div class="card shadow mb-3" style="margin-top: -40px;">
                  <div class="card-avatar">
                    <img
                        :src="((salon || {}).user || {})['user_image']"
                        class="w-100"
                        style="height: 90px"
                    />
                  </div>
                  <div class="card-body pt-2">
                    <div class="text-center border-bottom pb-2">
                      <h6>
                        {{ salon.salon_name }}
                        <span class="font-weight-light"/>
                      </h6>
                      <div class="h6 text-muted">
                        <i class="ni location_pin mr-2"/>
                        {{ (salon || {})["location"] }}
                      </div>
                    </div>
                    <div class="item-content">
                      <div
                          class="border-bottom text-muted text-center pt-4 pb-4"
                          v-if="user_appointment.service.length === 0"
                      >
                        No service selected yet
                      </div>
                      <div
                          class="row mt-3 pb-3 border-bottom"
                          v-for="(selected, i) in user_appointment.service"
                          :key="i"
                      >
                        <div class="col-7">
                          <div>
                            {{ selected.service_name }}
                          </div>
                          <div class="text-muted">
                            {{
                              selected.service_time_taken
                                  ? selected.service_time_taken + " mins"
                                  : "N/A"
                            }}
                          </div>
                        </div>
                        <div class="col-5">
                          <span class="font-weight-500"
                          >{{ getCurrency }}{{ selected.service_price }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3 pb-3  border-bottom">
                      <div class="col-12">
                        <div class="row" v-if="stage > 3">
                          <div class="col-7">
                            Sub Total
                          </div>
                          <div class="col-5">
                            {{ subTotal | toCurrencyFormatRaw }}
                          </div>
                        </div>
                        <div class="row" v-if="stage > 3">
                          <div class="col-7">
                            Service Charge
                          </div>
                          <div class="col-5">
                            {{ getCurrency }}{{ serviceCharge() }}
                          </div>
                        </div>
                        <div class="row font-weight-700">
                          <div class="col-7">
                            Total
                          </div>
                          <div class="col-5">
                            <div style="font-size: 20px">{{ getCurrency }}{{ getTotal() | toCurrency }}</div>
                          </div>
                        </div>
                        <div class="row" v-if="stage > 3 && depositFee() > 0">
                          <div class="col-7">
                            Deposit Required
                          </div>
                          <div class="col-5">
                            {{ getCurrency }}{{ depositFee() | toCurrency }}
                          </div>
                        </div>
                        <div class="row" v-if="stage > 3 && outstandingBalance() >0">
                          <div class="col-12">
                            <hr class="mt-2 mb-2"/>

                            <div style="font-size: 13px;">You will be required to pay the remainder in shop =
                              {{ getCurrency }}{{ outstandingBalance() | toCurrency }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                        class="row mt-3"
                        v-if="user_appointment.service.length > 0"
                    >
                      <div class="col-md-12">
                        <button
                            type="button"
                            class="btn btn-primary btn-block"
                            v-if="stage === 1"
                            @click="goToBookingSchedules"
                            style="box-shadow:none"
                        >
                          Book Now
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-block"
                            v-if="stage === 2"
                            :disabled="
                            !user_appointment.start_time ||
                              !user_appointment.end_time ||
                              !user_appointment.date_time
                          "
                            @click="proceedToStage3"
                            style="box-shadow:none"
                        >
                          Next
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-block"
                            v-if="
                            selectedDate &&
                              stage === 4 &&
                              authenticated
                          "
                            @click="referenceAndSubmit"
                            style="box-shadow:none"
                        >
                          Proceed to Pay <span>{{ amountToPay()|toCurrencyFormatRaw }}</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="wave-cover">
                  <div class="wave-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="row d-md-none d-sm-block bottomAction"
            v-if="user_appointment.service.length > 0"
        >
          <div class="col-md-12">
            <div class="card shadow" v-if="stage === 1 || stage === 2">
              <div class="card-body">
                <div class="mb-2">
                  Total : <span class="font-weight-700">{{ getCurrency }}{{ subTotal }}</span>
                </div>
                <button
                    v-if="stage === 1"
                    type="button"
                    class="btn btn-primary btn-block"
                    @click="stage = 2"
                    style="box-shadow:none"
                >
                  Book Now
                </button>
                <button
                    type="button"
                    class="btn btn-primary btn-block"
                    v-if="stage === 2"
                    :disabled="
                    !user_appointment.start_time ||
                      !user_appointment.end_time ||
                      !user_appointment.date_time
                  "
                    @click="proceedToStage3"
                    style="box-shadow:none"
                >
                  Next <i class="fa fa-arrow-right"/>
                </button>
              </div>
            </div>
            <div
                class="card shadow"
                v-if="selectedDate && stage ===4 && authenticated"
            >
              <div class="card-body">
                <div class="row mt-3 pb-3">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-7">
                        Sub Total
                      </div>
                      <div class="col-5">
                        {{ subTotal }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-7">
                        Service Charge
                      </div>
                      <div class="col-5">
                        {{ getCurrency }}{{ serviceCharge() }}
                      </div>
                    </div>
                    <div class="row font-weight-700">
                      <div class="col-7">
                        Total
                      </div>
                      <div class="col-5">
                        <div style="font-size: 20px">{{ getCurrency }}{{ getTotal() | toCurrency }}</div>
                      </div>
                    </div>
                    <div class="row" v-if=" depositFee() >0">
                      <div class="col-7">
                        Deposit Required
                      </div>
                      <div class="col-5">
                        {{ getCurrency }}{{ depositFee() | toCurrency }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12" v-if="stage > 3 && outstandingBalance() >0">
                        <hr class="mt-2 mb-2"/>

                        <div style="font-size: 13px;">You will be required to pay the remainder in shop = {{
                            getCurrency
                          }}{{ outstandingBalance() | toCurrency }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                    type="button"
                    class="btn btn-primary btn-block"
                    @click="referenceAndSubmit"
                    style="box-shadow:none"
                >
                  Proceed to Pay {{ amountToPay() | toCurrencyFormatRaw }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  successful appointment  modal-->
    <b-modal v-if="appointmentInfo"
             id="modal-1"
             size="md"
             no-close-on-backdrop
             v-model="successModal"
             hide-header-close
             no-close-on-esc
             title="Payment Received! Your Appointment is Booked"
             header-close-variant="light"
             title-class="font-18"
             hide-footer
    >
      <div class="text-center">
        <img src="@/assets/images/successful_appointment.png" style="width: 200px" class="img-fluid"
             alt="successful appointment">
      </div>
      <h6>Booking reference : {{ appointmentInfo.appointment_reference }}</h6>
      <p>Great news! We've received your payment, and your appointment is booked. Your stylist will confirm soon.</p>
      <p>Please wait up to 5 hours.</p>
      <p class="mb-0">Happy Booking,</p>
      <div>Style Me</div>
      <div class="text-center mt-3">
        <button class="btn btn-primary" @click="viewBooking()">View booking</button>
      </div>
      <div class="text-center mt-4">
        <!--        <small>-->
        <!--          <a :href="`/salon/${this.salon.slug}`">Make another booking</a>-->
        <!--        </small>-->
      </div>

    </b-modal>

    <!--  end of successful appointment  modal-->
  </div>
</template>

<script>
import GallerySlider from "Components/globalFrontendComponents/GallerySlider";
import SidebarLayoutTwo from "Views/listing/SidebarLayoutTwo";
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import * as Square from '@square/web-sdk';
import datepicker from "@/assets/bootstrap-datepicker.js";

import {index} from "@/api/resource";
import {getUser} from "@/utils/auth";
import {apisService} from "@/api/apis.service";
import moment from "moment";

export default {
  name: "Book-Salon",
  data() {
    return {
      classNames: "",
      dtclass: "",
      selected: "",
      payment_option: "online",
      addressLine1: "",
      terms: false,
      successModal: false,
      cardLoader: false,
      paymentLoader: false,
      cardInitialised: false,
      user_appointment: {
        date_time: "",
        start_time: "",
        end_time: "",
        salon_id: "",
        additional_notes: "",
        service: [],
        total: "",
        sub_total: "",
        deposit: "",
        outstanding_balance: "",
        service_charge: "",
        payment_type: "",
        payment_option: "",
        payment_option_value: "",
        payment_reference: "",
        user_email: "",
        phone_number: ""
      },
      authenticated: !!getUser(),
      loginForm: {
        email: "",
        password: "",
        role: "CONSUMER"
      },
      shop_appointments: [],
      stage: 1,
      showForm: "login",
      chooseTime: false,
      salon: '',
      isLoading: false,
      serviceLoader: false,
      fullPage: true,
      details: {},
      height: 128,
      width: 128,
      value: "",
      selectedDate: false,
      days: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ],
      scroll_left: false,
      hide_right_arrow: true,
      hide_left_arrow: true,
      leftPos: 0,
      all_time: {},
      paymentError: [],
      paymentErrorMessage: '',
      time_component: [],
      // base_url:process.env.VUE_APP_BASE_URL,
      selected_day_times: [],
      booking_slot: {
        day: '',
        start_time: "",
        end_time: "",
        slots: []
      },
      appointmentInfo: null,
      billing_contact: {
        addressLines: [],
        familyName: '',
        givenName: '',
        email: '',
        country: 'GB',
        countryCode: 'GB',
        phone: '',
        region: '',
        city: '',
        address_line_1: '',
        remember_address: '',
      },
      card: '',
      payments: '',
      advance_days: []
    };
  },
  components: {
    GallerySlider: GallerySlider,
    SidebarLayoutTwo: SidebarLayoutTwo,
    Loading: () => import("vue-loading-overlay")
  },
  computed: {
    getCurrency() {
      return process.env.VUE_APP_PRICE_SYMBOL
    },
    subTotal: function () {
      let sum = 0;
      this.user_appointment.service.forEach(e => {
        sum += Number(e.service_price);
      });
      return sum;
    },
  },


  methods: {
    viewBooking() {
      this.$router.push(`/booking-details/${this.appointmentInfo.appointment_id}/service`);
    },
    removeClassOnTablet() {
      const isTabletMode = window.matchMedia('(min-width: 768px)').matches;
      const isDesktopMode = window.matchMedia('(min-width: 1024px)').matches;

      if (isTabletMode === true) {
        this.classNames = "tabletclass";
        this.dtclass = "dttabletstyle";
      } else {
        this.classNames = "card-body pl-5 pr-5";
        this.dtclass = "dtstyle";

      }
      if (isDesktopMode === true) {
        this.classNames = "card-body pl-5 pr-5";
      }
    },

    getAll() {
      $(".mycard")
          .map(function () {
            return this.innerHTML;
          })
          .get();
    },
    checkScroll() {
      let me = this;
      $(".scrolling-wrapper").scroll(function () {
        me.leftPos = $(".scrolling-wrapper").scrollLeft();
        me.hide_left_arrow = me.leftPos === 0;
        me.hide_right_arrow =
            me.leftPos + 110 > $(".scrolling-wrapper").width();
      });
    },

    scrollToRight() {
      this.leftPos = $(".scrolling-wrapper").scrollLeft();
      $(".scrolling-wrapper").animate({scrollLeft: this.leftPos + 110}, 100);
      this.leftPos += 110;
      // console.log(this.leftPos, $(".scrolling-wrapper").width());
      this.hide_right_arrow = this.leftPos > $(".scrolling-wrapper").width();
      this.hide_left_arrow = this.leftPos == 0;
    },

    scrollToLeft() {
      this.leftPos = $(".scrolling-wrapper").scrollLeft();
      $(".scrolling-wrapper").animate({scrollLeft: this.leftPos - 110}, 100);
      this.leftPos -= 110;
      if (this.leftPos <= 0) {
        this.hide_left_arrow = true;
      } else {
        this.hide_left_arrow = false;
      }
      this.hide_right_arrow = this.leftPos > $(".scrolling-wrapper").width();
    },
    getShopAppointments() {
      let from = moment().format("YYYY-MM-DD");
      index(`api/shop-appointments/${this.salon.salon_id}?from=${from}`)
          .then(response => {
            this.shop_appointments = response.data;
            this.getDisabledDays();
          });
    },
    async getSaloonDetail(slug) {
      this.serviceLoader = true;
      await index("/api/salon/" + slug)
          .then(response => {
            const {data} = response;
            this.salon = data;
            this.salon.salon_category = this.salon.salon_category.filter(e => {
              if (e["services"].length > 0) {
                return e;
              }
            });
            this.user_appointment.salon_id = data.salon_id;
            if (this.$route.query.categoryInd && this.$route.query.serviceInd) {
              this.user_appointment.service.push(
                  this.salon.salon_category[this.$route.query.categoryInd]["services"][
                      this.$route.query.serviceInd
                      ]
              );
            }
            this.getShopAppointments();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.serviceLoader = false;
          });
    },

    checkIfAuthenticated() {
      let user = getUser();
      if (user === "undefined" || user == null) {
        this.authenticated = false;
        return false;
      } else {
        this.authenticated = true;
        this.user_appointment.user_id = user.id;
        this.user_appointment.user_email = user.email;
        return true;
      }
    },
    proceedToStage3() {
      if (
          this.user_appointment.start_time &&
          this.user_appointment.end_time &&
          this.user_appointment.date_time
      ) {
        this.selectedDate = true;
        if (this.checkIfAuthenticated()) {
          this.stage = 4;
          this.user_appointment.phone_number = getUser()["phone_number"];
          this.getUserBillingContact(getUser()["id"]);
        } else {
          this.stage = 3;
        }
      }
    },
    clearSetTime() {
      this.selected = '';
      this.user_appointment.start_time = '';
      this.user_appointment.end_time = '';
    },
    setTime(time, index) {
      this.selected = '';
      this.selected = index;
      this.user_appointment.start_time = time.start;
      this.user_appointment.end_time = time.end;
    },
    getPreviousPage() {
      if (this.stage === 4 && this.authenticated) {
        this.stage = 2;
      } else {
        this.stage -= 1;
      }
    },

    getDisabledDays() {
      let me = this;
      $(".datepicker")
          .datepicker({
            format: "yyyy-m-d",
            todayHighlight: true,
            startDate: "1d",
            daysOfWeekDisabled: this.salon.booking_time.disabled_days,
            beforeShowDay: function (date) {
              let slot = me.checkDate(date)
              if (slot.slots.length <= 0) {
                return false
              }
            }
          })
          .on("changeDate", async function (e) {
            // remove the set time
            me.clearSetTime();
            let selectedDate = moment(e.date).format("YYYY-MM-DD");

            // check if date is a custom date
            let slot = me.checkDate(e.date)

            if (slot.slots.length > 0) {
              me.user_appointment.date_time = selectedDate;
              me.booking_slot = slot;
            }

          });
    },
    checkDate(date) {
      let selectedDate = moment(date).format("YYYY-MM-DD");
      let bookingSlot = this.salon.booking_time;
      let slots;
      let customDateSlot = bookingSlot.customTimeSlots.find(e => e.date === selectedDate);
      if (customDateSlot) {
        if (customDateSlot.close) {
          slots = {...customDateSlot, slots: []};
        } else {
          slots = customDateSlot;
        }
      } else {
        // if its part of the normal days
        // convert the date to day and find the timeslot
        let day = moment(date).format("dddd").toLowerCase();
        slots = bookingSlot.timeslot.find(e => e.day.toLowerCase() === day);
      }
      // filter slots that are not checked
      slots.slots = slots.slots.filter(e => e.checked);

      // filter if no one has booked that time or the booking slot time is not full

      // get appointments for the selected date
      let selectedDateAppointments = this.shop_appointments.filter(e => e.date_time === selectedDate)


      for (const slot of slots.slots) {
        // get index of the slot in the shop_appointments

        let totalAppointmentsForSelectedDate = selectedDateAppointments.filter(e => e.start_time === slot.start).length;

        if (totalAppointmentsForSelectedDate > 0) {
          // if the slot is found in the shop_appointments
          let slotBookingLimit = slot.booking_limit - totalAppointmentsForSelectedDate;
          // check if the slot is full
          if (slotBookingLimit <= 0) {
            // if the slot is full
            // remove the slot from the slots
            let index = slots.slots.findIndex(e => e.start === slot.start);
            if (index !== -1) {
              slots.slots.splice(index, 1)
            }
          }
        }

      }

      return slots;

    },

    goToBookingSchedules() {
      this.stage = 2;
    },

    create_UUID() {
      let dt = new Date().getTime();
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
          c
      ) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },

    // saveUserAppointment

    payWithPaystack() {
      if (!this.terms) {
        Swal.fire({
          title: "Error",
          text: "Accept the terms and condition to proceed",
          icon: "error"
        });
        return;
      }
      console.log(this.salon);
      let me = this;
      let handler = window.PaystackPop.setup({
        key: "pk_live_badb98df90cff859a87fc08ed8de534bcd677ac0",
        email: me.user_appointment.user_email,
        amount: Math.round(this.depositFee() * 100),
        // amount: 0.1 * 100,
        currency: "GHS",
        ref: this.create_UUID(),
        channels: [
          "mobile_money",
          "ussd",
          "card",
          "bank",
          "qr",
          "bank_transfer"
        ],
        metadata: {
          custom_fields: [
            {
              display_name: "Mobile Number",
              variable_name: "mobile_number",
              value: "0241994733"
            }
          ]
        },
        callback: function (res) {
          me.user_appointment.payment_reference = res.reference;
          me.saveUserAppointment();
        },
        onClose: function () {
          console.log("closed");
        }
      });
      handler.openIframe();
    },

    referenceAndSubmit() {
      // Reference the button by its ref name
      const buttonToClick = this.$refs.buttonToClick;

      // Trigger the click event on the referenced button
      if (buttonToClick) {
        buttonToClick.click();
      }
    },

    getSpaceKeys() {
      if (process.env.NODE_ENV === 'production') {
        return {
          appId: process.env.VUE_APP_SPACE_PROD_APPID,
          locationId: process.env.VUE_APP_SPACE_PROD_LOCATION,
        };
      } else {
        return {
          appId: process.env.VUE_APP_SPACE_LOCAL_APPID,
          locationId: process.env.VUE_APP_SPACE_LOCAL_LOCATION,
        };
      }
    },

    async initSquare() {
      this.stage = 5;
      if (this.cardInitialised) {
        return;
      }

      this.cardLoader = true;
      // set billing details
      const {appId, locationId} = this.getSpaceKeys();
      this.payments = await Square.payments(appId, locationId);
      this.card = await this.payments.card();

      await this.card.attach('#card');
      this.cardLoader = false;
      this.cardInitialised = true;

    },

    async makePayment() {
      try {
        this.paymentLoader = true;

        this.paymentError = [];
        const result = await this.card.tokenize();
        const token = result.token
        const verificationDetails = {
          amount: `${(this.amountToPay())}`,
          /* collected from the buyer */
          billingContact: {...this.billing_contact, addressLines: [this.addressLine1]},
          currencyCode: 'GBP',
          intent: 'CHARGE',
        };

        const verificationToken = await this.payments.verifyBuyer(token, verificationDetails);
        const data = {
          verificationToken: verificationToken.token,
          sourceId: token,
          amount: `${(this.amountToPay())}`, // £2
          ...this.billing_contact,
          user_id: getUser().id,
        }
        this.paymentLoader = false;


        this.createPayment(data);
        // TODO: use result.token as source_id in /v2/payments API call
      } catch (ex) {
        this.paymentLoader = false;
        this.paymentErrorMessage = ex;
        console.error(ex, 'error---');
      }
    },

    createPayment(payload) {
      this.paymentLoader = true;
      apisService.createPayment(payload).then((response) => {
        const {success, message, data} = response;
        if (!success) {
          this.paymentError = data;
          this.paymentErrorMessage = message;
        } else {
          const {payment} = data;
          this.user_appointment.payment_reference = payment.order_id;
          this.saveUserAppointment();
        }

      }).finally(() => {
        this.paymentLoader = false;

      });
    },

    saveUserAppointment() {
      this.isLoading = true;
      this.user_appointment.deposit = this.amountToPay() > 0 ? Number(this.depositFee()).toFixed(2) : 0;
      this.user_appointment.total = this.getTotal().toFixed(2);
      this.user_appointment.outstanding_balance = this.outstandingBalance();
      this.user_appointment.sub_total = this.subTotal;
      this.user_appointment.service_charge = this.serviceCharge();
      apisService.saveShopAppointment(this.user_appointment).then((response) => {
        const {success, message, data} = response;
        if (success) {
          this.appointmentInfo = data
          this.successModal = true;
        } else {
          alert(message)
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },


    getUserBillingContact(user_id) {
      apisService.getBillingContact(user_id).then((response) => {
        const {success, message, data} = response;
        if (success) {
          if (data) {
            this.billing_contact.familyName = data.family_name;
            this.billing_contact.givenName = data.first_name;
            this.billing_contact.email = data.email;
            this.billing_contact.address_line_1 = data.address_line_1;
            this.billing_contact.city = data.city;
            this.billing_contact.phone = data.phone;
            return;
          }
          if (getUser()) {
            this.billing_contact.familyName = "";
            this.billing_contact.givenName = getUser().name;
            this.billing_contact.email = getUser().email;
            this.billing_contact.addressLines = "";
            this.billing_contact.city = "";
            this.billing_contact.phone = getUser().phone_number;
          }
        } else {
          alert(message)
        }
      });

    },
    login() {
      this.isLoading = true
      this.$store
          .dispatch("user/login", this.loginForm)
          .then((res) => {
            const {success, message} = res;
            if (success) {
              if (this.$store.getters.roles.indexOf("PROVIDER") !== -1) {
                Swal.fire({
                  title: "Error!",
                  text: "Sorry, you cannot make a booking as a salon",
                  icon: "error",
                  confirmButtonText: "ok"
                }).then(confirm => {
                  if (confirm) {
                    this.$router.push({path: "/"});
                  }
                });
              } else if (this.$store.getters.roles.indexOf("CONSUMER") !== -1) {
                this.stage = 4;
                this.user_appointment.user_id = getUser()["id"];
                this.user_appointment.user_email = getUser()["email"];
                this.user_appointment.phone_number = getUser()["phone_number"];
                this.getUserBillingContact(getUser()["id"]);
                this.authenticated = true;
              } else if (this.$store.getters.roles.indexOf("ADMIN") !== -1) {
                Swal.fire({
                  title: "Error!",
                  text: "Sorry, you cannot make a booking as an admin",
                  icon: "error",
                  confirmButtonText: "ok"
                }).then(confirm => {
                  if (confirm) {
                    this.$router.push({path: "/"});
                  }
                });
              }
            } else {
              Swal.fire({
                title: "Error!",
                text: message,
                icon: "error",
                confirmButtonText: "ok"
              });
            }

          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    register() {
      this.isLoading = true;
      this.$store
          .dispatch("user/register", this.loginForm)
          .then((res) => {
            const {success, data} = res;
            if (success) {
              this.user_appointment.user_id = getUser()["id"];
              this.stage = 4;
              this.authenticated = true;
              this.user_appointment.user_id = getUser()["id"];
              this.user_appointment.user_email = getUser()["email"];
              this.user_appointment.phone_number = getUser()["phone_number"];
              this.getUserBillingContact(getUser()["id"]);
            } else {
              let error_data = "";
              if (data) {
                let objectKeys = Object.keys(data);
                for (let d of objectKeys) {
                  error_data += "<li>" + data[d][0] + "</li>";
                }
              }
              Swal.fire({
                title: "Error!",
                html: "<ul>" + error_data + "</ul>",
                icon: "error",
                confirmButtonText: "ok"
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    serviceCharge() {
      return ((2 / 100) * this.subTotal) + 0.25;
    },
    depositFee() {
      let deposit = 0;
      this.user_appointment.payment_type = this.salon.payment_type;
      this.user_appointment.payment_option = this.salon.payment_option;
      if (this.salon.payment_type === "payonline") {
        if (this.salon.payment_option === "percentage") {
          deposit = (this.salon.deposit_percentage / 100) * this.getTotal();
          this.user_appointment.payment_option = "percentage";
          this.user_appointment.payment_option_value = this.salon.deposit_percentage;
        } else if (this.salon.payment_option === "fixed") {
          this.user_appointment.payment_option_value = this.salon.fixed_deposit_amount;
          if (this.salon.fixed_deposit_amount >= this.getTotal()) {
            deposit = this.getTotal();
          } else {
            deposit = this.salon.fixed_deposit_amount;
          }
        }
      }
      return deposit;
    },
    amountToPay() {
      if (this.depositFee() > 0) {
        return this.depositFee();
      }
      return this.getTotal()
    },
    outstandingBalance() {
      if (this.depositFee() > 0) {
        return this.getTotal() - this.depositFee();
      }
      return 0;
    },
    getTotal() {
      if (this.stage > 3) {
        return this.subTotal + this.serviceCharge();
      }
      return this.subTotal;
    }
  },
  created() {
  },
  async mounted() {
    await this.getSaloonDetail(this.$route.params.slug);


    if (
        this.authenticated &&
        this.$store.getters.roles.indexOf("PROVIDER") !== -1
    ) {
      Swal.fire({
        title: "Error!",
        text: "Sorry, you cannot make a booking as a salon",
        icon: "error",
        confirmButtonText: "ok"
      }).then(confirm => {
        if (confirm) {
          this.$router.push({path: "/admin/dashboard"});
        }
      });
    }
    this.checkScroll();
    this.getAll();

    this.removeClassOnTablet();
    window.addEventListener('resize', this.removeClassOnTablet);
  },


};
</script>

<style scoped>
.times {
  cursor: pointer;
  /* color: white; */
}

.times:hover,
.timeActive {
  background: black;
  color: white;
}

@media screen and (min-width: 567px) {
  .md-border-right {
    border-right: 0.0625rem solid #e9ecef !important;
  }
}


.custom-control-label::after {
  top: 0.6rem;
  left: -1.75rem;
  width: 2rem;
  height: 2rem;
}

.custom-control-label::before {
  top: 0.6rem;
  left: -1.75rem;
  width: 2rem;
  height: 2rem;
}

.nav-pills .nav-link {
  border-radius: 5rem !important;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 0px !important;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-left: 0px;
  scrollbar-width: none;
}

.mycard {
  display: inline-block;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.arrow-left {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 0.7rem 1rem;
  cursor: pointer;
}

.arrow-right {
  position: absolute;
  right: 0;
  top: 30px;
  padding: 0.7rem 1rem;
  cursor: pointer;
}

.service-item-header {
  font-size: 20px;
  font-weight: 600;
}

.card-avatar {
  max-width: 100px;
  max-height: 100px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0 rgb(0 0 0 / 12%),
  0 8px 10px -5px rgb(0 0 0 / 20%);
}

.wave-bottom::before {
  background-image: radial-gradient(
      circle at 10px -5px,
      rgb(255, 255, 255) 12px,
      rgba(255, 255, 255, 0) 13px
  );
}

.wave-bottom::before {
  content: "";
  position: absolute;
  left: 20px;
  bottom: 5px;
  right: 0px;
  background-repeat: repeat-x;
  height: 10px;
  background-size: 40px 23px;
  background-image: radial-gradient(
      circle at 10px -5px,
      rgb(255, 255, 255) 12px,
      rgba(255, 255, 255, 0) 13px
  );
}

.wave-bottom::after {
  background-image: radial-gradient(
      circle at 10px 17px,
      rgba(255, 255, 255, 0) 13px,
      rgb(255, 255, 255) 14px
  );
}

.wave-bottom::after {
  content: "";
  position: absolute;
  left: 1px;
  bottom: 5px;
  right: 1px;
  background-repeat: repeat-x;
  height: 15px;
  background-size: 40px 8px;
  background-image: radial-gradient(
      circle at 10px 17px,
      rgba(255, 255, 255, 0) 13px,
      rgb(255, 255, 255) 14px
  );
}

.wave-cover {
  position: relative;
}

.item-content {
  max-height: 260px;
  overflow-y: auto;
}

.tabletclass {
  padding-bottom: 5%;
  padding-left: 0%;
}

.dtstyle {
  background-color: #7593db47;
  border-radius: 5px;
  padding: 5%;

}

.dttabletstyle {
  background-color: #7593db5e;
  border-radius: 5px;
  padding: 5%;
  margin-right: 5%;

}

>>> .table-condensed {
  width: 100%;
}

>>> .datepicker-inline {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .badge-dark {
    color: #fff;
    background-color: rgb(117 147 219);
  }

  .container {
    max-width: 820px;
  }
}

@media screen and (min-width: 768px) {
  .badge-dark {
    color: #fff;
  }
}

.form-control {
  height: calc(2.3rem + 2px);
  margin-bottom: 10px
}

label {
  margin-bottom: 0.2rem;
}

</style>

<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header/>
    <div class="clearfix"></div>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar/>
      <!--Main Container -->
      <router-view/>
    </div>
  </div>
</template>

<script>
import AdminHeader from "Components/SuperAdminHeader/SuperAdminHeader.vue";
import AdminSidebar from "Components/SuperAdminHeader/SuperAdminSidebar.vue";

export default {
  components: {
    appAdminHeader: AdminHeader,
    appAdminSidebar: AdminSidebar
  }
};
</script>

<template>
  <div class="sticky">
    <!-- Book Now -->
    <!--		<div class="boxed-widget booking-widget">-->
    <div>
      <div class="form-group focused d-none d-md-block">
        <router-link
          :to="'/salon-booking/' + $route.params.id"
          class="btn btn-1 btn-primary d-block text-light"
          >Book</router-link
        >
      </div>
      <!--			</div>-->
      <!-- Book Now -->
    </div>
    <!-- Book Now / End -->
    <div class="boxed-widget bg-secondary mt-4 text-center p-4">
      <h4 class="mb-4">Personal Contact</h4>
      <div class="mb-3">
        <div class="mb-2">
          <img
            class="img-fluid rounded-circle shadow-lg"
            width="80"
            height="80"
            alt="Image not available"
            :src="(details || {}).user_image ? (details || {}).user_image : ''"
          />
        </div>
        <div class="">
          <h5 class="text-primary">{{ (details || {}).name }}</h5>
        </div>
      </div>
      <ul class="listing-details-sidebar">
        <li><i class="fa fa-phone" /> {{ (details || {}).phone_number }}</li>
        <li><i class="fa fa-envelope-o" />{{ (details || {}).email }}</li>
      </ul>
      <div class="col-lg-4">
        <div class="detail-list">
          <!--					<div class="detail-list-label mb-1"><i aria-hidden="true" class="fa fa-instagram mr-2"></i><small class="text-uppercase font-weight-bold">Instagram.</small></div>-->
          <!--					<p class="m-0 text-small text-muted">{{ salon.instagram }}</p>-->
        </div>
      </div>
    </div>
    <div class="boxed-widget bg-secondary mt-4 p-4">
      <div class="row" v-if="(opening_time ||{})['monday']">
        <div class="col-md-6">Monday</div>
        <div class="col-md-6"></div>
      </div>
      <div class="row" v-if="(opening_time ||{})['tuesday']">
        <div class="col-md-6">Tuesday</div>
        <div class="col-md-6"></div>
      </div>
      <div class="row" v-if="(opening_time ||{})['wednesday']">
        <div class="col-md-6">Wednesday</div>
        <div class="col-md-6"></div>
      </div>
      <div class="row" v-if="(opening_time ||{})['thursday']">
        <div class="col-md-6">Thursday</div>
        <div class="col-md-6"></div>
      </div>
    
    </div>
  </div>
</template>

<script>
// import {mapGetters} from "vuex";

export default {
  data() {
    return {
      selected: ""
    };
  },
  props: ["details", "booking_time", "opening_time"],
  mounted() {
    console.log(this.details,this.booking_time,this.opening_time)
  }
  
};
</script>


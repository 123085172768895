import Swal from "sweetalert2";
<template>
  <div class="dashboard-content">
    <div class="row">
      <!-- booking settings -->
      <div class="col-lg-12">
        <button class="btn btn-link" @click="$router.go(-1)"><i class="fa fa-arrow-left"/> Back to salon Info</button>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="add-listing-section mb-4">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>Booking Details</h3>
            </div>
            <form @submit.prevent="addBooking">
              <div class="row push-down booking">
                <div class="col-sm-12 form-group">
                  <h6>Booking Days</h6>
                  <p>Select all that apply</p>
                  <label
                    ><input
                      class="checkbox-day"
                      type="checkbox"
                      v-model="booking_days"
                      @change="bookingDayOnChange"
                      value="monday"
                    />
                    Mon</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      type="checkbox"
                      @change="bookingDayOnChange"
                      v-model="booking_days"
                      value="tuesday"
                    />
                    Tue</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      @change="bookingDayOnChange"
                      type="checkbox"
                      v-model="booking_days"
                      value="wednesday"
                    />
                    Wed</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      type="checkbox"
                      @change="bookingDayOnChange"
                      v-model="booking_days"
                      value="thursday"
                    />
                    Thu</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      type="checkbox"
                      @change="bookingDayOnChange"
                      v-model="booking_days"
                      value="friday"
                    />
                    Fri</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      type="checkbox"
                      @change="bookingDayOnChange"
                      v-model="booking_days"
                      value="saturday"
                    />
                    Sat</label
                  >
                  <label
                    ><input
                      class="checkbox-day"
                      style="margin-left: 15px;"
                      type="checkbox"
                      @change="bookingDayOnChange"
                      v-model="booking_days"
                      value="sunday"
                    />
                    Sun</label
                  >
                </div>

                <div class="col-sm-6 form-group">
                  <div class="row">
                    <div class="col-md-10">
                      <h5
                        style="font-size: 18px; font-weight:normal;"
                        for="slot_length"
                      >
                        Booking Slot Length
                      </h5>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="hours">Hours</label>
                          <input
                            class="form-control"
                            type="number"
                            v-model="all_time_slot.slot_hours"
                            id="hours"
                            max="24"
                            @input="updateAllBookingSlots"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="mins">Minutes</label>
                          <input
                            class="form-control"
                            type="number"
                            id="mins"
                            min="15"
                            @input="updateAllBookingSlots"
                            v-model="all_time_slot.slot_mins"
                            required
                          />
                        </div>
                      </div>

                      <small
                        >The amount of time between each booking window, i.e
                        00:30 would be 30 mins between window</small
                      >
                    </div>

                    <div class="col-md-10 mb-3 mt-3">
                      <h5
                        style="font-size: 18px; font-weight:normal;"
                        for="booking_limit"
                      >
                        Booking Limit
                      </h5>

                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        v-model="all_time_slot.booking_limit"
                        id="booking_limit"
                        name="booking_limit"
                        value="<?= isset($bookingDetails['timeslots']['timeslot_default_slots']) ? $bookingDetails['timeslots']['timeslot_default_slots'] :'1'?>"
                        required
                      />
                      <small
                        >The amount of bookings that can take place within a
                        given timeslot each timeslot can be altered in the input
                        field provided after a slot is created</small
                      >
                    </div>

                    <div class="col-md-10">
                      <h5
                        style="font-size: 18px; font-weight:normal;"
                        for="advance_bookings"
                      >
                        Advance Bookings
                      </h5>
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        v-model="all_time_slot.advanced_booking"
                        id="advance_bookings"
                        required
                      />
                      <small
                        >Number of weeks in advance a customer can book</small
                      >
                    </div>

                    <!--                  <div class="col-md-10">-->
                    <!--                    <div class="row">-->
                    <!--                      <div class="col-md-6 ">-->
                    <!--                        <h5 style="font-size: 18px; font-weight:normal;">-->
                    <!--                          Lead Time (HRS)-->
                    <!--                        </h5>-->
                    <!--                        <input-->
                    <!--                          class="form-control"-->
                    <!--                          type="number"-->
                    <!--                          min="0"-->
                    <!--                          id="lead_time_hours"-->
                    <!--                          name="lead_time_hours"-->
                    <!--                          required-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                      <div class="col-md-6">-->
                    <!--                        <h5 style="font-size: 18px; font-weight:normal;">-->
                    <!--                          Lead Time (MINS)-->
                    <!--                        </h5>-->
                    <!--                        <input-->
                    <!--                          class="form-control"-->
                    <!--                          type="number"-->
                    <!--                          min="0"-->
                    <!--                          id="lead_time_minutes"-->
                    <!--                          name="lead_time_minutes"-->
                    <!--                          value="<?= isset($bookingDetails['timeslots']['lead_time_minutes']) ?($bookingDetails['timeslots']['lead_time_minutes']) : '0' ?>"-->
                    <!--                          required-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                      <div class="col-md-12"><small-->
                    <!--                      >Number of hours and minutes before the next booking-->
                    <!--                       slot</small-->
                    <!--                      ></div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                  </div>
                </div>

                <div class="col-sm-6 form-group">
                  <div v-for="(d, i) of booking_days" :key="i">
                    <h5
                      class="text-capitalize"
                      style="font-size: 18px; font-weight:normal"
                    >
                      {{ d }}
                    </h5>
                    <div class="row">
                      <div class="col-sm-6 form-group">
                        <label>Start Time</label>
                        <input
                          :class="['form-control day_open', d + '_open']"
                          type="time"
                          min="0"
                          required
                          v-model="all_time_slot.timeslots[d].start_time"
                          @change="timeSlotInput(d)"
                        />
                      </div>
                      <div class="col-sm-6 form-group">
                        <label>End Time</label>
                        <input
                          :class="['form-control day_close', d + '_close']"
                          @change="timeSlotInput(d)"
                          type="time"
                          v-model="all_time_slot.timeslots[d].end_time"
                          required
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-md-3 col-4 form-group"
                        v-for="(t, ind) in all_time_slot.timeslots[d]['slots']"
                        :key="ind"
                      >
                        <div style="font-size:10px;">
                          {{ t.start + " - " + t.end }}
                        </div>
                        <div style="display:flex;">
                          <input
                            style="height:25px; width:50px;"
                            class="form-control"
                            type="number"
                            min="1"
                            v-model="t.booking_limit"
                          />
                          <input
                            style="margin-left: 1rem"
                            type="checkbox"
                            v-model="t.disabled"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Save Booking Info</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <mobilefooter />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Provider from "../../components/Profile/Provider.vue";
import Swal from "sweetalert2";
import { index } from "@/api/resource";

export default {
  data() {
    return {
      profile: {},
      days_of_week: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ],
      service: {
        service_name: "",
        service_price: "",
        service_description: "",
        service_time_taken: ""
      },
      days_number: {
        sunday: "0",
        monday: "1",
        tuesday: "2",
        wednesday: "3",
        thursday: "4",
        friday: "5",
        saturday: "6"
      },
      slot_length: "",
      booking_days: ["monday"],
      all_time_slot: {
        booking_limit: 1,
        slot_hours: 0,
        slot_mins: 30,
        available_days: [],
        disabled_days: [],
        available_days_strings: [],
        disabled_days_strings: [],
        advanced_booking: 1,
        timeslots: {
          monday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          tuesday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          wednesday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          thursday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          friday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          saturday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          sunday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          }
        }
      }
    };
  },
  components: {
    Provider,
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },
  methods: {
    ...mapMutations({
      addSalonData: "user/SET_SALON"
    }),
    addBooking() {
      if (this.booking_days.length > 0) {
        let daysArray = ["0", "1", "2", "3", "4", "5", "6"];
        let salonDays = this.booking_days.map(e => {
          return this.days_number[e];
        });

        this.all_time_slot.disabled_days = daysArray.filter(
          e => !salonDays.includes(e)
        );

        this.all_time_slot.available_days = daysArray.filter(e =>
          salonDays.includes(e)
        );
        this.all_time_slot.disabled_days_strings = this.days_of_week.filter(
          e => !this.booking_days.includes(e)
        );
        this.all_time_slot.available_days_strings = this.days_of_week.filter(
          e => this.booking_days.includes(e)
        );

        let data = {
          salon_id: this.$route.params.id,
          slots: this.all_time_slot
        };

        this.$store
          .dispatch("salon/updateSalonBooking", data)
          .then(res => {
            Swal.fire({
              title: "Success",
              text: "Your bookings have been updated successfully.",
              icon: "success"
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        Swal.fire({
          title: "Error",
          text: "Please select booking days",
          icon: "error"
        });
      }
    },
    bookingDayOnChange(e) {
      this.all_time_slot.timeslots[e.target.value]["slots"] = [];
      this.all_time_slot.timeslots[e.target.value]["end_time"] = "";
      this.all_time_slot.timeslots[e.target.value]["start_time"] = "";
    },

    updateAllBookingSlots() {
      this.booking_days.forEach(e => {
        this.checkOnChange(e);
      });
    },

    convertToMinutes(time) {
      if (time) {
        let new_time = time.split(":");
        return Number(new_time[0]) * 60 + Number(new_time[1]);
      }
    },
    timeSlotInput(e) {
      this.checkOnChange(e);
    },
    convertToHoursAndMinutes(totalMinutes) {
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
      return (
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes)
      );
    },
    checkOnChange(day) {
      let opening = $("." + day + "_open").val();
      let closing = $("." + day + "_close").val();
      let slotLength =
        this.all_time_slot.slot_hours + ":" + this.all_time_slot.slot_mins;
      let slotInMinutes = this.convertToMinutes(slotLength);
      if (
        opening === "" ||
        closing === "" ||
        slotLength === "" ||
        slotInMinutes === 0
      ) {
        return;
      }
      this.all_time_slot.timeslots[day]["slots"] = [];
      let openMinutes = this.convertToMinutes(opening);
      let closeMinutes = this.convertToMinutes(closing);

      let new_time = openMinutes + slotInMinutes;
      let max_slots = 100;

      if (new_time > 0 && slotInMinutes > 0) {
        let s = 1;
        while (new_time <= closeMinutes) {
          this.all_time_slot.timeslots[day]["slots"].push({
            start: this.convertToHoursAndMinutes(openMinutes),
            end: this.convertToHoursAndMinutes(new_time),
            booking_limit: this.all_time_slot.booking_limit,
            disabled: false
          });

          openMinutes = openMinutes + slotInMinutes;
          new_time = new_time + slotInMinutes;

          if (s > max_slots) {
            break;
          }
          s++;
        }
      }

      if (new_time > closeMinutes) {
        if (closeMinutes - openMinutes >= slotInMinutes) {
          this.all_time_slot.timeslots[day]["slots"].push({
            start: this.convertToHoursAndMinutes(new_time - slotInMinutes),
            end: this.convertToHoursAndMinutes(closeMinutes),
            booking_limit: this.all_time_slot.booking_limit,
            disabled: false
          });
        }
      }
      if (this.all_time_slot.timeslots[day]["slots"].length < 1) {
        alert("time slot for " + day + " is incorrect");
        this.all_time_slot.timeslots[day]["end_time"] = "";
      }
    },
    getSalonBooking(id) {
      index("/api/get-salon-by-id/" + id).then(response => {
        const { data } = response;
        if (data["booking_time"]) {
          this.booking_days = data["booking_time"]["available_days_strings"];
          this.all_time_slot = data["booking_time"];
        }
      });
    }
  },

  created() {
    
    if (this.$route.params.id) {
      this.getSalonBooking(this.$route.params.id);
    }
  },

  computed: {
    role() {
      return this.$store.getters.roles;
    }
  }
};
</script>

<template>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger" role="alert" v-if="set_service">
            <i class="fa fa-warning"/> You have not set up any service and your
            customers wont be able to book you.
            <router-link
                class="text-light"
                style="text-decoration: underline"
                to="/admin/services"
            >Click here to set up
            </router-link
            >
          </div>
          <div class="alert alert-danger" role="alert" v-if="set_booking">
            <i class="fa fa-warning"/> Your booking times are empty and your
            customers wont be able to book you.
            <router-link
                class="text-light"
                style="text-decoration: underline"
                to="/admin/booking-settings"
            >
              Click here to set up
            </router-link
            >
          </div>
          <div
              class="alert alert-danger"
              role="alert"
              v-if="salon_details.payment_type === null"
          >
            <i class="fa fa-warning"/> Set your payment option to receive funds
            directly from your customers.
            <router-link
                class="text-light"
                style="text-decoration: underline"
                to="/admin/payment-settings"
            >Click here to set up
            </router-link
            >
          </div>
        </div>
        <!-- Item -->
        <div class="col-lg-3 col-md-6 col-6">
          <div
              class="card mb-4 cursor-pointer"
              @click="$router.push('/admin/bookings')"
          >
            <div class="card-body text-center">
              <i class="i-Add-User"/>
              <div class="py-4">
                <h2 class="text-primary mb-2">
                  {{ (stats["upcoming"] || []).length }}
                </h2>
                <p class="text-muted mt-2 mb-0">Pending Bookings</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="col-lg-3 col-md-6 col-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"/>
              <div class="py-4">
                <h2 class="text-primary mb-2">{{ stats.cash_made | toCurrencyFormatRaw }}</h2>
                <p class="text-muted mt-2 mb-0">Cash Made</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="col-lg-3 col-md-6 col-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"/>
              <div class="py-4">
                <h2 class="text-primary mb-2">
                  {{ (stats["customers"] || []).length }}
                </h2>
                <p class="text-muted mt-2 mb-0">Customers</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="col-lg-3 col-md-6 col-6">
          <div
              class="card mb-4 cursor-pointer"
              @click="$router.push('/admin/services')"
          >
            <div class="card-body text-center">
              <i class="i-Add-User"/>
              <div class="py-4">
                <h2 class="text-primary mb-2">{{ stats["services"] || 0 }}</h2>
                <p class="text-muted mt-2 mb-0">Total Services</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Invoices -->
        <div class="col-lg-12 col-md-12 mb-4">
          <div class="card">
            <div class="db-tile text-center text-md-left">
              <h4 style="font-size: 20px">Upcoming Bookings</h4>
            </div>
            <div
                class="dashboard-list-box table-responsive invoices with-icons"
                v-if="bookings.length > 0"
            >
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Service</th>
                  <th>Booking Date</th>
                  <th>Price ({{ getCurrency }})</th>
                  <th>Customer</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody v-if="!isLoading">
                <tr v-for="(b, i) in bookings" :key="i">
                  <td>
                    <div class="text-capitalize">
                      <div v-if="b.service.length < 1"> N/A</div>
                      <div v-for="(service, index) in b.service" :key="index+'a'">
                        {{ service.service_name }}
                        <span v-if="index < b.service.length - 1">, </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ b.date_time | toBookingDateTime }} @
                    {{ b.start_time + " - " + b.end_time }}
                  </td>
                  <td>
                    {{ b.sub_total | toCurrencyFormatRaw }}
                  </td>
                  <td>{{ b["user"]["name"] }}</td>
                  <td>{{ b["user"]["phone_number"] ? b["user"]["phone_number"] : 'N/A' }}</td>
                  <td>
                     <span
                         v-bind:class="getBadgeClass(b.status)"
                         class="badge badge-pill text-uppercase"
                     >{{ getStatusText(b.status) }}</span>
                  </td>
                  <td>
                    <div v-if="b.status ==='pending'">
                      <button class="btn btn-sm btn-link text-danger"
                              @click="updateStatus(b['appointment_id'], 'rejected')">
                        Reject
                      </button>
                      <button class="btn btn-primary btn-sm" @click="updateStatus(b['appointment_id'], 'accepted')">
                        Accept
                      </button>
                      <i class="fa fa-eye cursor-pointer"
                         @click="$router.push(`/shop-booking-details/${b.appointment_id}`)"></i>


                    </div>
                    <div v-if="b.status ==='accepted'">
                      <button class="btn btn-success btn-sm" v-if="b.status === 'accepted'"
                              @click="updateStatus(b['appointment_id'], 'completed')">Mark as completed
                      </button>
                      <i class="fa fa-eye cursor-pointer"
                         @click="$router.push(`/shop-booking-details/${b.appointment_id}`)"></i>
                    </div>

                    <div v-if="b.status ==='completed'" class="cursor-pointer">
                      <i class="fa fa-eye" @click="$router.push(`/shop-booking-details/${b.appointment_id}`)"></i>
                    </div>

                  </td>
                </tr>
                <tr v-if="bookings.length < 1">
                  <td colspan="6" class="text-center">
                    No bookings at the moment
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr v-for="(a, i) in 5" :key="i">
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                  <td>
                    <div class="lines shine"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <img
                  src="@/assets/images/no_booking.png"
                  class="img-fluid"
                  style="width: 300px"
                  alt=""
              />
              <h6 class="mb-5">No bookings at the moment</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mobilefooter/>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from "vuex";
import moment from "moment";

const {mapActions} = createNamespacedHelpers("appointment");
import Swal from "sweetalert2";
import {putUpdate} from "@/api/resource";

export default {
  data() {
    return {
      bookings: [],
      isLoading: false,
      stats: {},
      salon_info: this.$store.state.user.salon,
      salon_details: this.$store.state.user.salon,
      set_service: false,
      set_booking: false,
      getCurrency: process.env.VUE_APP_PRICE_SYMBOL,
    };
  },
  computed: {},
  components: {
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
  },

  methods: {
    ...mapActions(["delete", "index"]),
    getBadgeClass(status) {
      return {
        'badge-danger': status === 'rejected',
        'badge-warning': status === 'pending',
        'badge-info': status === 'accepted',
        'badge-success': status === 'completed',
      };
    },
    getStatusText(status) {
      switch (status) {
        case 'rejected':
          return 'rejected';
        case 'pending':
          return 'Pending';
        case 'accepted':
          return 'Accepted';
        case 'completed':
          return 'Completed';
        default:
          return '';
      }
    },
    getSalonInfo() {
      if (this.salon_info) {
        if (this.salon_info["user"]["sign_up_stage"] < 7) {
          this.$router.push("/setup-profile");
        } else {
          if (this.salon_info["services"].length < 1) {
            this.set_service = true;
          }
          if (this.salon_info["booking_time"].length < 1) {
            this.set_booking = true;
          }
        }
      } else {
        alert('no salon info')
      }

    },

    getAppointments() {
      this.isLoading = true;
      this.index()
          .then((res) => {
            this.stats = res.stats;
            this.bookings = res.appointments;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    updateStatus(id, status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
            status === "completed"
                ? "Yes, complete it!"
                : status === 'accepted' ? "Yes, Accept it" : status === 'rejected' ? 'Reject it!' : '',
      }).then((result) => {
        if (result.isConfirmed) {
          putUpdate(`/api/appointments/${id}`, {
            status: status,
          }).then((res) => {
            this.getAppointments();
            Swal.fire(
                "Success!",
                "Appointment has been " + status + " successfully.",
                "success"
            );
          });
        }
      });
    },
  },
  created() {
    this.getSalonInfo();
    this.getAppointments();
  },
};
</script>

<style>
div >>> .b-mobile-bar {
  bottom: 90px !important;
}
</style>

import { render, staticRenderFns } from "./EditBlog.vue?vue&type=template&id=896a2d6c&scoped=true"
import script from "./EditBlog.vue?vue&type=script&lang=js"
export * from "./EditBlog.vue?vue&type=script&lang=js"
import style0 from "./EditBlog.vue?vue&type=style&index=0&id=896a2d6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "896a2d6c",
  null
  
)

export default component.exports
import Dashboard from "../views/superAdminDashboard/Dashboard";
import SuperAdminPanel from "../layouts/superAdminPanel/SuperAdminPanel";
import shopDetails from "../views/superAdminDashboard/shopDetails";
import AdminSalonProfileSettings from "../views/superAdminDashboard/AdminSalonProfileSettings";
import AdminSalonServices from "../views/superAdminDashboard/AdminSalonServices";
import AdminUpdateSalonService from "../views/superAdminDashboard/AdminUpdateSalonService";
import AdminSalonPersonalProfile from "../views/superAdminDashboard/AdminSalonPersonalProfile";
import AdminSalonBookingSettings from "../views/superAdminDashboard/AdminSalonBookingSettings";
import AdminAddService from "../views/superAdminDashboard/AdminAddService";
import GiveAwayUsers from "../views/superAdminDashboard/GiveAwayUsers";
import AllBookings from "../views/superAdminDashboard/AllBookings";
import CreateBlog from "Views/superAdminDashboard/CreateBlog";
import AllBlogs from "Views/superAdminDashboard/AllBlogs";
import EditBlog from "Views/superAdminDashboard/EditBlog";
import WaitingList from "Views/superAdminDashboard/WaitingList";
import ShopRatings from "Views/superAdminDashboard/ShopRatings";

// dashboard components
// const Dashboard = () => import('Views/adminPages/Dashboard');
// const Messages = () => import('Views/adminPages/Messages');


export default {
  path: "/super-admin",
  component: SuperAdminPanel,
  // redirect: "/super-admin/home",
  children: [
    {
      path: "/super-admin/home",
      component: Dashboard,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Shops"
      }
    },
    {
      path: "/super-admin/waiting-list",
      component: WaitingList,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "WaitingList"
      }
    },
    {
      path: "/super-admin/shop-ratings",
      component: ShopRatings,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "ShopRatings"
      }
    },
    {
      path: "/shop-details/:id",
      component: shopDetails,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "salonDetails"
      }
    },
    {
      path: "/give-away-users",
      component: GiveAwayUsers,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "giveAwayUsers"
      }
    },

    {
      path: "/super-admin/blog",
      component: CreateBlog,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Create a new blog"
      }
    },
    {
      path: "/super-admin/blog/:slug",
      component: EditBlog,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Edit Blog"
      }
    },
    {
      path: "/super-admin/all-blogs",
      component: AllBlogs,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "All Blogs"
      }
    },
    {
      path: "/all-bookings",
      component: AllBookings,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "allBookings"
      }
    },
    {
      path: "/shop-details/:id/personal-info/:name",
      component: AdminSalonProfileSettings,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Salon Profile"
      }
    },
    {
      path: "/shop-details/:name/services/:id",
      component: AdminSalonServices,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Salon Services"
      }
    },
    {
      path: "/update-service/:id/salon/:salon_name",
      component: AdminUpdateSalonService,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Update Salon Service"
      }
    },

    {
      path: "/add-new-service/:salon_name",
      component: AdminAddService,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Add Salon Service"
      }
    },

    {
      path: "/salon-personal-info/:id/:name",
      component: AdminSalonPersonalProfile,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Salon Personal Profile"
      }
    },
    {
      path: "/booking-settings/:id/:name",
      component: AdminSalonBookingSettings,
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
        title: "Salon Booking Settings"
      }
    }
  ]
};

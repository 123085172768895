import Swal from "sweetalert2";
<template>
  <div class="col-12">
    <div class="col-lg-12 col-md-12">
      <div class="dashboard-list-box mb-5">
        <div class="dashboard-list-box-static">
          <div class="my-profile">
            <h4 class="mb-3">Update Salon Details</h4>
            <div class="form-group">
              <div class="custom-file">
                <FileUpload
                  @change="
                    file => {
                      return (
                        (profile.carousel = []), profile.carousel.push(file)
                      );
                    }
                  "
                  name="carousel"
                />
              </div>
            </div>
            <div class="form-group">
              <FileUpload
                @change="
                  file => {
                    return (profile.avatar = []), profile.avatar.push(file);
                  }
                "
                name="avatar"
              />
            </div>

            <div class="row with-forms">
              <div class="col-md-4">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="profile.salon_name"
                    name="salon_name"
                    id="salon_name"
                    placeholder="Salon Name"
                    class="form-control form-control-alternative"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Phone"
                    v-model="profile.phone_number"
                    class="form-control form-control-alternative"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <input
                    type="integer"
                    placeholder="booking limit"
                    class="form-control form-control-alternative"
                    v-model="profile.booking_limit"
                  />
                </div>
              </div>
            </div>

            <div class="add-listing-headline">
              <h3>Location</h3>
            </div>
            <div class="submit-section">
              <!-- Row -->
              <div class="row with-forms">
                <!-- City -->
                <div class="col-md-6">
                  <select
                    class="form-control form-control-alternative"
                    v-model="profile.region"
                  >
                    <option selected>Region</option>
                    <option>AHAFO</option>
                    <option>ASHANTI</option>
                    <option>BONO EAST</option>
                    <option>BRONG AHAFO</option>
                    <option>CENTRAL</option>
                    <option>EASTERN</option>
                    <option>GREATER ACCRA</option>
                    <option>NORTH EAST</option>
                    <option>NORTHERN</option>
                    <option>OTI</option>
                    <option>SAVANNAH</option>
                    <option>UPPER EAST</option>
                    <option>UPPER WEST</option>
                    <option>WESTERN</option>
                    <option>WESTERN NORTH</option>
                    <option>VOLTA</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Street Name"
                      class="form-control form-control-alternative"
                      v-model="profile.street"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Town"
                      class="form-control form-control-alternative"
                      v-model="profile.town"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="City"
                      class="form-control form-control-alternative"
                      v-model="profile.city"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!--  get long and lat-->
            <div class="add-listing-headline">
              <h3>Social Media Handles</h3>
            </div>
            <div class="row with-forms">
              <!-- Phone -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="twitter">Twitter</label>
                  <input
                    id="twitter"
                    type="text"
                    placeholder="Twitter"
                    class="form-control form-control-alternative"
                    v-model="profile.twitter"
                  />
                </div>
              </div>
              <!-- Website -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="facebook">Facebook</label>
                  <input
                    type="text"
                    id="facebook"
                    placeholder="Facebook"
                    class="form-control form-control-alternative"
                    v-model="profile.facebook"
                  />
                </div>
              </div>
              <!-- Email Address -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="instagram">Instagram</label>
                  <input
                    type="text"
                    placeholder="Instagram"
                    id="instagram"
                    class="form-control form-control-alternative"
                    v-model="profile.instagram"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <!--                          <input type="time" placeholder="Opening time" class="form-control form-control-alternative" v-model="profile.opening_time">-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                      <div>-->
              <!--                        <div class="form-group">-->
              <!--                          <input type="time" placeholder="Closing time" class="form-control form-control-alternative" v-model="profile.closing_time">-->
              <!--                        </div>-->
            </div>

            <label>Description</label>
            <textarea
              class="form-control form-control-alternative mb-4"
              name="notes"
              id="notes"
              cols="5"
              rows="5"
              v-model="profile.description"
            >
Description of your salon</textarea
            >
          </div>
          <!-- Checkboxes -->
          <!--                  list of all services available-->

          <!-- Checkboxes / End -->
          <button @click="update" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="dashboard-list-box mb-5">
        <div class="add-listing-section mb-4">
          <!-- Headline -->
          <div class="add-listing-headline">
            <h3>Services You provide</h3>
          </div>

          <!-- Switcher ON-OFF Content -->
          <!-- Day -->

          <div class="row mb-4">
            <div class="col-md-6">
              <p>
                Category :
                <span class="badge badge-info">{{
                  ((profile || {}).salon_category || {})[0][
                    ["service_category_name"]
                  ]
                }}</span>
              </p>
            </div>
            <div class="col-md-6 text-right">
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#addServiceModal"
              >
                <i class="fa fa-plus" /> Add new service
              </button>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-4"
              v-for="(s, i) in (profile || {}).services"
              :key="i"
            >
              <form @submit.prevent="updateService(s, i)">
                <div class="form-group">
                  <label>Service Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="s.service_name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Price</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="s.service_price"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Time Taken in minutes</label>
                  <input
                    type="number"
                    class="form-control"
                    min="1"
                    v-model="s.service_time_taken"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    v-model="s.service_description"
                    rows="3"
                    required
                  />
                </div>
                <div class="mt-3">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeService(s.service_id, i)"
                  >
                    <i class="fa fa-trash" /> Remove
                  </button>
                  <button type="submit" class="btn btn-sm btn-primary">
                    <i class="fa fa-trash" /> Update
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Switcher ON-OFF Content / End -->
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="dashboard-list-box mb-5">
        <div class="add-listing-section mb-4">
          <!-- Headline -->
          <div class="add-listing-headline">
            <h3>Booking Details</h3>
          </div>
          <form @submit.prevent="addBooking">
            <div class="row push-down booking">
              <div class="col-sm-12 form-group">
                <h6>Booking Days</h6>
                <p>Select all that apply</p>
                <label
                  ><input
                    class="checkbox-day"
                    type="checkbox"
                    v-model="booking_days"
                    @change="bookingDayOnChange"
                    value="monday"
                  />
                  Mon</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="tuesday"
                  />
                  Tue</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="wednesday"
                  />
                  Wed</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="thursday"
                  />
                  Thu</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="friday"
                  />
                  Fri</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="saturday"
                  />
                  Sat</label
                >
                <label
                  ><input
                    class="checkbox-day"
                    style="margin-left: 15px;"
                    type="checkbox"
                    v-model="booking_days"
                    value="sunday"
                  />
                  Sun</label
                >
              </div>

              <div class="col-sm-6 form-group">
                <div class="row">
                  <div class="col-md-10">
                    <h5
                      style="font-size: 18px; font-weight:normal;"
                      for="slot_length"
                    >
                      Booking Slot Length
                    </h5>
                    <div class="row">
                      <div class="col-md-6">
                        <label for="hours">Hours</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="all_time_slot.slot_hours"
                          id="hours"
                          max="24"
                          @input="updateAllBookingSlots"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="mins">Minutes</label>
                        <input
                          class="form-control"
                          type="number"
                          id="mins"
                          min="15"
                          @input="updateAllBookingSlots"
                          v-model="all_time_slot.slot_mins"
                          required
                        />
                      </div>
                    </div>

                    <small
                      >The amount of time between each booking window, i.e 00:30
                      would be 30 mins between window</small
                    >
                  </div>

                  <div class="col-md-10">
                    <h5
                      style="font-size: 18px; font-weight:normal;"
                      for="booking_limit"
                    >
                      Booking Limit
                    </h5>

                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      v-model="all_time_slot.booking_limit"
                      id="booking_limit"
                      name="booking_limit"
                      value="<?= isset($bookingDetails['timeslots']['timeslot_default_slots']) ? $bookingDetails['timeslots']['timeslot_default_slots'] :'1'?>"
                      required
                    />
                    <small
                      >The amount of bookings that can take place within a given
                      timeslot each timeslot can be altered in the input field
                      provided after a slot is created</small
                    >
                  </div>

                  <div class="col-md-10">
                    <h5
                      style="font-size: 18px; font-weight:normal;"
                      for="advance_bookings"
                    >
                      Advance Bookings
                    </h5>
                    <input
                      class="form-control"
                      type="number"
                      min="1"
                      v-model="all_time_slot.advanced_booking"
                      id="advance_bookings"
                      required
                    />
                    <small
                      >Number of weeks in advance a customer can book</small
                    >
                  </div>

                  <!--                  <div class="col-md-10">-->
                  <!--                    <div class="row">-->
                  <!--                      <div class="col-md-6 ">-->
                  <!--                        <h5 style="font-size: 18px; font-weight:normal;">-->
                  <!--                          Lead Time (HRS)-->
                  <!--                        </h5>-->
                  <!--                        <input-->
                  <!--                          class="form-control"-->
                  <!--                          type="number"-->
                  <!--                          min="0"-->
                  <!--                          id="lead_time_hours"-->
                  <!--                          name="lead_time_hours"-->
                  <!--                          required-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                      <div class="col-md-6">-->
                  <!--                        <h5 style="font-size: 18px; font-weight:normal;">-->
                  <!--                          Lead Time (MINS)-->
                  <!--                        </h5>-->
                  <!--                        <input-->
                  <!--                          class="form-control"-->
                  <!--                          type="number"-->
                  <!--                          min="0"-->
                  <!--                          id="lead_time_minutes"-->
                  <!--                          name="lead_time_minutes"-->
                  <!--                          value="<?= isset($bookingDetails['timeslots']['lead_time_minutes']) ?($bookingDetails['timeslots']['lead_time_minutes']) : '0' ?>"-->
                  <!--                          required-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                      <div class="col-md-12"><small-->
                  <!--                      >Number of hours and minutes before the next booking-->
                  <!--                       slot</small-->
                  <!--                      ></div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>

              <div class="col-sm-6 form-group">
                <div v-for="(d, i) of booking_days" :key="i">
                  <h5
                    class="text-capitalize"
                    style="font-size: 18px; font-weight:normal"
                  >
                    {{ d }}
                  </h5>
                  <div class="row">
                    <div class="col-sm-6 form-group">
                      <label>Start Time</label>
                      <input
                        :class="['form-control day_open', d + '_open']"
                        type="time"
                        min="0"
                        required
                        v-model="all_time_slot.timeslots[d].start_time"
                        @input="timeSlotInput(d)"
                      />
                    </div>
                    <div class="col-sm-6 form-group">
                      <label>End Time</label>
                      <input
                        :class="['form-control day_close', d + '_close']"
                        @input="timeSlotInput(d)"
                        type="time"
                        v-model="all_time_slot.timeslots[d].end_time"
                        required
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-3 col-xs-3 form-group"
                      v-for="(t, ind) in all_time_slot.timeslots[d]['slots']"
                      :key="ind"
                    >
                      <div style="font-size:10px;">
                        {{ t.start + " - " + t.end }}
                      </div>
                      <div style="display:flex;">
                        <input
                          style="height:25px; width:50px;"
                          class="form-control"
                          type="number"
                          min="1"
                          v-model="t.booking_limit"
                        />
                        <input
                          style="margin-left: 1rem"
                          type="checkbox"
                          v-model="t.disabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary">Save Booking Info</button>
          </form>
        </div>
      </div>
    </div>

    <Consumer />

    <!-- Modal -->
    <div
      class="modal fade"
      id="addServiceModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="addService">
            <div class="modal-body">
              <div class="form-group">
                <label for="name">Service Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="service.service_name"
                  required
                  id="name"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group">
                <label for="price">Price</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="service.service_price"
                  required
                  id="price"
                />
              </div>
              <div class="form-group">
                <label for="time_taken">Time Taken in minutes</label>
                <input
                  type="number"
                  class="form-control"
                  min="1"
                  v-model="service.service_time_taken"
                  required
                  id="time_taken"
                />
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  class="form-control"
                  v-model="service.service_description"
                  id="description"
                  required
                  rows="3"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Consumer from "./Consumer.vue";
import FileUpload from "../inputs/FileUpload";
import Swal from "sweetalert2";

export default {
  name: "Provider",
  data() {
    return {
      days_of_week: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ],
      service: {
        service_name: "",
        service_price: "",
        service_description: "",
        service_time_taken: ""
      },
      days_number: {
        sunday: "0",
        monday: "1",
        tuesday: "2",
        wednesday: "3",
        thursday: "4",
        friday: "5",
        saturday: "6"
      },
      slot_length: "",
      booking_days: ["monday", "tuesday", "wednesday"],
      all_time_slot: {
        booking_limit: 1,
        slot_hours: 0,
        slot_mins: 15,
        available_days: [],
        disabled_days: [],
        available_days_strings: [],
        disabled_days_strings: [],
        advanced_booking: 1,
        timeslots: {
          monday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          tuesday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          wednesday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          thursday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          friday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          saturday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          },
          sunday: {
            start_time: "",
            end_time: "",
            slots: [],
            total_delivery_slots: 0,
            total_delivery_windows: 0
          }
        }
      }
    };
  },

  components: { Consumer, FileUpload },

  methods: {
    ...mapMutations({
      addSalonData: "user/SET_SALON"
    }),

    addBooking() {
      if (this.booking_days.length > 0) {
        let daysArray = ["0", "1", "2", "3", "4", "5", "6"];
        let salonDays = this.booking_days.map(e => {
          return this.days_number[e];
        });

        this.all_time_slot.disabled_days = daysArray.filter(
          e => !salonDays.includes(e)
        );

        this.all_time_slot.available_days = daysArray.filter(e =>
          salonDays.includes(e)
        );
        this.all_time_slot.disabled_days_strings = this.days_of_week.filter(
          e => !this.booking_days.includes(e)
        );
        this.all_time_slot.available_days_strings = this.days_of_week.filter(
          e => this.booking_days.includes(e)
        );

        let data = {
          salon_id: this.profile.salon_id,
          slots: this.all_time_slot
        };

        this.$store
          .dispatch("salon/updateSalonBooking", data)
          .then(res => {
            Swal.fire({
              title: "Success",
              text: "Your bookings have been updated successfully.",
              icon: "success"
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        Swal.fire({
          title: "Error",
          text: "Please select booking days",
          icon: "error"
        });
      }
    },
    addService() {
      let data = {
        salon_id: this.profile.salon_id,
        service_category_id: this.profile["salon_category"][0][
          "service_category_id"
        ],
        ...this.service
      };

      this.$store
        .dispatch("salon/addService", data)
        .then(res => {
          this.profile.services.push(res.data);
          $("#addServiceModal").modal("hide");
          Swal.fire({
            title: "Success",
            text: "Service has been created successfully.",
            icon: "success"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    removeService(service_id, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("salon/removeService", { service_id: service_id })
            .then(res => {
              this.profile.services.splice(index, 1);
              Swal.fire({
                title: "Success",
                text: "Service has been deleted successfully.",
                icon: "success"
              });
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },

    updateService(service, index) {
      this.$store
        .dispatch("salon/updateService", service)
        .then(res => {
          this.profile.services[index] = service;
          Swal.fire({
            title: "Success",
            text: "Service has been deleted successfully.",
            icon: "success"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    bookingDayOnChange(e) {
      this.checkOnChange(e.target.value);
    },

    updateAllBookingSlots() {
      this.booking_days.forEach(e => {
        this.checkOnChange(e);
      });
    },

    convertToMinutes(time) {
      if (time) {
        let new_time = time.split(":");
        return Number(new_time[0]) * 60 + Number(new_time[1]);
      }
    },
    timeSlotInput(e) {
      this.checkOnChange(e);
    },
    convertToHoursAndMinutes(totalMinutes) {
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
      return (
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes)
      );
    },
    checkOnChange(day) {
      let opening = $("." + day + "_open").val();
      let closing = $("." + day + "_close").val();
      let slotLength =
        this.all_time_slot.slot_hours + ":" + this.all_time_slot.slot_mins;
      let slotInMinutes = this.convertToMinutes(slotLength);
      if (
        opening === "" ||
        closing === "" ||
        slotLength === "" ||
        slotInMinutes === 0
      ) {
        return;
      }

      this.all_time_slot.timeslots[day]["slots"] = [];
      let openMinutes = this.convertToMinutes(opening);
      let closeMinutes = this.convertToMinutes(closing);

      let new_time = openMinutes + slotInMinutes;
      let max_slots = 100;

      if (new_time > 0 && slotInMinutes > 0) {
        let s = 1;
        while (new_time <= closeMinutes) {
          this.all_time_slot.timeslots[day]["slots"].push({
            start: this.convertToHoursAndMinutes(openMinutes),
            end: this.convertToHoursAndMinutes(new_time),
            booking_limit: this.all_time_slot.booking_limit,
            disabled: false
          });

          openMinutes = openMinutes + slotInMinutes;
          new_time = new_time + slotInMinutes;

          if (s > max_slots) {
            break;
          }
          s++;
        }
      }

      if (new_time > closeMinutes) {
        if (closeMinutes - openMinutes >= slotInMinutes) {
          this.all_time_slot.timeslots[day]["slots"].push({
            start: this.convertToHoursAndMinutes(new_time - slotInMinutes),
            end: this.convertToHoursAndMinutes(closeMinutes),
            booking_limit: this.all_time_slot.booking_limit,
            disabled: false
          });
        }
      }
      // });
    },

    update() {
      this.$store
        .dispatch("salon/update", this.profile)
        .then(() => {
          console.log(response);
          // this.addSalonData(response);
          console.log("success after salon update");
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  created() {
    if (this.profile.booking_time) {
      this.booking_days = this.profile.booking_time.available_days_strings;
      this.all_time_slot = this.profile.booking_time;
    }
  },
  computed: {
    ...mapGetters({
      profile: "salon_user"
    })
  }
};
</script>

export function convertTo12HourTime(time24) {
    // Split the time string into hours and minutes
    let parts = time24.split(':');
    let hours = parseInt(parts[0], 10);
    let minutes = parseInt(parts[1], 10);

    // Determine if it's AM or PM
    let ampm = hours >= 12 ? 'pm' : 'am';

    // Convert to 12-hour format
    if (hours > 12) {
        hours -= 12;
    } else if (hours === 0) {
        hours = 12;
    }

    // Format the time in 12-hour format
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + ampm;
    // Output: "01:44 AM"
}

export function convertToHoursAndMinutes(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return (
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes)
    );
}

export function convertToMinutes(time) {
    if (time) {
        let new_time = time.split(":");
        return Number(new_time[0]) * 60 + Number(new_time[1]);
    }
}


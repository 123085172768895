import router, { constantRoutes } from "./router";
import store from "./store";
// import { Message } from 'element-ui'
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!hasToken) {
      next(`/login?redirect=${to.path}`);
    } else {
      if (
        to.matched.some(record => record.meta["requiresConsumer"]) &&
        !store.getters.roles.includes("CONSUMER")
      ) {
        if (store.getters.roles.includes("PROVIDER")) {
          next({
            path: "/admin/dashboard"
          });
        } else if (store.getters.roles.includes("ADMIN")) {
          next({
            path: "/super-admin/home"
          });
        }
      } else if (
        to.matched.some(record => record.meta["requiresProvider"]) &&
        !store.getters.roles.includes("PROVIDER") &&
        !store.getters.roles.includes("ADMIN")
      ) {
        if (store.getters.roles.includes("CONSUMER")) {
          next({
            path: "/customer/dashboard"
          });
        } else if (store.getters.roles.includes("ADMIN")) {
          next();
        }
      } else if (
        to.matched.some(record => record.meta["requiresSuperAdmin"]) &&
        !store.getters.roles.includes("ADMIN")
      ) {
        if (store.getters.roles.includes("PROVIDER")) {
          next({
            path: "/admin/dashboard"
          });
        } else if (store.getters.roles.includes("CONSUMER")) {
          next({
            path: "/customer/dashboard"
          });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (hasToken) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
  NProgress.done();
});

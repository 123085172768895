<template>
  <div class="dashboard-content">
    <div class="container-fluid">
      <div class="row responsive-row">
        <div class="col-lg-12 col-md-12">
          <div class="text-primary mb-3 cursor-pointer" @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i> Back
          </div>
          <h4 class="text-center mb-3">Advanced Booking Settings</h4>
          <div class="text-primary mb-3 cursor-pointer text-right text-underline" @click="$router.push('/admin/booking-settings')">
            <i class="fa fa-cog"></i> Booking Settings
          </div>
          <div class="card">
              <full-calendar :config="config" :events="events" ref="calendar"/>
<!--              custom slot modal-->
                <div class="modal fade" id="bookingModal" tabindex="-1" aria-labelledby="bookingModalLabel"
                     aria-hidden="true" v-if="calendarData">
                  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6 class="modal-title"><span class="d-md-inline d-none">Hours for</span> <span
                            class="text-capitalize">{{ calendarData.day }}</span> -
                          {{ calendarData.date|toFormattedDate }} </h6>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-12">
                            <div style="font-size:10px;">
                              <input
                                  style="margin-right: 5px"
                                  id="close-day"
                                  type="checkbox"
                                  v-model="calendarData.close"
                              />
                              <label for="close-day">Closed</label>

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <input id="start" :disabled="calendarData.close" v-model="calendarData.start_time"
                                   @change="checkOnChange()" class="form-control" type="time"/>
                          </div>
                          <div class="col-6">
                            <input id="end" :disabled="calendarData.close" v-model="calendarData.end_time"
                                   @change="checkOnChange()" class="form-control" type="time"/>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div
                              class="col-md-4 col-6 form-group"
                              v-for="(t, ind) in calendarData.slots"
                              :key="ind"
                          >
                            <div style="font-size:10px;">
                              <input
                                  style="margin-right: 5px"
                                  :id="'slot_time'+ind"
                                  type="checkbox"
                                  :disabled="calendarData.close"
                                  v-model="t.checked"

                              />
                              <label :for="'slot_time'+ind">{{ t.start + " - " + t.end }}</label>

                            </div>
                            <div style="display: flex">
                              <input
                                  v-b-popover.hover.bottom="'Expected number of bookings'"
                                  style="height:25px;"
                                  class="form-control"
                                  type="number"
                                  :disabled="calendarData.close"
                                  min="1"
                                  v-model="t.booking_limit"
                              />
                              <i class="fa fa-info-circle mt-1 ml-2"
                                 v-b-popover.hover.bottom="'Expected number of bookings'"></i>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="modal-footer text-left">
                        <button class="btn btn-dark btn-sm mr-4" @click="setCustomSlot()">Set Hours</button>
                        <button class="btn btn-light btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
<!--end of custom slot modal-->
          </div>
        </div>
      </div>
      <admin-mobile-footer/>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {FullCalendar} from "vue-full-calendar";
import {index} from "@/api/resource";
import {convertTo12HourTime, convertToHoursAndMinutes, convertToMinutes} from "@/utils/externalFxns";
import Swal from "sweetalert2";

const {mapActions} = createNamespacedHelpers("appointment");

export default {
  name: "AdvancedBookingSettings",
  data() {
    var self = this;
    return {
      salonInfo: this.$store.state.user.salon,
      events: [],
      targetDivId: '',
      show: false,
      calendarData: '',
      isPopoverVisible: false,
      config: {
        defaultView: "month",
        header: {
          left: "prev,next,today",
          center: "title",
          right: "agendaWeek,agendaDay,month",
        },
        all_time_slot: {},
        slotDuration: "00:30:00",
        // minTime: "06:00:00",
        // maxTime: "22:00:00",
        selectable: false,
        editable: false,
        contentHeight: "auto",
        height: "auto",

        displayEventTime: false,
        eventRender: function (event, element, view) {
          // custom time slot logic on calendar - Check if the event's date is within any of the specified ranges
          let customTimeSlot = self.all_time_slot.customTimeSlots.some(function (range) {
            let rangeDate = range.date; // Extract the date key from the range object
            let rangeStart = moment(rangeDate, 'YYYY-MM-DD');
            let rangeEnd = rangeStart.clone().endOf('day');
            return (
                event.start.isSameOrAfter(rangeStart) &&
                event.end.isSameOrBefore(rangeEnd)
            );
          });

          // set calendar to closed if there are no time slots

          // Add a CSS class to closed events
          let uniqueId = moment(event.start).format('YYYY-MM-DD');
          // check if it's a custom time slot or normal time slot
          if (customTimeSlot) {
            let customTime = self.getCustomSlotData(event);
            if (!customTime.close) {
              let slots = customTime.slots
                  .filter(function (slot) {
                return slot.checked;
              });
              if (slots.length <=0){
                element.find(".fc-content").html("<div id=" + uniqueId + "> <div class='mb-md-5 text-center mt-md-4'>" + 'Closed' + "</div><div/>");
                return;
              }
              let slotsHtml = '<div id="' + uniqueId + '">';
              slots.forEach(function (slot, i) {
                slotsHtml += '<div class="mb-1">' + convertTo12HourTime(slot['start']) + ' - ' + convertTo12HourTime(slot['end']) + (i < customTime.slots.length - 1 ? ',' : '') + '</div>';
              });
              slotsHtml += '<div/>';

              element.find('.fc-content').html(slotsHtml);
            } else {
              element.find(".fc-content").html("<div id=" + uniqueId + "> <div class='mb-md-5 text-center mt-md-4'>" + 'Closed' + "</div><div/>");
            }

          } else {
            let slots = event.slots.filter(function (slot) {
              return slot.checked;
            });

            if (slots && slots.length > 0) {
              let slotsHtml = '<div id="' + uniqueId + '">';
              slots.forEach(function (slot, i) {
                slotsHtml += '<div class="mb-1">' + convertTo12HourTime(slot['start']) + ' - ' + convertTo12HourTime(slot['end']) + (i < event.slots.length - 1 ? ',' : '') + '</div>';
              });
              slotsHtml += '<div/>';


              element.find('.fc-content').html(slotsHtml);
            }
          }

          return true;
        },



        eventClick: function (info) {
          self.showPopover(info);
        },
      },
      all_time_slot: {},
    };
  },
  components: {
    FullCalendar,
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter"),
  },
  watch: {
    'calendarData.slots': {
      handler(slots) {
        if(this.calendarData.close) {
          return;
        }
       let checkedSlots = slots.filter(s=>s.checked);
        this.calendarData.close = checkedSlots.length === 0;
      },
      deep: true // Watch for changes deeply within the 'slots' array
    }
  },
  methods: {
    ...mapActions(["delete", "index"]),

    getCustomSlotData(event) {
      let event_date = event.start.format('YYYY-MM-DD')
      return this.all_time_slot.customTimeSlots.find((e) => e.date === event_date);
    },

    showPopover(info) {
      let date = moment(info.start).format('YYYY-MM-DD');
      // check if data belongs to custom slot
      let customSlotData = this.all_time_slot.customTimeSlots.find((e) => e.date === date);


      if (customSlotData) {
        this.calendarData = customSlotData;
        // this.calendarData.close = customSlotData.close;
      } else {
       let data = JSON.parse(JSON.stringify(info.data))
        this.calendarData = {
          close: false,
          date: date,
          day: data.day,
          end_time: data.end_time,
          start_time: data.start_time,
          slots: data.slots
        };
      }
  
      this.$nextTick(() => {
        $("#bookingModal").modal("show");
      });
    },

    checkOnChange() {
      let opening = this.calendarData.start_time;
      let closing = this.calendarData.end_time;
      let slotLength = this.all_time_slot.slot_hours + ":" + this.all_time_slot.slot_mins; //slot length should be the days slot length
      // find timeSlot from main timeSlots

      let slotInMinutes = convertToMinutes(slotLength);
      if (
          opening === "" ||
          closing === "" ||
          slotLength === "" ||
          slotInMinutes === 0
      ) {
        return;
      }

      this.calendarData.slots = [];
      let openMinutes = convertToMinutes(opening);
      let closeMinutes = convertToMinutes(closing);

      let new_time = openMinutes + slotInMinutes;
      let max_slots = 100;

      if (new_time > 0 && slotInMinutes > 0) {
        let s = 1;
        while (new_time <= closeMinutes) {
          this.calendarData.slots.push({
            start: convertToHoursAndMinutes(openMinutes),
            end: convertToHoursAndMinutes(new_time),
            booking_limit: this.all_time_slot.booking_limit,
            checked: true
          });

          openMinutes = openMinutes + slotInMinutes;
          new_time = new_time + slotInMinutes;

          if (s > max_slots) {
            break;
          }
          s++;
        }
      }

      if (new_time > closeMinutes) {
        if (closeMinutes - openMinutes >= slotInMinutes) {
          this.calendarData.slots.push({
            start: convertToHoursAndMinutes(new_time - slotInMinutes),
            end: convertToHoursAndMinutes(closeMinutes),
            booking_limit: this.all_time_slot.booking_limit,
            checked: false
          });
        }
      }
      if (this.calendarData.slots.length < 1) {
        alert("time slot is incorrect");
        this.calendarData.end_time = "";
      }
    },

    saveSalonBookingSlot() {
      $("#bookingModal").modal("hide"); //disable modal

      let data = {
        user_id: this.salonInfo.user_id,
        slots: this.all_time_slot
      };

      this.isLoading = true;
      this.$store
          .dispatch("salon/updateSalonBooking", data)
          .then(res => {
            Swal.fire({
              title: "Success",
              text: "Your bookings have been updated successfully.",
              icon: "success"
            });
            this.getSalonInfo();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    setCustomSlot() {
      let customSlotIndex = this.all_time_slot.customTimeSlots.findIndex((e) => e.date === this.calendarData.date);

      if (customSlotIndex > -1) {
        this.all_time_slot.customTimeSlots[customSlotIndex] = this.calendarData;
      } else {
        this.all_time_slot.customTimeSlots.push(this.calendarData);
      }

      this.saveSalonBookingSlot();

    },

    getSalonInfo() {
      this.isLoading = true;
      let url = `/api/salon/${this.salonInfo.slug}`;
      index(url)
          .then(response => {
            const {data} = response;
            if (data["booking_time"]) {
              this.all_time_slot = data["booking_time"];
              this.all_time_slot.customTimeSlots = this.all_time_slot.customTimeSlots || [];
              this.calendarEventInfo(this.all_time_slot.timeslot)
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    calendarEventInfo(events) {
      let mappedEvents = [];
      // Iterate through the timeslot array and transform each object into an event
      events.forEach(function (slot, i) {
        if (slot.checked && slot.start_time && slot.end_time) {
          mappedEvents.push({
            id: i,
            data: slot,
            dow: [slot.value.toString()], // days to be active
            start: slot.start_time,
            end: slot.end_time,
            slots: slot.slots,
            className: 'event-none'
            // Other properties specific to your events
          });

        }
      });
      this.events = mappedEvents;
      return mappedEvents;
    }
  },


  mounted() {
    this.$loadScript(
        "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {
      this.getSalonInfo();
    });
  },
};
</script>

<style scoped>
@media screen and (min-width: 300px) and (max-width: 500px) {
  #calendar {
    width: 100% !important;
    margin: 0 auto;
    font-size: 17px;
  }
}

>>> .fc-toolbar .fc-center h2 {
  margin-top: 10px !important;
}

.pop-over-wide {
  /*max-width: 100vw;*/
}

@media screen and (min-width: 500px) {
  .pop-over-wide {
    max-width: 40vw;
  }
}


</style>

import { render, staticRenderFns } from "./AdminAddService.vue?vue&type=template&id=88e99736"
import script from "./AdminAddService.vue?vue&type=script&lang=js"
export * from "./AdminAddService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
<div :class="view">
  <div class="col-lg-3 col-sm-6">
    <div class="form-group">
      <input type="text" v-model="search_name" placeholder="What are you looking for?" class="form-control">
    </div>
  </div>
  <div class="col-lg-3 col-sm-6">
    <div class="form-group">
      <input type="text" v-model="search_location" placeholder="Location" class="form-control">
    </div>
  </div>
  <div class="col-lg-3 col-sm-6">
    <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" v-model="search_category">
      <option value="" selected>Choose a Category</option>
      <option v-for="category in serviceCategories" :key="category.service_category_id" :value="category.service_category_name"> {{category.service_category_name}} </option>
    </select>
  </div>
  <div class="col-lg-3 col-sm-6 text-right">
    <a class="btn main-search-btn btn-radius btn-lg btn-primary text-white"><span class="btn-inner--text">Search</span></a>
  </div>
</div>
</template>

<script>

import {createNamespacedHelpers, mapGetters} from "vuex";
const { mapMutations } = createNamespacedHelpers('salon')

export default {
  name: "Search",
  props: [
      'view',
  ],
  computed : {
    search_name : {
      set(val) {
        this.set_name(val)
        // this.search_name = val
      },
      get() {
        return this.$store.getters.search_name
      }
    },
    search_location : {
      set(val) {
        this.set_location(val)
        // this.search_name = val
      },
      get() {
        return this.$store.getters.search_location
      }
    },
    search_category : {
      set(val) {
        this.set_category(val)
        // this.search_name = val
      },
      get() {
        return this.$store.getters.search_category
      }
    },
    ...mapGetters(['serviceCategories']),
  },
  watch: {
    search_name: function (val) {
      this.set_name(val)
    },
    search_location: function (val) {
      this.set_location(val)
    },
    search_category: function (val) {
      this.set_category(val)
    },
  },
  methods : {
    ...mapMutations({
      set_name : 'SET_SEARCH_NAME',
      set_location: 'SET_SEARCH_LOCATION',
      set_category: 'SET_SEARCH_CATEGORY'
    }),
  }
}
</script>

<style scoped>

</style>
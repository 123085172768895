<template>
  <div class="dashboard-content">
    <div class="row">
      <!-- Profile -->

      <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <form @submit.prevent="updateBlog">
              <div class="my-profile">
                <h4 class="mb-3">Create Blog</h4>
                <div class="row with-forms">
                  <!-- Phone -->
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="twitter">Title</label>
                      <input
                          id="twitter"
                          type="text"
                          placeholder="Twitter"
                          required
                          class="form-control form-control-alternative"
                          v-model="data.title"
                      />
                    </div>
                  </div>
                </div>

                <div class="row with-forms">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="twitter">Main Image</label>
                      <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" @change="customFileUpload" class="custom-file-input form-control" id="customFile"
                                 aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <label>Description</label>
                <vue-editor v-model="data.body" required
                            useCustomImageHandler
                            @image-added="handleImageAdded"
                ></vue-editor>
              </div>

              <button
                  type="submit"
                  class="btn btn-primary mt-2"
                  :disabled="isLoading"
              >
                {{ isLoading ? 'Submitting...' : 'Submit' }}
              </button>
            </form>
          </div>
        </div>
      </div>
      <mobilefooter/>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import Provider from "../../components/Profile/Provider.vue";
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import {index, putUpdate, update} from "@/api/resource";
import {VueEditor} from "vue2-editor";
import $ from "jquery";

export default {
  name: "Admin-edit-blog",
  data() {
    return {
      categories: [],
      salonCategory: [],
      disableButton: false,
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      profile: {},
      data:{
        image:'',
        body:'',
        title:'',
      },
      content: "",
    };
  },
  components: {
    Provider,
    loading: () => import("vue-loading-overlay"),
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
    VueEditor
  },
  methods: {
    ...mapMutations({
      addSalonData: "user/SET_SALON"
    }),
    customFileUpload(e){
      this.data.image = e.target.files[0];
      $('.custom-file-label').html(e.target.files[0].name);
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("file", file);
      store("/api/upload-image", formData)
          .then(response => {
            const {data} = response;
            Editor.insertEmbed(cursorLocation, "image", data);
            resetUploader();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    updateBlog(){
      if(this.data.body ===''){
        alert('Please add a description');
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append("image", this.data.image.name?this.data.image:'');
      formData.append("body", this.data.body);
      formData.append("title", this.data.title);
      formData.append("id", this.data.id);
      console.log(formData);
      update(`/api/update-blog`,this.data.id,formData)
          .then(() => {
            Swal.fire({
              title: "Success",
              text: "Blog has been updated successfully",
              icon: "success"
            }).then(() => {
              this.$router.push("/all-blogs");
            });

          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    getBlog() {
      index("/api/get-blog/" + this.$route.params.slug)
          .then(response => {
            const {data} = response;
            this.data = data;
            $('.custom-file-label').html(data.image);

            console.log(data,'blog data');
          });
    },



  },

  mounted() {
    this.getBlog();
  }
};
</script>


<style scoped>
.add-listing-headline {
  padding-bottom: 10px;
}
</style>

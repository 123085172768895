<template>
  <div class="dashboard-content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    />
    <div class="row">
      <div class="col-lg-12">
        <button class="btn btn-link" @click="$router.go(-1)"><i class="fa fa-arrow-left"/> Back to salon Info</button>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h4 class="mb-3">Profile Details</h4>
            <div class="my-profile">
              <div>
                <div class="custom-file">
                  <label for="user_image">User Image</label>
                  <FileUpload
                    id="user_image"
                    @change="
                      file => {
                        return (
                          (user_profile.user_image = []),
                          user_profile.user_image.push(file)
                        );
                      }
                    "
                    name="user_image"
                  />
                </div>
              </div>
              <div class="mt-4">
                <div class="form-group">
                  <label for="full_name">Full Name</label>
                  <input
                    type="text"
                    id="full_name"
                    placeholder="Full Name"
                    class="form-control form-control-alternative"
                    v-model="user_profile.name"
                  />
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label for="phone_number">Phone Number</label>
                  <input
                    id="phone_number"
                    type="text"
                    placeholder="Phone"
                    v-model="user_profile.phone_number"
                    class="form-control form-control-alternative"
                  />
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label for="location">Location</label>
                  <input
                    type="text"
                    id="location"
                    placeholder="Location"
                    v-model="user_profile.location"
                    class="form-control form-control-alternative"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="email">E-mail Address</label>
                <input
                  type="email"
                  id="email"
                  v-model="user_profile.email"
                  placeholder="E-mail"
                  class="form-control form-control-alternative"
                  readonly
                />
              </div>
            </div>
            <button @click="update" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
    <mobilefooter />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileUpload from "../../components/inputs/FileUpload";
import Swal from "sweetalert2";
import { index, update } from "@/api/resource";

export default {
  name: "Consumer",
  data() {
    return {
      user_profile: {},
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128
    };
  },
  components: {
    FileUpload,
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
    Loading: () => import("vue-loading-overlay")
  },

  methods: {
    getSaloonDetail() {
      if (this.$route.params.id) {
        this.isLoading = true;
        index("/api/get-salon-by-id/" + this.$route.params.id)
          .then(response => {
            const { data } = response;
            this.user_profile = data["user"];
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    update() {
      update(
        "/api/update_user_profile",
        this.user_profile.id,
        this.user_profile
      )
        .then(response => {
          const { data } = response;
          Swal.fire({
            title: "Success",
            text: "Your profile has been updated successfully",
            icon: "success"
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.getSaloonDetail();
  },
  computed: {
    ...mapGetters({
      profile: "user_data"
    })
  }
};
</script>

<!-- Header Structure -->
<template>
<header class="header-global">
    <nav class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom" id="navbar-main">
        <div class="custom-container-fluid mt-0 mb-0" style="display: inherit">
            <div id="logo1">
                <router-link to="/"><img alt="" src="/static/images/logo.png" style="width: 45px;" /></router-link>
            </div>

            <div class="dropdown text-light d-block d-sm-none">
                <div aria-expanded="false" aria-haspopup="true" class=" dropdown-toggle" data-toggle="dropdown" href="#" id="navbar-primary_dropdown_1" role="button">
                    <img :src="user['user_image']" class="img-fluid" style="width: 30px; height: 30px;" alt="" v-if="user" />
                    <i class="fa fa-user-circle" v-else />
                </div>
                <div aria-labelledby="navbar-primary_dropdown_1" class="dropdown-menu dropdown-menu-right">
                    <!--            <router-link class="dropdown-item cursor-pointer blink_me" to="/giveaway"-->
                    <!--            >**GIVEAWAYS**-->
                    <!--            </router-link>-->
                    <div v-if="!user">

                        <router-link class="dropdown-item cursor-pointer" to="/login">Login
                        </router-link>
                        <router-link class="dropdown-item cursor-pointer" to="/sign-up-business">Partner With Us
                        </router-link>
                        <router-link class="dropdown-item cursor-pointer" to="/contact-us">Contact Us
                        </router-link>
                        <a class="dropdown-item cursor-pointer" hre="https://docs.google.com/forms/d/10QgqcHTkhD_o5taLAIbzHB4XCACdyoWAzn5QpXZd8_o/closedform">Feedback
                        </a>
                        <a class="dropdown-item cursor-pointer" href="https://docs.google.com/document/d/1yf3F5jre41I-_d4olGWAYH5yBihTbOOG-s6ZXKgNeFM/edit">Terms and Conditions
                        </a>
                        <div class="dropdown-item cursor-pointer">
                            <a target="_blank" href="https://twitter.com/stylemeghana/">
                                <i class="fa fa-twitter mr-3" /></a>
                            <a target="_blank" href="https://www.instagram.com/stylemeghana/"><i class="fa fa-instagram" /></a>
                        </div>
                    </div>
                    <div v-else>
                        <router-link to="/logout" class="dropdown-item cursor-pointer">Logout
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="navbar-collapse collapse" id="navbar_global">
                <div class="navbar-collapse-header">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <div id="logo">
                                <router-link to="/"><img alt="" class="img-fluid" height="25" src="/static/images/logo.png" width="65" /></router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <!--          <div class="mr-auto ml-auto" style="display:inline-flex; align-items:center">-->
                <!--            <div class="has-search">-->
                <!--              <span class="fa fa-search form-control-feedback" />-->
                <!--              <input-->
                <!--                type="text"-->
                <!--                style="height:40px; width:400px"-->
                <!--                class="form-control form-control-alternative search-container"-->
                <!--                placeholder="Search for a service or venue"-->
                <!--              />-->
                <!--            </div>-->
                <!--            <div class="text-light ml-2"> <span> in Accra, ghana</span></div>-->
                <!--          </div>-->

                <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
                    <ul class="m-0 p-0">
                        <app-menu />
                    </ul>
                    <div class="header-widget">
                        <router-link class="btn btn-neutral btn-icon btn-radius" to="/salons">Book A Service
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>
</template>

<script>
import Menu from "Components/Menu/FrontEndMenu.vue";
import {
    getUser
} from "@/utils/auth";

export default {
    data() {
        return {
            user: getUser()
        };
    },
    methods: {
        logout() {
            this.$store
                .dispatch("user/logout")
                .then(() => {
                    this.$router.push({
                        path: "/"
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    components: {
        appMenu: Menu
    }
};
</script>

<style scoped>
@media (max-width: 991.98px) {

    .navbar-expand-lg>.custom-container-fluid,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar>.custom-container-fluid,
    .navbar>.container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

.form-control {
    border-radius: 5px !important;
}

.has-search input {
    padding-left: 2.375rem !important;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.5rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>

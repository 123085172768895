<template>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Ratings</h4>
            <div class="row mb-md-2">
              <div class="col-6 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <span class="mr-1">Show</span>
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >
                    <span class="ml-1">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-6 col-md-6">
                <div
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">

                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  table-class="table table-centered w-100"
                  :busy="!isLoading"
                  :items="ratingList"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template v-slot:cell(created_at)="data">
                  {{ formatDate(data.item['created_at']) }}
                </template>
                <template v-slot:cell(Action)="data">
                  <button v-if="!data.item.approve" @click="changeRatingStatus(data.item.rating_id,1)"
                          class="btn btn-primary btn-sm"
                  >Approve
                  </button>
                  <button v-else @click="changeRatingStatus(data.item.rating_id,0)"
                          class="btn btn-danger btn-sm"
                  >Disapprove
                  </button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <admin-mobile-footer/> -->
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from "vuex";
import moment from "moment";
import {apisService} from "@/api/apis.service";

const {mapActions} = createNamespacedHelpers("salon");
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      fields: [
        {
          key: "salon",
          sortable: true,
          label: "Salon",
          formatter: (value, key, item) => {
            return item.salon.salon_name ? item.salon.salon_name : "N/A";
          }
        }, {
          key: "comment",
          sortable: true,
          label: "Comment",
          formatter: (value, key, item) => {
            return item.comment ? item.comment : "N/A";
          }
        },
        {
          key: "rating",
          sortable: true,
          label: "Rating",
          formatter: (value, key, item) => {
            return item.rating_value ? item.rating_value : "N/A"
          }
        },
        {
          key: "user",
          sortable: true,
          label: "User",
          formatter: (value, key, item) => {
            return item.user.name ? item.user.name : "N/A"
          }

        }, {
          key: "created_at",
          sortable: true,
          label: "Date Created",

        },
        'Action'

      ],
      ratingList: [],
      isLoading: false,
      stats: {}
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ratingList.length;
    },
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getRatingList() {
      this.isLoading = true;
      apisService.getShopRatings().then((response) => {
        const {success, message, data} = response;
        if (success) {
          this.ratingList = data;
        } else {
          alert("Error - " + message)
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    changeRatingStatus(id, approve) {
      let r = confirm("Are you sure you want to perform this action?");
      if (r) {
        this.isLoading = true;
        apisService.changeRatingStatus({id, approve}).then((response) => {
          const {success, message, data} = response;
          if (success) {
            this.getRatingList();
          } else {
            alert("Error - " + message)
          }
        }).finally(() => {
          this.isLoading = false;
        });
      }

    },
    formatDate(d) {
      return d ? moment(d).format("Do MMM, YYYY hh:mm A") : "";
    }
  },
  created() {
    this.getRatingList();
  }
};
</script>

<style>
div >>> .b-mobile-bar {
  bottom: 90px !important;
}
</style>

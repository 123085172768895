<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <ul>
            <li>
              <router-link to="/customer/bookings"
                ><i class=" fa fa-calendar" /> Bookings</router-link
              >
            </li>
            <li>
              <router-link to="/customer/schedules"
                ><i class=" fa fa-calendar" /> Schedules</router-link
              >
            </li>
            <li>
              <router-link to="/customer/profile"
                ><i class=" fa fa-calendar" />Profile</router-link
              >
            </li>
            <li @click.prevent="logout">
              <router-link to="/"
                ><i class="fa fa-sign-out" /> Logout</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <i class="fa fa-reorder" />
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch(error => {
          console.log(error);
        });
    },

    toggleMenu() {
      let wrapper = $("#wrapper");
      if (wrapper.hasClass("sidebar-in")) {
        wrapper.removeClass("sidebar-in");
      } else {
        wrapper.addClass("sidebar-in");
      }
    }
  }
};
</script>

<template>
  <div class="container pt-5">
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="db-tile">
            <h4>My Bookings</h4>
          </div>
          <div class="dashboard-list-box table-responsive invoices with-icons">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>Service</th>
                <th>Booking Date</th>
                <th>Price ({{getCurrency}})</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody v-if="!isLoading">
              <tr v-for="(b, i) in bookings" :key="i">
                <td>
                  <div class="text-capitalize">
                    <div v-for="(service, index) in b.service" :key="index+'a'">
                      {{ service.service_name }}
                      <!-- Display a comma if it's not the last item in the array -->
                      <span v-if="index < b.service.length - 1">, </span>
                    </div>
                  </div>
                </td>
                <td>
                  {{ formatDate(b.date_time) }} @
                  {{ b.start_time + " - " + b.end_time }}
                </td>
                <td>
                  {{getCurrency}}{{b.total}}
                </td>
                <td>
                    <span
                        v-bind:class="getBadgeClass(b.status)"
                        class="badge badge-pill text-uppercase"
                    >{{ getStatusText(b.status) }}</span>
                </td>
                <td>
                  <button
                      v-if="!b.rating && b.status === 'completed'"
                      @click="appointmentDetails = b"
                      class="btn btn-info btn-sm"
                      type="button"
                      data-toggle="modal" data-target="#rateModal"
                  >
                    <i class="fa fa-star"/> Rate shop
                  </button>
                  <router-link :to="`/booking-details/${b.appointment_id}/service`" class="btn btn-primary btn-sm"> Details
                  </router-link>
                </td>
              </tr>
              <tr v-if="bookings.length < 1">
                <td colspan="4" class="text-center">
                  <img
                      src="../../../public/static/images/no_booking.png"
                      style="width: 200px"
                      class="img-fluid"
                      alt=""
                  />

                  <div class="text-center">
                    No bookings at the moment
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr v-for="(a, i) in 5" :key="i">
                <td>
                  <div class="lines shine"/>
                </td>
                <td>
                  <div class="lines shine"/>
                </td>
                <td>
                  <div class="lines shine"/>
                </td>
                <td>
                  <div class="lines shine"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <mobilefooter/>
    <!--Rate Shop Modal -->
    <div
        class="modal fade"
        id="rateModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="rateModalModalTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div style="position: absolute; top:-10px; right:-5px;">
            <button
                style="opacity: 1"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                          <span
                              aria-hidden="true"
                              style="background-color: #7592db;color: #ffffff;"
                          >&times;</span
                          >
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="appointmentDetails">
              <div class="col-md-12 text-center">
                <img :src="appointmentDetails.salon.user.user_image" style="width:50px;" />
                <h4 class="mb-0 mt-1"> Rate your experience with <span
                    class="text-lowercase">{{ appointmentDetails.salon.salon_name }}</span>?</h4>
              </div>

              <div class="col-12 mt-4 mb-4">
                <div class="d-flex justify-content-center">
                  <div class="text-center mr-3"><i :class="['fa fa-star',rating >=1?'text-warning':'']"
                                                   @click="rating =1;"
                                                   style="font-size: 30px"/><br>
                    Terrible
                  </div>
                  <div class="text-center mr-3"><i :class="['fa fa-star',rating >=2?'text-warning':'']"
                                                   @click="rating =2;"
                                                   style="font-size: 30px"/><br> Bad
                  </div>
                  <div class="text-center mr-3"><i :class="['fa fa-star',rating >=3?'text-warning':'']"
                                                   @click="rating =3;"
                                                   style="font-size: 30px"/><br> Okay
                  </div>
                  <div class="text-center mr-3"><i :class="['fa fa-star',rating >=4?'text-warning':'']"
                                                   @click="rating =4;"
                                                   style="font-size: 30px"/><br> Good
                  </div>
                  <div class="text-center mr-3"><i :class="['fa fa-star',rating >=5?'text-warning':'']"
                                                   @click="rating =5;"
                                                   style="font-size: 30px"/><br> Great
                  </div>
                </div>

              </div>
              <div class="col-md-12">
                <label for="message">Add comment </label>
                <textarea class="form-control" maxlength="600" v-model="comment" placeholder="comment"
                          id="message"></textarea>
                <div class="text-right text-muted">{{ comment.length }}/600</div>
              </div>
            </div>

            <div class="col-12 mt-3 text-center">
              <button class="btn btn-primary" @click="rateShop">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import moment from "moment";

const {mapActions} = createNamespacedHelpers("appointment");
import Swal from "sweetalert2";
import {putUpdate} from "@/api/resource";
import {apisService} from "@/api/apis.service";

export default {
  data() {
    return {
      bookings: [],
      isLoading: false,
      value: 1,
      appointmentDetails: '',
      comment: '',
      rating: ''
    };
  },
  computed: {
    getCurrency() {
      return process.env.VUE_APP_PRICE_SYMBOL
    },
  },
  components: {
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },
  methods: {
    ...mapActions(["delete", "index"]),

    getBadgeClass(status) {
      return {
        'badge-danger': status === 'rejected',
        'badge-warning': status === 'pending',
        'badge-info': status === 'accepted',
        'badge-success': status === 'completed',
      };
    },
    getStatusText(status) {
      switch (status) {
        case 'rejected':
          return 'rejected';
        case 'pending':
          return 'Pending';
        case 'accepted':
          return 'Accepted';
        case 'completed':
          return 'Completed';
        default:
          return '';
      }
    },

    getAppointments() {
      this.isLoading = true;
      this.index()
          .then(res => {
            this.bookings = res.appointments;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    formatDate(d) {
      return d ? moment(d).format("Do MMMM, YYYY") : "";
    },
    rateShop() {
      if (!this.rating) {
        alert('Rating is required');
        return;
      }
      this.isLoading = true;
      let formData = {
        salon_id: this.appointmentDetails.salon_id,
        rating: this.rating,
        appointment_id: this.appointmentDetails.appointment_id,
        comment: this.comment
      }
      apisService.rateShop(formData).then((response) => {
        const {success, message} = response;
        if (success) {
          $("#rateModal").modal("hide");
          this.getAppointments();
          this.comment = '';
          this.rating = '';
          this.appointmentDetails = '';
          alert(message);
        } else {
          alert("Error - " + message)
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    updateStatus(id, status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
            status === "completed"
                ? "Yes, complete it!"
                : "Yes, " + status + " it!"
      }).then(result => {
        if (result.isConfirmed) {
          putUpdate(`/api/appointments/${id}`, {
            status: status
          }).then(res => {
            this.getAppointments();
            Swal.fire(
                "Success!",
                "Appointment has been " + status + " successfully.",
                "success"
            );
          });
        }
      });
    }
  },
  created() {
    this.getAppointments();
  }
};
</script>

<style scoped>
.text-warning {
  color: #ffc61c !important;
}
</style>

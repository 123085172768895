<template>
    <div class="modal" v-if="isOpen">
      <div class="modal-content">
        <!-- Modal content here -->
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Modal',
    props: {
      isOpen: {
        type: Boolean,
        default: false
      }
    }
  };
  </script>
  
  <style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
  padding-bottom: 30%;
}

.modal-content {
  max-width: 400px; /* Adjust the maximum width as per your requirement */
  width: 90%; /* Adjust the width as per your requirement */
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}

  </style>
  
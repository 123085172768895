<template>
  <router-view/>
</template>

<script>
// import Vue from 'vue';
//
// import 'beautify-scrollbar/dist/index.css';
//
// import V2LazyList from 'v2-lazy-list';
//
// Vue.use(V2LazyList);

export default {
  created() {
    // this.getList();
  },
  methods: {
    getList() {
 
      this.$store.dispatch("serviceCategory/index").then(() => {});
    },
    
  },
};
</script>


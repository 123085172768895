<template>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Shops</h4>
            <div class="row mb-md-2">
              <div class="col-6 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <span class="mr-1">Show</span>
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >
                    <span class="ml-1">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-6 col-md-6">
                <div
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">

                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  table-class="table table-centered w-100"
                  :busy="!isLoading"
                  :items="salons"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template v-slot:cell(created_at)="data">
                  {{ formatDate(data.item['created_at']) }}
                </template>
                <template v-slot:cell(Status)="data">
                  <span class="badge badge-pill badge-success text-uppercase"
                        v-if="data.item.approve === 1">Approved</span>

                  <span
                      class="badge badge-pill badge-info text-uppercase"
                      v-if="data.item.approve === 0"
                  >Pending Approval</span
                  >
                </template>
                <template v-slot:cell(Action)="data">
                  <router-link
                      :to="'/shop-details/' + data.item.salon_id"
                      class="btn btn-primary btn-sm"
                  >Details
                  </router-link
                  >
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <admin-mobile-footer/>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from "vuex";
import moment from "moment";

const {mapActions} = createNamespacedHelpers("salon");
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      fields: [
        {
          key: "salon_name",
          sortable: true,
          label: "Shop name",
          formatter: (value, key, item) => {
            return item.salon_name?item.salon_name:"Not set";
          }
        },
        {
          key: "name",
          sortable: true,
          label: "Shop Owner",
          formatter: (value, key, item) => {
            return item.name?item.name:"Not set"
          }
        },
        {
          key: "phone_number",
          sortable: true,
          label: "Phone number",
          formatter: (value, key, item) => {
            return item.phone_number?item.phone_number:"N/A"
          }

        }, {
          key: "location",
          sortable: true,
          label: "Location",
          formatter: (value, key, item) => {
            return item.location?item.location:"N/A"
          }

        }, {
          key: "region",
          sortable: true,
          label: "Region",
          formatter: (value, key, item) => {
            return item.region?item.region:"N/A"
          }

        }, {
          key: "created_at",
          sortable: true,
          label: "Date Created",

        },
        'Status',
        'Action'
      ],
      salons: [],
      isLoading: false,
      stats: {}
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.salons.length;
    },
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },
  methods: {
    ...mapActions(["allshops"]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getShops() {
      this.isLoading = true;
      this.allshops()
          .then(res => {
            this.salons = res;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    formatDate(d) {
      return d ? moment(d).format("Do MMM, YYYY hh:mm A") : "";
    }
  },
  created() {
    this.getShops();
  }
};
</script>

<style>
div >>> .b-mobile-bar {
  bottom: 90px !important;
}
</style>

<template>
  <div class="main-wrapper">

    <gallery-slider v-if="((salon || {})['salon_images'] || []).length > 0" :data="(salon || {})['salon_images']"/>

    <div v-else style="margin-top: 2rem"/>

    <!-- Content -->
    <div class="custom-container-fluid">
      <div class="content pt-xs-2">

        <div class="row sticky-wrapper">
          <div class="col-md-2 d-none d-md-block">
            <div class="timeline" style="position: sticky;top: 130px;">
              <div class="nav flex-column side-link nav-pills" id="v-pills-tab" role="tablist"
                   aria-orientation="vertical">
                <a v-for="(category, i) in salon.salon_category" :key="i" :id="'service' + i"
                   class="nav-link mb-2 cursor-pointer service-link" :class="[i === 0 ? 'active' : '']"
                   @click="scrollToService('service' + i)">{{ category["service_category_name"] }}</a>
                <a v-if="((salon || {}).salon_category || []).length < 1" class="nav-link mb-2 cursor-pointer">
                  No service
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-7 mb-3">
            <div class="">
              <div class="detail-tile mb-md-2">
                <h3>{{ salon.salon_name }} <span style="float: right;"> 
                  <a href="#" @click.prevent="shareLink()"
                               class="btn btn-neutral btn-icon-only btn-round btn-lg"><i
                    class="fa fa-share"></i></a></span></h3>

                <h6 v-if="reviews.length>0">
                  <i class="fa fa-star text-warning"></i> {{ getAverageReview() }}/5 Great <span
                    class="text-muted">{{ reviews.length }} reviews</span></h6>
                <div class="text-muted" v-if="(salon || {}).location">
                  <i class="fa fa-map-marker mr-2" style="font-size:20px;color:darkred"/>
                  {{ (salon || {})["location"] }}
                </div>
                <div class="accordion" id="accordionExample" v-if="(salon || {})['description']">
                  <button class="btn btn-link pl-0" type="button" data-toggle="collapse" data-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                    Description <i class="fa fa-chevron-down"/>
                  </button>

                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <p>{{ (salon || {})["description"] }}</p>
                  </div>
                </div>
                <span v-for="(category, i) in (salon || {}).categories" :key="i"
                      class="badge badge-pill badge-info text-uppercase mr-2">{{ category }}</span>
              </div>
              <div class="nav-wrapper mb-md-4">
                <ul class="nav nav-pills nav-fill flex-md-row" id="tabs-icons-text" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link mb-sm-3 mb-md-0 active show service" id="service-tab" data-toggle="tab"
                       href="#service" role="tab" aria-controls="service" aria-selected="false"><i
                        class="fa fa-money mr-2"/>Services</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link mb-sm-3 mb-md-0 location" id="location-tab" data-toggle="tab"
                       href="#tabs-icons-text-2" @click="enableMap()" role="tab" aria-controls="tabs-icons-text-2"
                       aria-selected="false"><i class="fa fa-map-marker mr-2"/>Location</a>
                  </li>
                </ul>
              </div>
              <!-- <div class="card shadow">
          <div class="card-body"> -->
              <div class="tab-content pl-0 pr-0" id="myTabContent">
                <div class="tab-pane fade location" id="tabs-icons-text-2" role="tabpanel"
                     aria-labelledby="location-tab">
                  <div id="map"></div>
                </div>
                <div class="tab-pane active fade show service" id="service" role="tabpanel"
                     aria-labelledby="service-tab">

                  <div v-if="!isLoading">

                    <div v-for="(category,i) in salon.salon_category" :key="i" :id="'service' + i">
                      <div class="h3 font-weight-bold">
                        {{ category.service_category_name }}
                      </div>
                      <span class="pr-0" v-for="(service, a) in category.services" :key="service.service_id">

                                        <div class="card shadow" style="margin-bottom: 5%;">
                                            <div class="card-body">
                                                <router-link
                                                    :to=" '/booking/' + salon['slug'] + '?categoryInd=' + i +  '&serviceInd=' + a ">

                                                    <div class="row">
                                                        <div class="col-md-8" style="width: 70%;">
                                                            <h5> {{ service.service_name }} </h5>

                                                            <p style="color: #000;" :class="isPhoneView"> {{
                                                                service.service_description
                                                              }} </p>
                                                                <i class="fa fa-clock-o"></i>
                                                                <span style="color:#000"> {{ service.service_time_taken | formattedTime }}</span>
                                                        </div>

                                                        <div class="col-md-4" style="width:30%">
                                                            <div class="img-container">
                                                                <img :src="
                                                                        service['images'].length > 0
                                                                        ? service['images'][0]['image']
                                                                        : ''
                                                                    " alt="" style=" object-fit:cover;"
                                                                     class="img-fluid img-thumbnail"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="badge">{{ symbol }}{{ service.service_price }}</div>

                                                </router-link>
                                                <div>

                                                    <a href="#" :class="hideModal" @click="openModal(category,service)">Read More</a>

                                                    <modal :is-open="isModalOpen">
                                                        <h5> {{ modal_service.service_name }} </h5>
                                                        <slick :options="slickOptions" class="category-carousel">
                                                            <div v-for="slide in slides" :key="slide.id">
                                                                <img style="height: 100px;" :src="slide.image"
                                                                     alt="Slide">
                                                            </div>
                                                        </slick>
                                                        <p>{{ modal_service.service_description }}</p>
                                                        <div><i class="fa fa-clock-o"></i>
                                                        <span style="color:#000"> {{ modal_service.service_time_taken | formattedTime }}</span></div>
                                                        <p style="font-weight: 800;font-size: 19px;">{{
                                                            symbol
                                                          }} {{ modal_service.service_price }}</p>

                                                        <button class="closebutton" @click="closeModal">Close</button>
                                                    </modal>
                                                </div>
                                            </div>
                                        </div>

                                    </span>
                    </div>

                    <div class="pricing-list-container">
                      <div v-if="((salon || {}).salon_category || []).length < 1" class="text-center">
                        <img src="@/assets/images/no_booking.png" class="img-fluid" style="width: 300px;" alt=""/>
                        <h6>No Services at the moment</h6>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="lines shine w-25 mb-2"></div>
                    <span class="pr-0" v-for="(service, i) in 2" :key="i">
                        <div class="card shadow" style="margin-bottom: 5%;">
                            <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8" style="width: 70%;">
                                            <h5 class="lines shine"></h5>

                                            <p class="lines shine"></p>
                                          <div style="color:#000" class="lines shine"></div>
                                          <div style="color:#000" class="lines shine"></div>
                                          <div style="color:#000" class="lines shine"></div>
                                        </div>

                                        <div class="col-md-4" style="width:30%">
                                            <div class="img-container">
                                                 <div class="photo shine"/>
                                            </div>
                                        </div>
                                    </div>
                                <div>

                                </div>
                            </div>
                        </div>

                    </span>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div class="col-md-3 col-lg-3 ">
            <div style="position: sticky;top: 130px;">
              <!-- Book Now -->
              <div class="form-group focused d-none d-md-block">
                <button @click="goToShopBooking" class="btn btn-1 btn-primary d-block text-light w-100">
                  Book Salon
                </button>
              </div>
              <div class="boxed-widget bg-secondary mt-4 p-4">
                <h6 class="text-center">Opening Hours</h6>
                <hr class="mt-0">
                <div v-if="!isOpeningDaysLoader">
                  <div class="row mb-3" v-for="(b, i) in opening_days" :key="i">
                    <div class="col-5">
                      <div class="text-capitalize" :class="[b.today ? 'font-weight-bold text-primary' : '']">
                        {{ b.day }}
                      </div>
                    </div>
                    <div class="col-7" :class="[b.today ? 'font-weight-bold text-primary' : '']">
                      <div v-if="!b.close" v-b-toggle="'collapse-'+i">
                          <span>{{ b["start_time"] }}</span> -
                          <span>{{ b["end_time"] }}</span>
                          <small class="when-open"><i class="fa fa-chevron-up ml-1"></i></small>
                          <small class="when-closed"><i class="fa fa-chevron-down ml-1"></i></small>

                        <b-collapse :id="'collapse-'+i" class="mt-2">
                          <div v-for="(slot,s) in b.slots" :key="s" style="font-size:15px">
                            <span>{{ slot["start"] }}</span> -
                            <span>{{ slot["end"] }}</span> <i class="fa fa-calendar" style="font-size:10px"></i>
                          </div>
                        </b-collapse>
                      </div>
                      <div v-else class="text-danger">Closed</div>
                    </div>
                  </div>
                  <div v-if="opening_days.length < 1" class="text-center">
                    No hours found
                  </div>
                </div>
                <div v-else>
                  <div class="lines shine"/>
                  <div class="lines shine mt-4"/>
                  <div class="lines shine mt-4"/>
                  <div class="lines shine mt-4"/>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="row">

          <div class="col-md-10 offset-md-2 mt-5">
            <h2>Review</h2>
            <div class="row" v-if="reviews.length >0">
              <div class="col-md-5">
                <div class="card">
                  <div class="card-body">
                    <div style="font-size: 23px">
                      <i class="fa fa-star mr-1 text-warning" v-for="(v,a) in Math.round(getAverageReview()||0)"
                         :key="'c'+a"/>
                      <i class="fa fa-star mr-1 text-ash" v-for="(v,b) in 5-Math.round(getAverageReview()||0)"
                         :key="'c'+b"/>
                    </div>
                    <div style="font-size: 20px;">
                      <span class="font-weight-bold mr-2">{{ getAverageReview() }}/5 Great</span> <span
                        class="text-muted"> {{ reviews.length }} reviews</span>
                    </div>

                    <div class="mt-3">
                      <h4>Ratings</h4>
                      <div class="row">
                        <div class="col-4">
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star text-warning"/>
                        </div>
                        <div class="col-6 pl-0">
                          <div class="progress w-100 mt-2">
                            <div class="progress-bar text-warning"
                                 :style="{'width':(countReviewStars(5)/reviews.length)*100+'%'}" role="progressbar"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-2 pl-0">{{ countReviewStars(5) | formatToK }}</div>

                      </div>
                      <div class="row">
                        <div class="col-4">
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star text-ash"/>
                        </div>
                        <div class="col-6 pl-0">
                          <div class="progress w-100 mt-2">
                            <div class="progress-bar bg-warning"
                                 :style="{'width':(countReviewStars(4)/reviews.length)*100 +'%'}" role="progressbar"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-2 pl-0">{{ countReviewStars(4) | formatToK }}</div>

                      </div>
                      <div class="row">
                        <div class="col-4">
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star text-ash"/>
                        </div>
                        <div class="col-6 pl-0">
                          <div class="progress w-100 mt-2">
                            <div class="progress-bar bg-warning"
                                 :style="{'width':(countReviewStars(3)/reviews.length)*100 +'%'}" role="progressbar"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-2 pl-0">{{ countReviewStars(3) | formatToK }}</div>

                      </div>
                      <div class="row">
                        <div class="col-4">
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star text-ash"/>
                        </div>
                        <div class="col-6 pl-0">
                          <div class="progress w-100 mt-2">
                            <div class="progress-bar bg-warning"
                                 :style="{'width':(countReviewStars(2)/reviews.length)*100 +'%'}" role="progressbar"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-2 pl-0">{{ countReviewStars(2) | formatToK }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <i class="fa fa-star mr-1 text-warning"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star mr-1 text-ash"/>
                          <i class="fa fa-star text-ash"/>
                        </div>
                        <div class="col-6 pl-0">
                          <div class="progress w-100 mt-2">
                            <div class="progress-bar bg-warning"
                                 :style="{'width':(countReviewStars(1)/reviews.length)*100 +'%'}" role="progressbar"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-2 pl-0">{{ countReviewStars(1) | formatToK }}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-7">
                <div class="review" v-for="(r,i) in reviews" :key="i">
                  <div class="d-flex">
                    <div class="name-abbr mr-3">
                      <span class="abbr text-uppercase">{{ r.user.name[0] }}{{ r.user.name[1] }}</span>
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 mb-0">{{ r.user.name }}</h5>
                      <div class="text-muted">{{ r.created_at | toFormattedDate }}</div>
                    </div>
                  </div>
                  <div class="mt-2 mb-2">
                    <i class="fa fa-star mr-1 text-warning" v-for="(v,a) in Number(r.rating_value)" :key="'r'+a"/>
                    <i class="fa fa-star mr-1 text-ash" v-for="(v,b) in 5-r.rating_value" :key="'b'+b"/>
                  </div>
                  <p>{{ r.comment }}</p>
                </div>
              </div>
            </div>
            <div class="row" v-if="reviews.length < 1">
              <div class="col-12">
                <h6>No reviews at the moment</h6>
              </div>
            </div>
          </div>

        </div>
        <div class="d-md-none d-sm-block" style="margin-top: 5rem"></div>
        <div class="row d-md-none d-sm-block bottomAction">
          <div class="col-md-12">
            <div class="card shadow">
              <div class="card-body">
                <div class="mb-2 text-center font-weight-600">
                  {{ (salon.services || []).length }}
                  <span v-if="(salon.services || []).length < 2">service</span>
                  <span v-else>services</span>
                </div>
                <router-link :to="'/booking/' + $route.params.slug" class="btn btn-1 btn-primary d-block text-light">
                  Book Now
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mobilefooter/>
  </div>
</template>


<script>
import Slick from "vue-slick";
import GallerySlider from "Components/globalFrontendComponents/GallerySlider";
import SidebarLayoutTwo from "Views/listing/SidebarLayoutTwo";
import Modal from "Components/globalFrontendComponents/Modal";
import {
  createNamespacedHelpers,
  mapGetters
} from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import $ from "jquery";
// const { mapMutations } = createNamespacedHelpers("review");
import {
  index
} from "@/api/resource";
import moment from "moment";
import {
  apisService
} from "@/api/apis.service";

export default {
  //   props: ["id"],
  name: "Saloon-Details",
  data() {
    return {
      lazy_load: {
        item_count: 0,
        threshold: 2,
      },
      seconds: 100,
      slides: [],
      isPhoneView: "",
      hideModal: "",
      isModalOpen: false,
      modal_category: '',
      modal_service: '',

      review: {
        reviewer: "",
        salon_id: "",
        review: ""
      },
      salon: {},
      reviews: [],
      symbol: process.env.VUE_APP_PRICE_SYMBOL,
      opening_days: [],
      isLoading: false,
      reviewLoader: false,
      fullPage: true,
      isOpeningDaysLoader: false,
      details: {},
      height: 128,
      width: 128,
      value: "",
      times: [],
      daySelected: "",
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 5565000,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        responsive: [{
          breakpoint: 1441,
          settings: {
            centerPadding: '10%',
            slidesToShow: 4,
          },
        },
          {
            breakpoint: 1025,
            settings: {
              centerPadding: '10px',
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              centerPadding: '10px',
              slidesToShow: 2,
            },
          },
        ],
      },
    };

  },

  components: {
    Modal,
    Slick,
    GallerySlider: GallerySlider,
    SidebarLayoutTwo: SidebarLayoutTwo,
    Loading: () => import("vue-loading-overlay"),
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },

  async mounted() {
    this.showsonPhone();
    window.addEventListener('resize', this.showsonPhone);
    this.getSaloonDetail(this.$route.params.slug);

    this.$loadScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.VUE_APP_GOOGLE_MAPS_KEY +
        "&libraries=places"
    )
        .then(() => {
          this.enableMap();
        })
        .catch(() => {
        });

  },

  methods: {
    goToShopBooking(){
      if (this.opening_days.length < 1) {
        alert("Shop has no booking hours..")
        return;
      }
      this.$router.push("/booking/" + this.salon.slug);
    },
    countReviewStars(star_number) {
      let review_number = this.reviews.filter((value) => Number(value.rating_value) === star_number);
      return review_number.length;
    },
    getAverageReview() {
      let sum = this.reviews.reduce((previous, current) => previous + Number(current.rating_value), 0)
      return sum / this.reviews.length;
    },

    showsonPhone() {
      const isPhoneMode = window.innerWidth <= 768;

      if (isPhoneMode === true) {
        this.isPhoneView = "ellipsis-1";
        this.hideModal = "";
      }
      if (isPhoneMode === false) {
        this.hideModal = "hideonphone";
        this.isPhoneView = "";
      }
    },

    openModal(category, service) {
      this.modal_category = category;
      this.modal_service = service;
      this.slides = service.images;
      console.log(this.modal_service)
      this.isModalOpen = true;

    },

    closeModal() {
      this.isModalOpen = false;
    },

    enableMap() {
      let location = {
        lat: parseFloat(this.salon.latitude),
        lng: parseFloat(this.salon.longitude)
      };
      let map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        // styles: [{
        //     "stylers": [{
        //         "hue": "#121212"
        //     }, {
        //         saturation: -100
        //     },
        //         {
        //             gamma: 3
        //         }
        //     ]
        // }],
        center: {
          lat: location.lat ? location.lat : 6.765809,
          lng: location.lng ? location.lng : -1.107311
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          style: google.maps.ZoomControlStyle.LARGE
        },
        scaleControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: "roadmap",
        fullscreenControl: false
      });

      let contentString =
          '<a class="text-dark" href="/salon/' +
          this.salon.slug +
          '"><img style="width: 210px; height: 120px; object-fit: cover;" src="' +
          this.salon.carousel +
          '" alt=""><div class="font-weight-500 p-1 mb-2">' +
          this.salon.salon_name +
          "</div><div>" +
          this.salon.street +
          "</div></a>";
      const infowindow = new google.maps.InfoWindow({
        content: contentString
      });
      let marker = new google.maps.Marker({
        position: {
          lat: location.lat ? location.lat : 6.765809,
          lng: location.lng ? location.lng : -1.107311
        },
        map: map,
        center: location
        // icon: '/static/images/shop.png',
      });
      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    },
    getShopOpeningHours() {
      this.isOpeningDaysLoader = true;
      apisService.getShopOpeningHours(this.salon.salon_id).then((response) => {
        const {
          success,
          data
        } = response;
        if (success) {
          this.opening_days = data['openingDays']
        }
      }).finally(() => {
        this.isOpeningDaysLoader = false;
      });

    },
    getSaloonDetail(slug) {
      this.isLoading = true;
      index("/api/salon/" + slug)
          .then(response => {
            const {
              data
            } = response;
            this.salon = data;

            if (this.salon) {
              this.getReviews();
              this.getShopOpeningHours();
            }
            this.salon.salon_category = this.salon.salon_category.filter(e => {
              if (e["services"].length > 0) {
                return e;
              }
            });
          })
          .catch(error => {
            this.$router.push("/404");
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    scrollToService(id) {
      let selector = $("#" + id);
      this.$nextTick(() => {
        $(".service").addClass("active show fade");
        $(".location").removeClass("active show fade");
        $(".service-link").removeClass("active");
        selector.addClass("active");

        $("#wrapper").animate({
              scrollTop: selector.offset().top + 200
            },
            1000
        );
      });
    },

    getReviews() {
      this.reviewLoader = true;
      apisService.getShopReviews(this.salon.salon_id).then((response) => {
        const {
          success,
          data
        } = response;
        if (success) {
          this.reviews = data
        }
      }).finally(() => {
        this.reviewLoader = false;
      });
    },


    async shareLink() {
      const urlToShare = window.location.href;
      if (navigator.share) {
        try {
          await navigator.share({
            url: urlToShare,
          });
          console.log('Shared successfully');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        console.warn('Web Share API not supported. You could provide a fallback option here.');
      }
    }
  },

  computed: {
    ...mapGetters({
      host: "host"
    }),

  }
};
</script>


<style scoped>
#map {
  height: 300px;
}

/*.flex-column {*/
/*  flex-direction: row !important;*/
/*}*/

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}

.content {
  padding-top: 4rem;
}

@media (max-width: 768px) {
  .content {
    padding-top: 15px;
  }
}

.timeline::before {
  border-radius: 0.25rem;
  background: #dee2e6;
  bottom: 0;
  content: "";
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  height: 20rem;
  width: 4px;
}

.side-link .nav-link {
  background: none !important;
  color: black;
  box-shadow: none;
}

.side-link .nav-link.active,
.nav-pills .show > .nav-link {
  color: #444;
  /* background-color: #7593DB; */
  border-radius: 0;
}

.side-link .nav-link.active {
  border-left: 4px solid #7593db;
  color: #7593db;
  font-size: 15px;
  z-index: 1;
}

.nav-pills:not(.nav-pills-icons) > li > a {
  border-radius: 0.25rem;
}

.nav-pills > li > a.active,
.nav-pills > li > a.active:focus,
.nav-pills > li > a.active:hover {
  color: #fff;
  background-color: #7593db;
}

.name-abbr {
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  border-radius: 50%;
  background-color: #cde4ff;
  color: #037aff;
  fill: #037aff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.abbr {
  display: flex;
  align-items: center;
  height: 100%;
  color: #037aff;
}

.review {
  margin-bottom: 2rem;
}

.text-warning {
  color: #ffc61c !important;
}

.bg-warning {
  background-color: #ffc61c !important;
}

.badge {
  position: absolute;
  top: 10px;
  /* Adjust the top position as needed */
  right: 10px;
  /* Adjust the right position as needed */
  background-color: rgb(239, 6, 6);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  z-index: 1;

}

@media only screen and (min-width: 1200px) {
  .img-container {
    width: 160px;
    height: 170px;
    overflow: hidden;
    position: relative;
  }

  .img-container img {
    display: block;
    max-height: none;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;

  }
}

@media screen and (max-width: 480px) {
  .img-container {
    width: 86%;
    height: 100px;
    overflow: auto;
    position: absolute;
  }

  .img-container img {
    width: 100%;
    /* Ensure the image fills the container width */
    height: auto;
    /* Maintain the image's aspect ratio */
    object-fit: cover;
    /* Maintain aspect ratio and cover the container */
  }

}

.closebutton {
  border-right-color: #7593db;
  width: 35%;
  margin-left: 65%;
  background-color: #7593db;
  border-radius: 5px;
  color: #fff;

}

.hideonphone {
  display: none;
}
</style>

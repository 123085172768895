import { update, index, store, destroy } from '@/api/resource'

const state = {
  users: [],
  sites: []
}

const mutations = {
  SET_USERS: (state, {users, sites}) => {
    // console.log()
    state.users = users
    state.sites = sites
  },
  UPDATE_USER: (state, user_data) => {
    Object.assign(state.users[state.users.findIndex(user => user.id === user_data.id)], user_data)
  },
  ADD_USER: (state, user) => {
    state.users.push(user)
    // state.users.users[state.users.users.findIndex(user => user.id === site_data.id)].site_id = site_data.id
  },
  DELETE_USER: (state, id) => {
    state.users.splice(state.users.findIndex(user => user.id === id), 1)

    // console.log(state.users['users'])
  }
}

const actions = {
  // get Asset Condition
  index({ commit }) {
    return new Promise((resolve, reject) => {
      index('/api/users').then(response => {
        const { data } = response
        commit('SET_USERS', data)
        console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  store({ commit }, user) {
    return new Promise((resolve, reject) => {
      store('/api/users', user).then(response => {
        const { data } = response
        commit('ADD_USER', data)
        console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  update({ commit }, user) {
    return new Promise((resolve, reject) => {
      update('/api/user/update', user.id, user).then(response => {
        const { data } = response
        commit('UPDATE_USER', data)
        console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  delete({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      destroy('/api/users', user_id).then(response => {

        commit('DELETE_USER', user_id)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

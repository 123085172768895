export const menus = [
  // {
  // 	state: '/home',
  // 	name: 'Home',
  // 	type: 'sub',
  // 	children: [
  // 		{state: '/home/version1', name: 'Search by Style', type: 'link'},
  // 		{state: '/home/version2', name: 'Search by Salon', type: 'link'}
  // 	]
  // },

  // {
  //   state: "/salons",
  //   name: "Salons",
  //   type: "link"
  // },
  // {
  //     state: "/session/sign-up",
  //     name: "Signup",
  //     type: "link"
  // },

  {
    state: "/sign-up-business",
    name: "Sign Up",
    query: "partner",
    type: "sub",
    children: [
      {
        state: "/sign-up",
        name: "User",
        type: "link"
      },
      {
        state: "/sign-up-business",
        name: "Partner",
        type: "link"
      }
    ]
  },
  // {
  //   state: "/sign-up",
  //   name: "Join As A User",
  //   query: "user",
  //   type: "link"
  // },
  // {
  //   state: "/give-away",
  //   name: "Give Away",
  //   type: "link"
  // },
  {
    state: "/login",
    name: "Login",
    type: "link"
  },
  // {
  //   state: "/blog",
  //   name: "Blogs",
  //   type: "link"
  // },

  {
    state: "/customer/dashboard",
    name: "Dashboard",
    type: "link",
    auth: "consumer"
  },
  {
    state: "/super-admin/home",
    adminState: "/super-admin/home",
    name: "Dashboard",
    type: "link",
    auth: "admin"
  },

  // {
  //   state: "/super-admin/waiting-list",
  //   adminState: "/super-admin/waiting-list",
  //   name: "Waiting List",
  //   type: "link",
  //   auth: "admin"
  // },
  {
    state: "/admin/dashboard",
    adminState: "/admin/dashboard",
    name: "Dashboard",
    type: "link",
    auth: "provider"
  },
  {
    state: "/logout",
    name: "Log Out",
    type: "link"
  }

  // {
  //     state: '/home',
  //     name: 'Search',
  //     type: 'sub',
  //     children: [{
  //             state: '/listing/list/with-sidebar',
  //             name: 'Search By Style',
  //             type: 'link'
  //         },
  //         {
  //             state: '/listing/grid/full-width-map',
  //             name: 'Search By Salon',
  //             type: 'link'
  //         },
  //     ]
  // },
  // {
  //     state: '/list',
  //     name: 'List Layout',
  //     type: 'sub',
  //     children: [{
  //             state: '/listing/list/with-sidebar',
  //             name: 'With Sidebar',
  //             type: 'link'
  //         },
  //         {
  //             state: '/listing/list/full-width',
  //             name: 'Full Width',
  //             type: 'link'
  //         },
  //         {
  //             state: '/listing/list/full-width-map',
  //             name: 'Full Width + Map',
  //             type: 'link'
  //         }
  //     ]
  // },
  // {
  //     state: '/grid',
  //     name: 'Grid Layout',
  //     type: 'sub',
  //     children: [
  //         {state: '/listing/grid/with-sidebar', name: 'With Sidebar', type: 'link'},
  //         {state: '/listing/grid/full-width', name: 'Full Width', type: 'link'},
  //         {state: '/listing/grid/full-width-map', name: 'Full Width + Map', type: 'link'}
  //     ]
  // },
  // {
  //     state: '/half-map',
  //     name: 'Half Screen Map',
  //     type: 'sub',
  //     children: [
  //         {state: '/listing/half-map/list', name: 'Layout 1', type: 'link'},
  //         {state: '/listing/half-map/grid', name: 'Layout 2', type: 'link'}
  //     ]
  // },
  // {
  //     state: '/detail',
  //     name: 'Detail',
  //     type: 'sub',
  //     children: [{
  //             state: '/listing/detail/version1/1',
  //             name: 'Version1',
  //             type: 'link'
  //         },
  //         {
  //             state: '/listing/detail/version2',
  //             name: 'Version2',
  //             type: 'link'
  //         }
  //     ]
  // },
  // ]

  // {
  //     state: '/admin',
  //     name: 'Salon Panel',
  //     type: 'sub',
  //     children: [{
  //             state: '/admin/dashboard',
  //             name: 'Dashboard',
  //             type: 'link'
  //         },
  //         // {state: '/admin/messages', name: 'Messages', type: 'link'},
  //         {
  //             state: '/admin/bookings',
  //             name: 'Bookings',
  //             type: 'link'
  //         },
  //         // {state: '/admin/reviews', name: 'Reviews', type: 'link'},
  //         {
  //             state: '/admin/bookmarks',
  //             name: 'Favourites',
  //             type: 'link'
  //         },
  //         {
  //             state: '/admin/list',
  //             name: 'My Posts',
  //             type: 'link'
  //         },
  //         // {state: '/admin/add-list', name: 'Add List', type: 'link'},
  //         {
  //             state: '/admin/profile',
  //             name: 'Edit Salon Profile',
  //             type: 'link'
  //         }
  //     ]
  // },

  // {
  //   state: "/session",
  //   name: "Session",
  //   type: "sub",
  //   children: [
  //     {
  //       state: "/session/login",
  //       name: "Login",
  //       type: "link"
  //     },
  //     {
  //       state: "/session/sign-up",
  //       name: "Signup",
  //       type: "link"
  //     },
  //
  //     {
  //       state: "/session/forgot-password",
  //       name: "Forgot Password",
  //       type: "link"
  //     }
  //     // {
  //     //     state: '/session/coming-soon',
  //     //     name: 'Coming Soon',
  //     //     type: 'link'
  //     // }
  //   ]
  // },
  // {
  //   state: "/session",
  //   name: "Profile",
  //   type: "sub",
  //   children: [
  //     {
  //       state: "/admin/dashboard",
  //       name: "Dashboard",
  //       type: "link"
  //     },
  //     {
  //       state: "/session/logout",
  //       name: "LogOut",
  //       type: "link"
  //     }
  //   ]
  // }
];

// export default menus;

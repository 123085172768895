<template>
  <div class="dashboard-content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    />
    <div class="row">
      <div class="col-lg-12">
        <form id="add-listing" @submit.prevent="updateService">
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>Basic Information</h3>
            </div>
            <!-- Title -->
            <div class="row with-forms">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    required
                    type="text"
                    placeholder="Name"
                    class="form-control form-control-alternative"
                    v-model="(service || {}).service_name"
                  />
                </div>
              </div>
            </div>
            <!-- Row -->
            <div class="row with-forms">
              <!-- Status -->
              <div class="col-md-6">
                <select
                  required
                  class="custom-select form-control-alternative mb-3"
                  v-model="(service || {}).service_category_id"
                >
                  <option
                    v-for="item in salonDetails['salon_category']"
                    :key="item.service_category_id"
                    :label="item.service_category_name"
                    :value="item.service_category_id"
                    >{{ item.service_category_name }}</option
                  >
                </select>
              </div>
              <!-- Type -->
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Keywords"
                    class="form-control form-control-alternative"
                  />
                </div>
              </div>
            </div>
            <!-- Row / End -->
            <div class="row with-forms">
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Price"
                    class="form-control form-control-alternative"
                    v-model="(service || {}).service_price"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <select
                  class="custom-select form-control-alternative mb-3"
                  v-model="(service || {}).service_time_taken"
                  placeholder="Category"
                >
                  <option label="Estimated Time Taken">Select Time</option>
                  <option value="15">15 minutes</option>
                  <option value="30">30 minutes</option>
                  <option value="45">45 minutes</option>
                  <option value="60">1 hour</option>
                  <option value="75">1.5 hours</option>
                  <option value="120">2 hours</option>
                  <option value="150">2.5 hours</option>
                  <option value="180">3 hours</option>
                  <option value="210">3.5 hours</option>
                  <option value="240">4 hours</option>
                  <option value="270">4.5 hours</option>
                  <option value="300">5 hours</option>
                  <option value="390">6.5 hours</option>
                  <option value="420">7 hours</option>
                  <option value="450">7.5 hours</option>
                  <option value="480">8 hours</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Section / End -->
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <!-- Dropzone -->
            <dropzone
              aria-required="true"
              id="myVueDropzone"
              ref="myVueDropzone"
              class="mb-2"
              :options="dropzoneOptions"
              @vdropzone-success="upload_avatar"
              @vdropzone-file-added="addingFiles"
              @vdropzone-error="errorFile"
              @vdropzone-removed-file="removedFile"
            >
            </dropzone>
          </div>
          <!-- Section / End -->
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>Details</h3>
            </div>
            <div class="form">
              <textarea
                class="form-control form-control-alternative"
                placeholder="Description"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="(service || {}).service_description"
              />
            </div>
            <div class="text-center mb-5">
              <button
                class="btn btn-success"
                type="submit"
                :disabled="disableButton"
              >
                Save <i class="fa fa-arrow-circle-right" />
              </button>
            </div>
          </div>
          <!-- Section / End -->
        </form>
      </div>
      <mobilefooter />
    </div>
  </div>
</template>

<script>
import Dropzone from "vue2-dropzone";
import { mapGetters } from "vuex";

import { update, index } from "@/api/resource";
import Swal from "sweetalert2";

export default {
  props: ["id"],
  components: {
    Dropzone,
    Loading: () => import("vue-loading-overlay"),
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 10,
        headers: { "My-Awesome-Header": "header value" },
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,",
        addRemoveLinks: true
      },
      disableButton: false,
      service: {
        images: [],
        rating: "",
        salon_id: "",
        service_category: "",
        service_category_id: "",
        service_description: "",
        service_id: "",
        service_name: "",
        service_price: "",
        service_time_taken: "",
        status: "",
        updated_at: ""
      },
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      salon_name: this.$route.params.salon_name,
      salon_id: this.$route.params.id,
      service_id: this.$route.params.id,
      salonDetails: {}
    };
  },
  computed: {
  
  },
  created() {
    if (this.salon_name && this.service_id) {
      this.getSaloonDetail().then(() => {
        this.getServiceDetails();
      });
    } else {
      this.$router.go(-1);
    }
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch("serviceCategory/index").then(() => {});
    },
    getSaloonDetail() {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        index("/api/get-salon-by-id/" + this.salon_name)
          .then(response => {
            const { data } = response;
            this.salonDetails = data;
            resolve("");
          })
          .catch(error => {
            console.log(error);
            reject("err");
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    getServiceDetails() {
      this.isLoading = true;
      index("/api/find-service/" + this.service_id)
        .then(res => {
          this.service = res["data"];
          if (this.service) {
            this.service.images.forEach((e, i) => {
              let file = { size: "", name: "image " + i, type: "image/png" };
              this.$refs.myVueDropzone.manuallyAddFile(file, e["image"]);
            });
            this.disableButton = false;
          }

          if (!this.service) {
            Swal.fire({
              title: "Error!",
              text: "Sorry the service does not exist",
              icon: "error",
              confirmButtonText: "close"
            }).then(res => {
              if (res) {
                this.$router.push("/admin/settings");
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    upload_avatar(file) {
      this.disableButton = false;
      if (!file["manuallyAdded"]) {
        console.log(file);
        this.service["images"].push(file);
      }
    },

    errorFile(file, message) {
      this.$refs.myVueDropzone.removeFile(file);
      this.disableButton = false;
      alert(message);
    },

    removedFile(file) {
      this.service["images"].splice(this.service["images"].indexOf(file), 1);
      console.log(this.service, file);
    },

    addingFiles() {
      this.disableButton = true;
    },

    updateService() {
      this.isLoading = true;
      this.$store
        .dispatch("service/update", this.service)
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Service has been updated successfully",
            icon: "success",
            confirmButtonText: "close"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.dropzone .dz-preview .dz-details .dz-size {
  display: none !important;
}
</style>

<template>
  <div class="custom-file">
    <input
      type="file"
      class="custom-file-input"
      :id="name"
      :multiple="multiple"
      accept="image/*"
      @change="upload_avatar"
    />
    <label class="custom-file-label" :for="name">Upload {{ name }}</label>
  </div>
</template>

<script>
export default {
  name: "FileUpload",

  props: {
    name: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      file: []
    };
  },

  methods: {
    upload_avatar(e) {
      let files = e.target.files;
      let reader = new FileReader();
      files.forEach(file => {
        reader.onloadend = file => {
          this.$emit("change", reader.result);
          this.file.push(reader.result);
        };
        reader.readAsDataURL(file);
      });
    }
  }
};
</script>

import { update, index, store, destroy } from "@/api/resource";

const state = {
  appointments: []
};

const mutations = {
  UPDATE_APPOINTMENT: (state, appointment_data) => {
    Object.assign(
      state.appointments[appointment_data.appointment_id],
      appointment_data
    );
  },
};

const actions = {
  // get Asset Condition
  index({ rootGetters }) {
    return new Promise((resolve, reject) => {
      let url = "/api/appointments";
      if (rootGetters.roles.includes("ADMIN")) {
        url =
          "/api/appointments?user_id=" + rootGetters.salon_user.user_id;
      }
      index(url)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store({ commit }, appointment) {
    return new Promise((resolve, reject) => {
      store("/api/appointment/store", appointment)
        .then(response => {
          const { data } = response;
          commit("ADD_APPOINTMENT", data);
          console.log(data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // update({ commit }, appointment) {
  //   return new Promise((resolve, reject) => {
  //     update("/api/appointment/update", appointment.appointment_id, appointment)
  //       .then(response => {
  //         const { data } = response;
  //         // data.service_id = data.id
  //         console.log(data);
  //         commit("UPDATE_APPOINTMENT", data);
  //         resolve(data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  delete({ commit }, appointment_id) {
    return new Promise((resolve, reject) => {
      destroy("/api/appointments", appointment_id)
        .then(response => {
          commit("DELETE_APPOINTMENT", appointment_id);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

import { render, staticRenderFns } from "./SuperAdminHeader.vue?vue&type=template&id=43298860&scoped=true"
import script from "./SuperAdminHeader.vue?vue&type=script&lang=js"
export * from "./SuperAdminHeader.vue?vue&type=script&lang=js"
import style0 from "./SuperAdminHeader.vue?vue&type=style&index=0&id=43298860&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43298860",
  null
  
)

export default component.exports
import request from '@/utils/request'

export function login(data) {
    return request({
      url: '/api/login',
      method: 'post',
      data
    })
  // })
}

export function register(data) {
    return request({
        url: '/api/register',
        method: 'post',
        data
    })
    // })
}
export function update(data) {
    return request({
        url: '/api/update_user_profile',
        method: 'post',
        data
    })
    // })
}

export function getInfo(token) {
  return request({
    url: '/api/user',
    method: 'get',
    params: { }
  })
}

export function logout() {
  return request({
    url: '/api/logout',
    method: 'get'
  })
}

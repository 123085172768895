import Vue from "vue";
// import * as VueGoogleMaps from 'vue2-google-maps'
import App from "./App.vue";
// import Element from "element-ui";
// import elementLocale from "element-ui/lib/locale/lang/en";
import FullCalendar from "vue-full-calendar";



import "bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import './utils/filters'

import LoadScript from "vue-plugin-load-script";

Vue.use(FullCalendar);
Vue.use(LoadScript);

// router
import router from "./router";

// store
import store from "./store";

import "./permission"; // permission control
import "./utils/error-log"; // error log

// include script file
import "./lib/CustomScript";

// include all css files
import "./lib/CustomCss";

// include real time files
import Echo from "laravel-echo";
import Larasocket from "larasocket-js";

import "beautify-scrollbar/dist/index.css";
import V2LazyList from "v2-lazy-list";
import "./registerServiceWorker";
import BootstrapVue from 'bootstrap-vue'


// window.$ = require('jquery')
// window.JQuery = require('jquery');

// Vue.use(Element, {
//   locale: elementLocale,
//   size: "medium" // set element-ui default size
// });

Vue.use(V2LazyList, {});

// Vue.use(VueGoogleMaps, {
//    load: {
//       key: process.env.VUE_APP_GOOGLE_MAPS_KEY, // Add your here your google map api key
//       libraries: 'places',
//    }
// })

window.Echo = new Echo({
  broadcaster: Larasocket,
  token:
    "242|tQkuL7OhI8j8JdmksprXT5BTVStpif7JQWJNIlHcBwSZbxrY4OcylGmFivfxNcwyoGyjCpjUaGn4cmcC"
});

Vue.config.productionTip = false;
Vue.use(BootstrapVue)


new Vue({
  router,
  store,
  render: h => h(App),
  components: { App }
}).$mount("#app");

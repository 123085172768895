<template>
    <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
            <div class="dashboard-list-box-static">
                <h4 class="mb-3">Profile Details</h4>
                <div class="my-profile">
                    <div>
                        <div class="custom-file">
                            <FileUpload @change="(file) =>  { return profile.user_image = [], profile.user_image.push(file) }" name="user_image"></FileUpload>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="form-group">
                            <input type="text" placeholder="Full Name" class="form-control form-control-alternative" v-model="profile.name">
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <input type="text" placeholder="Phone" v-model="profile.phone_number" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <input type="text" placeholder="Location" v-model="profile.location" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="profile.email" placeholder="E-mail" class="form-control form-control-alternative" readonly>
                    </div>
                </div>
                <button @click="update" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import FileUpload from "../inputs/FileUpload";

    export default {
        name: "Consumer",

        components: { FileUpload },

        methods : {
            update () {
                this.$store.dispatch('user/update', this.profile).then(() => {
                    console.log('success after update')
                }).catch((error) => {
                    console.log(error)
                })
            },
        },
        computed : {
            ...mapGetters({
                profile: 'user_data'
            })
        }
    }
</script>

<style scoped>

</style>
<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <!--				<ul data-submenu-title="Main">-->
          <ul data-submenu-title="Administration">
            <li class="active">
              <router-link to="/super-admin/home"
              ><i class="fa fa-tachometer"/> Shops
              </router-link
              >
            </li>
            <li>
              <router-link to="/all-bookings"
              ><i class="fa fa-calendar"/>Bookings
              </router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Blogs">
            <li>
              <router-link to="/super-admin/all-blogs"
              ><i class="fa fa-archive"/>Blogs
              </router-link
              >
            </li>
            <li>
              <router-link to="/super-admin/blog"
              ><i class="fa fa-edit"/>Create Blog
              </router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Give aways">
            <li>
              <router-link to="/give-away-users"
              ><i class="fa fa-certificate"/>GiveAway Users
              </router-link
              >
            </li>
            <li>
              <router-link to="/super-admin/waiting-list"
              ><i class="fa fa-dashcube"/>Waiting List
              </router-link
              >
            </li>
            <li>
              <router-link to="/super-admin/shop-ratings"
              ><i class="fa fa-dashcube"/>Shop Ratings
              </router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Account">
            <!--            <li>-->
            <!--              <router-link to="/settings"-->
            <!--              ><i class="fa fa-user"/> Settings-->
            <!--              </router-link-->
            <!--              >-->
            <!--            </li>-->
            <li>
              <router-link to="/"
              ><i class="fa fa-link"/> Visit Site
              </router-link
              >
            </li>
            <li @click.prevent="logout">
              <a style="color: #3F295A;"
              ><i class="fa fa-sign-out"/> Logout
              </a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <i class="fa fa-reorder"/>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
          .dispatch("user/logout")
          .then(() => {
            this.$router.push({path: "/"});
          })
          .catch(error => {
            console.log(error);
          });
    },

    toggleMenu() {
      if ($("#wrapper").hasClass("sidebar-in")) {
        $("#wrapper").removeClass("sidebar-in");
      } else {
        $("#wrapper").addClass("sidebar-in");
      }
    }
  }
};
</script>

<style scoped>
a {
  font-size: 15px !important;
}

/*.dashboard-nav ul li a {*/
/*  padding: 20px 27px;*/
/*}*/

/*i {*/
/*  margin-right: 10px !important;*/
/*}*/
</style>

<template>
  <div class="dashboard-content mt-4 mb-5">
    <div class="container-fluid">

      <!-- Notice -->
      <!-- Content -->
      <div class="row">
        <!-- Item -->
        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Manage Booking Information</h5>
                  <p>
                    Edit your booking times, schedules, advance bookings and
                    more
                  </p>
                  <router-link
                    to="/admin/booking-settings"
                    class="btn btn-primary btn-sm"
                    >Manage Booking Information</router-link
                  >
                </div>
                <img
                  src="@/assets/images/calendar.png"
                  class="
                    align-self-center
                    mr-3
                    img-fluid
                    d-none d-md-block d-lg-block
                  "
                  alt="..."
                  style="width: 60px"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Item -->
        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Manage Services</h5>
                  <p>
                    Add more services, edit your current services, update your
                    price list and more
                  </p>
                  <router-link
                    to="/admin/services"
                    class="btn btn-primary btn-sm"
                    >Manage services</router-link
                  >
                </div>
                <img
                  src="@/assets/images/services.png"
                  class="
                    align-self-center
                    mr-3
                    img-fluid
                    d-none d-md-block d-lg-block
                  "
                  alt="..."
                  style="width: 60px"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->

        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Manage Your Business</h5>
                  <p>
                    Edit your business name, location, facebook, instagram
                    information and more
                  </p>
                  <router-link
                    to="/admin/profile-settings"
                    class="btn btn-primary btn-sm"
                  >
                    Manage your business
                  </router-link>
                </div>
                <img
                  src="@/assets/salon.png"
                  class="
                    align-self-center
                    mr-3
                    img-fluid
                    d-none d-md-block d-lg-block
                  "
                  alt="..."
                  style="width: 60px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Manage Personal Profile</h5>
                  <p>
                    Edit your personal name, phone number, user image,
                    information and more
                  </p>
                  <router-link
                    to="/admin/personal-profile"
                    class="btn btn-primary btn-sm"
                  >
                    Manage personal profile
                  </router-link>
                </div>
                <img
                  src="@/assets/images/avatar.png"
                  class="
                    align-self-center
                    mr-3
                    img-fluid
                    d-none d-md-block d-lg-block
                  "
                  alt="..."
                  style="width: 60px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Payment Settings</h5>
                  <p>
                    Edit your payment option, either fixed or percentage deposit
                    and more
                  </p>
                  <router-link
                    to="/admin/payment-settings"
                    class="btn btn-primary btn-sm"
                  >
                    Manage Payment Settings
                  </router-link>
                </div>
                <img
                  src="@/assets/images/cash.png"
                  class="
                    align-self-center
                    mr-3
                    img-fluid
                    d-none d-md-block d-lg-block
                  "
                  alt="..."
                  style="width: 60px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <mobilefooter />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      salonInfo: this.$store.state.user.salon,
    };
  },

  components: {
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
  },

  methods: {
    goBack() {
      window.history.back();
    }
  }
};
</script>

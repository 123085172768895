import { update, index, store, destroy } from '@/api/resource'

const state = {
  reviews: [],
}

const mutations = {
  SET_REVIEWS: (state, review) => {
    state.reviews = review
  },
  UPDATE_REVIEW: (state, review_data) => {
    if( state.reviews.length !== 0 ) {
      Object.assign(state.reviews[state.reviews.findIndex(review => review.review_id === review_data.review_id)], review_data)
    } else {
      state.reviews.push(review_data)
    }
  },
  ADD_REVIEW: (state, review_data) => {
    state.reviews.push(review_data)
  },
  DELETE_REVIEW: (state, id) => {
    state.reviews.splice(state.reviews.findIndex(review => review.review_id === id), 1)
  }
}

const actions = {
  index({ commit }, salon) {
    return new Promise((resolve, reject) => {
      index('/api/reviews/'+salon).then(response => {
        const { data } = response
        commit('SET_REVIEWS', data)
        console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  store({ commit }, review) {
    return new Promise((resolve, reject) => {
      store('/api/reviews', review).then(response => {
        const { data } = response
        commit('ADD_REVIEW', data)
        console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  update({ commit }, review) {
    return new Promise((resolve, reject) => {
      update('/api/review/update', review.review_id, review).then(response => {
        const { data } = response
        // data.review_id = data.id
        console.log(data)
        commit('UPDATE_REVIEW', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  delete({ commit }, review_id) {
    return new Promise((resolve, reject) => {
      destroy('/api/reviews', review_id).then(response => {
        commit('DELETE_REVIEW', review_id)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
})

instance.interceptors.response.use(function (response) {
   // console.log('interceptor response', response)
    return response;
}, function (error) {

    if(error.response){
        if(error.response.status === 401){
            localStorage.clear();
            window.location.href="login";
        }
    }
    return Promise.reject(error);

});

export default instance;

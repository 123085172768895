import { update, index, store, destroy } from "@/api/resource";

const state = {
  services: []
};

const mutations = {
  SET_SERVICES: (state, services) => {
    state.services = services;
  },
  UPDATE_SERVICE: (state, service_data) => {
    // console.log(service_data);
    // Object.assign(state.services[service_data.service_id], service_data)
  },
  ADD_SERVICE: (state, service_data) => {
    state.services.push(service_data);
  },
  DELETE_SERVICE: (state, id) => {
    state.services.splice(state.services[id], 1);
  }
};

const actions = {
  // get Asset Condition
  index({ commit, rootGetters }, salon) {
    return new Promise((resolve, reject) => {
      if (!rootGetters.salon_user) {
        // reject("no salon");
        resolve([]);
      }
      const salon_id = rootGetters.roles.includes("PROVIDER")
        ? rootGetters.salon_user.salon.salon_id
        : salon;
      index("/api/services/" + this.$store.state.user.salon,)
        .then(response => {
          const { data } = response;
          commit("SET_SERVICES", data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store({ commit }, service) {
    return new Promise((resolve, reject) => {
      store("/api/services", service)
        .then(response => {
          const { data } = response;
          commit("ADD_SERVICE", data);
          // console.log(data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update({ commit }, service) {
    return new Promise((resolve, reject) => {
      update("/api/service/update", service.service_id, service)
        .then(response => {
          const { data } = response;
          commit("UPDATE_SERVICE", data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  delete({ commit }, service_id) {
    return new Promise((resolve, reject) => {
      destroy("/api/services", service_id)
        .then(response => {
          commit("DELETE_SERVICE", service_id);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

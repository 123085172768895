import { update, index, store, destroy } from '@/api/resource'

const state = {
  serviceCategories: []
}
// {
//     asset_name: '',
//     asset_quantity: 0,
//     service_id: 0,
//     site_id: 0,
//     asset_id: 0,
//     created_at: '',
//     updated_at: '',
//     deleted_at: ''
//   }

const mutations = {
  SET_SERVICE_CATEGORIES: (state, serviceCategories) => {
    state.serviceCategories = serviceCategories
  },
  UPDATE_SERVICE_CATEGORY: (state, serviceCategoryData) => {
    Object.assign(state.serviceCategories[state.serviceCategories.findIndex(serviceCategory => serviceCategory.service_id === serviceCategoryData.service_id)], serviceCategoryData)
  },
  ADD_SERVICE_CATEGORY: (state, serviceCategoryData) => {
    state.serviceCategories.push(serviceCategoryData)
    state.serviceCategories[state.serviceCategories.findIndex(serviceCategory => serviceCategory.id === serviceCategoryData.id)].service_id = serviceCategoryData.id
  },
  DELETE_SERVICE_CATEGORY: (state, id) => {
    state.serviceCategories.splice(state.serviceCategories.findIndex(serviceCategory => serviceCategory.service_id === id), 1)
  }
}

const actions = {
  // get Asset Condition
  index({ commit }) {
    return new Promise((resolve, reject) => {
      index('/api/serviceCategories').then(response => {
        const { data } = response
        commit('SET_SERVICE_CATEGORIES', data)
        // console.log(data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  store({ commit }, serviceCategory) {
    return new Promise((resolve, reject) => {
      store('/api/serviceCategories', serviceCategory).then(response => {
        const { data } = response
        commit('ADD_SERVICE_CATEGORY', data)
        // console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  update({ commit }, serviceCategory) {
    return new Promise((resolve, reject) => {
      update('/api/service_category/update', serviceCategory.service_id, serviceCategory).then(response => {
        const { data } = response
        commit('UPDATE_SERVICE_CATEGORY', data)
        // console.log(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  delete({ commit }, service_category_id) {
    return new Promise((resolve, reject) => {
      destroy('/api/serviceCategories', service_category_id).then(response => {
        commit('DELETE_SERVICE_CATEGORY', service_category_id)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import {login, getInfo, register} from "@/api/user";
import {
    getToken,
    setToken,
    removeToken,
    setUser,
    setSalon,
    getSalon,
    removeSalon,
    getUser,
    removeUser
} from "@/utils/auth";
import router, {resetRouter} from "@/router";
import {update, store} from "@/api/resource";

const state = {
    token: getToken() || null,
    roles: getUser() ? [getUser()["role"]] : [],
    data: getUser() || null,
    user: getUser() || null,
    salon: getSalon() || null
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },

    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_DATA: (state, data) => {
        state.user = data;
    },
    SET_SALON: (state, salon) => {
        state.salon = salon;
    }
};

const actions = {
    // user login
    login({commit, dispatch}, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo)
                .then(response => {
                    const {success, data} = response;
                    if (!success) {
                        resolve(response);
                        return;
                    }
                    const {access_token, user, salon} = data;
                    setToken(access_token);
                    commit("SET_TOKEN", access_token);

                    if(user){
                        setUser(user);
                        commit("SET_DATA", user);

                        commit("SET_ROLES", [user.role]);
                    }

                    if(salon){
                        setSalon(salon);
                        commit("SET_SALON", salon);
                    }


                    // }
                    resolve(response);
                })
                .catch(err => {
                    resolve({success: false, message: err})
                });
        });
    },

    saveSalonDetails({commit}, setUpInfo) {

        return new Promise((resolve, reject) => {
            store("/api/save-salon-details", setUpInfo)
                .then(response => {
                    const {data} = response;
                    setSalon(data);
                    commit("SET_SALON", data);
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    falseLoginToSalon({commit}, salonInfo) {
        return new Promise((resolve,reject) => {
            if (salonInfo){
                setSalon(salonInfo);
                commit("SET_SALON", salonInfo);
                resolve("done");
            }else{
                reject('Salon details cannot be found')
            }


        });
    },

    register({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            register(userInfo)
                .then(response => {
                    const {success, data} = response;
                    if (success) {
                        const {access_token, user, salon} = data;
                        setToken(access_token);
                        commit("SET_TOKEN", access_token);
                        commit("SET_ROLES", [user.role]);

                        if (user) {
                            setUser(user);
                            commit("SET_DATA", user);
                        }
                        if (salon) {
                            setSalon(salon);
                            commit("SET_SALON", salon);
                        }
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // get user info
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            getInfo(state.token)
                .then(response => {
                    const data = response.data;
                    const {user_info, salon} = data;
                    if (!data) {
                        reject("Verification failed, please Login again.");
                    }

                    const {role} = user_info;

                    commit("SET_DATA", user_info);
                    commit("SET_SALON", salon);

                    commit("SET_ROLES", [role]);

                    resolve(user_info);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {

            commit("SET_DATA", null);

            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            removeToken();
            removeUser();
            removeSalon();
            resetRouter();
            resolve();
        });
    },

    update(context, user) {
        return new Promise((resolve, reject) => {
            update("/api/update_user_profile", user.id, user)
                .then(response => {
                    const {data} = response;
                    setSalon(data);
                    context.commit("SET_SALON", data);
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    resetPassword({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            store("/api/reset-password", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },


    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            console.log("remove token");
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            removeToken();
            resolve();
        });
    },

    // dynamically modify permissions
    changeRoles({commit, dispatch}, role) {
        return new Promise(async resolve => {
            const token = role + "-token";

            commit("SET_TOKEN", token);
            setToken(token);

            const {roles} = await dispatch("getInfo");

            resetRouter();

            // generate accessible routes map based on roles
            const accessRoutes = await dispatch("permission/generateRoutes", roles, {
                root: true
            });

            // dynamically add accessible routes
            router.addRoutes(accessRoutes);

            // reset visited views and cached views
            dispatch("tagsView/delAllViews", null, {root: true});

            resolve();
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};

<template>
  <div class="dashboard-content">
    <div class="container-fluid">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      />
      <div class="text-primary mb-3 cursor-pointer" @click="$router.go(-1)">
        <i class="fa fa-arrow-left"></i> Back
      </div>
      <div class="row">
        <div class="col-lg-12">
          <form id="add-listing" @submit.prevent="updateService">
            <!-- Section -->
            <div class="add-listing-section mb-4">
              <!-- Headline -->
              <div class="add-listing-headline">
                <h3>Basic Information</h3>
              </div>
              <!-- Title -->
              <div class="row with-forms">
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      required
                      type="text"
                      placeholder="Name"
                      class="form-control form-control-alternative"
                      v-model="(service || {}).service_name"
                    />
                  </div>
                </div>
              </div>
              <!-- Row -->
              <div class="row with-forms">
                <!-- Status -->
                <div class="col-md-6">
                  <select
                    required
                    class="custom-select form-control-alternative mb-3"
                    v-model="(service || {}).service_category_id"
                  >
                    <option
                      v-for="item in salon_details['salon_category']"
                      :key="item.service_category_id"
                      :label="item.service_category_name"
                      :value="item.service_category_id"
                    >
                      {{ item.service_category_name }}
                    </option>
                  </select>
                </div>
                <!-- Type -->
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Keywords"
                      class="form-control form-control-alternative"
                    />
                  </div>
                </div>
              </div>
              <!-- Row / End -->
              <div class="row with-forms">
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Price"
                      class="form-control form-control-alternative"
                      v-model="(service || {}).service_price"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <select
                    class="custom-select form-control-alternative mb-3"
                    v-model="(service || {}).service_time_taken"
                    placeholder="Category"
                  >
                    <option label="Estimated Time Taken">Select Time</option>
                    <option value="15">15 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="45">45 minutes</option>
                    <option value="60">1 hour</option>
                    <option value="75">1.5 hours</option>
                    <option value="120">2 hours</option>
                    <option value="150">2.5 hours</option>
                    <option value="180">3 hours</option>
                    <option value="210">3.5 hours</option>
                    <option value="240">4 hours</option>
                    <option value="270">4.5 hours</option>
                    <option value="300">5 hours</option>
                    <option value="390">6.5 hours</option>
                    <option value="420">7 hours</option>
                    <option value="450">7.5 hours</option>
                    <option value="480">8 hours</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
              <label>Service Images</label>
              <div class="d-flex">
                <div class="roll-element" v-for="(s,i) in (service || {}).images" :key="i">
                  <div>
                    <img :src="s.image || s.dataURL" alt="" class="img-fluid img-thumbnail result-img">
                    <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number" @click="removeCroppedImage(i)"><i class="fa fa-close"></i></span>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="roll-element">
                  <div @click="$refs.file.click()">
                    <input type="file" accept="image/*" ref="file" class="d-none" @change="selectedFile"/>
                    <button type="button" class="cg-btn-upload" style="height:71px;"><i class="fa fa-image"></i>
                    </button>
                    <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number"><i class="fa fa-plus-circle" style="color: #adb5bdcc;"/></span>
                        </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
              <!-- Headline -->
              <div class="add-listing-headline">
                <h3>Service Description</h3>
              </div>
              <div class="form">
                <textarea
                  required
                  class="form-control form-control-alternative"
                  placeholder="Description"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="(service || {}).service_description"
                />
              </div>
              <div class="text-center mb-5">
                <button
                  class="btn btn-success"
                  type="submit"
                  :disabled="disableButton"
                >
                  Save <i class="fa fa-arrow-circle-right" />
                </button>
              </div>
            </div>
            <!-- Section / End -->
          </form>
        </div>
<!--        Modal-->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Service Images</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="img-container">
                  <img id="cropper" :src="displayImage" class="img-fluid" style="max-height:350px"/>
                </div>
                <div class="mt-3 mb-3 text-center">
                  <button type="button" class="btn btn-info btn-sm" @click="cropImage()">Crop Image</button>
                </div>
                <hr class="mt-0 mb-3"/>
                <div class="d-flex">
                  <div class="roll-element" v-for="(r,i) in results" :key="i">
                    <div>
                      <img :src="r" @click="recreateImage(r)" alt="" class="img-fluid result-img">
                      <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number" @click="removeCroppedImage(i)"><i class="fa fa-close"></i></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="roll-element">
                    <div @click="$refs.file.click()">
                      <input type="file" accept="image/*" ref="file" class="d-none" @change="selectedFile"/>
                      <button class="cg-btn-upload"><i class="fa fa-image"></i></button>
                      <div class="roll-element-order">
                        <span class="reo-wrapper">
                          <span class="reo-number"><i class="fa fa-plus-circle" style="color: #adb5bdcc;"/></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mobilefooter />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

import { update, index } from "@/api/resource";
import Swal from "sweetalert2";

export default {
  name:"EditSalonService",
  props: ["id"],
  components: {
    Loading: () => import("vue-loading-overlay"),
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
  },
  data() {
    return {
      results: [],
      cropper: null,
      displayImage: '/img/picture.jpg',
      fileDetails: {
        mimeType: 'image/jpg',
        name: ''
      },

      salon_details: this.$store.state.user.salon,
      disableButton: false,
      service: {
        images: [],
        rating: "",
        salon_id: "",
        service_category: "",
        service_category_id: "",
        service_description: "",
        service_id: "",
        service_name: "",
        service_price: "",
        service_time_taken: "",
        status: "",
        updated_at: "",
      },
      salonDetails: {},
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
    };
  },
  computed: {
    ...mapGetters({
      service_select: "service",
      serviceCategories: "serviceCategories",
    }),
    serviceCategories() {
      return this.$store.getters.serviceCategories;
    },
  },
  created() {
    this.getServiceDetails();
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch("serviceCategory/index").then(() => {});
    },
    cropImage() {
      let croppedImageDataUrl = this.cropper.getCroppedCanvas().toDataURL(this.fileDetails.mimeType, 0.6);
      this.service.images.push({dataURL: croppedImageDataUrl, upload: {uuid: Date.now()}})
      this.results.push(croppedImageDataUrl);
    },

    removeCroppedImage(index) {
      this.results.splice(index, 1);
      this.service.images.splice(index, 1);
    },
    recreateImage(img) {
      this.displayImage = img;
      this.initializeCropper();
    },
    initializeCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }

      setTimeout(() => {
        let image = document.querySelector('#cropper');
        this.cropper = new Cropper(image, {
          dragMode: 'move',
          aspectRatio: 0.8,
          background: false,
          autoCropArea: 0.65,
          restore: false,
          guides: false,
          center: false,
          highlight: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          toggleDragModeOnDblclick: false,
        });
      }, 200)
    },

    selectedFile(event) {
      this.displayImage = URL.createObjectURL(event.target.files[0]);
      this.fileDetails.type = event.target.files[0].type;
      this.fileDetails.name = event.target.files[0].name;

      $("#exampleModal").modal("show");
      this.initializeCropper();

    },

    getServiceDetails() {
      this.isLoading = true;
      let salon_id = this.salon_details.salon_id;
      index("/api/services/" + salon_id)
        .then((res) => {
          let service_id = this.$route.params.id;
          const { data } = res;
          this.service = data.find((e) => e.service_id === Number(service_id));
          if (!this.service) {
            Swal.fire({
              title: "Error!",
              text: "Sorry the service does not exist",
              icon: "error",
              confirmButtonText: "close",
            }).then((res) => {
              if (res) {
                this.$router.push("/admin/settings");
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },


    updateService() {
      if(this.service.images.length <1){
        alert('Select 1 or more images for the service');
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("service/update", this.service)
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Service has been updated successfully",
            icon: "success",
            confirmButtonText: "close",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.roll-element {
  width: 60px;
  height: 60px;
  margin: 1px;
  position: relative;
  user-select: none;
}

.reo-wrapper {
  position: relative;
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1.25em;
}

.roll-element-order {
  position: absolute;
  top: 0px;
  right: 2px;
  z-index: 2;
  color: white;
}

.cg-btn-upload {
  width: 60px;
  height: 75px;
  margin: 1px;
  position: relative;
  color: rgba(173, 181, 189, 0.8);
  border: 2px dashed #adb5bd;
  border-radius: 3px;
  font-size: 22px;
  background-color: transparent;
  font-weight: 400;
}

.cg-btn-upload:before {
  border-radius: 3px;
  width: 60px;
  height: 75px;
  border: 2px solid #adb5bd;
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  opacity: 0;
  transition: all 0.5s ease, opacity 0.3s ease-out;
}

</style>


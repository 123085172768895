import AdminendPanel from "Layout/adminPanel/AdminPanel";
import UpdateService from "Views/adminPages/UpdateService";
import Schedules from "../views/adminPages/Schedules";
import Dashboard from "../views/adminPages/Dashboard";
import Settings from "../views/adminPages/Settings";
import AdvancedBookingSettings from "Views/adminPages/AdvancedBookingSettings.vue";

// dashboard components
// const Dashboard = () => import('Views/adminPages/Dashboard');
// const Messages = () => import('Views/adminPages/Messages');
const Bookings = () => import("Views/adminPages/Bookings");
const AdminBookingDetail = () => import("Views/adminPages/AdminBookingDetail");
const Reviews = () => import("Views/adminPages/Reviews");
const Bookmarks = () => import("Views/adminPages/Bookmarks");
const Services = () => import("Views/adminPages/Services");
const AddService = () => import("Views/adminPages/AddService");
const Profile = () => import("Views/adminPages/Profile");
const BookingSettings = () => import("../views/adminPages/BookingSettings");
const SalonProfileSettings = () =>
  import("../views/adminPages/SalonProfileSettings");
const PersonalProfile = () => import("../views/adminPages/PersonalProfile");
const PaymentSettings = () => import("../views/adminPages/PaymentSettings");

export default {
  path: "/admin",
  component: AdminendPanel,
  redirect: "/admin/dashboard",
  children: [
    {
      path: "/admin/dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Dashboard"
      }
    },
    {
      path: "/admin/settings",
      component: Settings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Settings"
      }
    },

    {
      path: "/admin/personal-profile",
      component: PersonalProfile,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "PersonalProfile"
      }
    },
    {
      path: "/shop-booking-details/:id/",
      component: AdminBookingDetail,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Booking Details"
      }
    },
    {
      path: "/admin/payment-settings",
      component: PaymentSettings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Payment Settings"
      }
    },
    {
      path: "/admin/profile-settings",
      component: SalonProfileSettings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "ProfileSettings"
      }
    },

    {
      path: "/admin/booking-settings",
      component: BookingSettings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "BookingSettings"
      }
    },
    {
      path: "/admin/advanced-booking-settings",
      component: AdvancedBookingSettings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "BookingSettings"
      }
    },
    {
      path: "/admin/schedules",
      component: Schedules,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Schedules"
      }
    },
    // {
    //    path: '/admin/messages',
    //    component: Messages,
    //    meta: {
    //       title: 'Messages'
    //    }
    // },
    {
      path: "/admin/bookings",
      component: Bookings,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Bookings"
      }
    },

    {
      path: "/admin/reviews",
      component: Reviews,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Reviews"
      }
    },
    {
      path: "/admin/bookmarks",
      component: Bookmarks,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Bookmarks"
      }
    },
    {
      path: "/admin/services",
      component: Services,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Services"
      }
    },
    {
      path: "/admin/add-new-service",
      component: AddService,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Add Service"
      }
    },
    {
      path: "/admin/update-service/:id",
      component: UpdateService,
      props: true,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Update Service"
      }
    },
    {
      path: "/admin/profile",
      component: Profile,
      meta: {
        requiresAuth: true,
        requiresProvider: true,
        title: "Profile"
      }
    }
  ]
};

import Swal from "sweetalert2";
<template>
  <div class="dashboard-content mt-4">
    <!-- Notice -->
    <!-- Content -->
    <div class="text-primary mb-3 cursor-pointer" @click="$router.go(-1)">
        <i class="fa fa-arrow-left"></i> Back
      </div>
    <div class="row">
      <div class="col-md-12 text-right mb-2">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Perform an action
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a @click="falseLoginToSalon()" class="dropdown-item cursor-pointer"
              >Login to Shop</a
            >
            <router-link
              :to="
                '/shop-details/' +
                  shop_id +
                  '/personal-info/' +
                  salon['salon_name']
              "
              class="dropdown-item cursor-pointer"
              >Manage Shop Info</router-link
            >
            <router-link
              :to="'/booking-settings/' + shop_id + '/' + salon['salon_name']"
              class="dropdown-item cursor-pointer"
              href="#"
              >Manage Booking Settings</router-link
            >
            <router-link
              :to="
                '/shop-details/' + shop_id + '/services/' + salon['salon_id']
              "
              class="dropdown-item cursor-pointer"
              href="#"
              >Manage Services</router-link
            >
            <router-link
              :to="
                '/salon-personal-info/' + shop_id + '/' + salon['salon_name']
              "
              class="dropdown-item cursor-pointer"
              >Manage Personal Info</router-link
            >
            <a
              class="dropdown-item cursor-pointer"
              :class="[salon['approve'] === 1 ? 'text-danger' : 'text-success']"
              @click="approveShop(salon['salon_id'], salon['approve'])"
              >{{
                salon["approve"] === 1 ? "Disapprove Shop" : "Approve Shop"
              }}</a
            >
              <a class="dropdown-item cursor-pointer text-danger"
                 @click="confirmDelete(salon['salon_id'])"
              >
                  <i class="fa fa-trash mr-0"></i> Delete
              </a>

          </div>

        </div>


      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 border-right">
                <h4>
                  Shop details
                  <span
                    class="badge badge-pill"
                    :class="[
                      salon['approve'] === 0 ? 'badge-warning' : 'badge-success'
                    ]"
                    >{{
                      salon["approve"] === 0 ? "Pending approval" : "approved"
                    }}</span
                  >
                </h4>
                <hr />
                <span>Category : </span>
                <span
                  class="badge badge-pill badge-primary mr-2"
                  v-for="(s, i) in salon['salon_category']"
                  :key="i"
                  >{{ s["service_category_name"] }}</span
                >

                <div class="row mb-3 mt-4">
                  <div class="col-md-12">
                    <h6>Business Owner Information</h6>
                  </div>
                  <div class="col-5">
                    Name :
                  </div>
                  <div class="col-7">
                    {{ salon["user"] ? salon["user"]["name"] : "Not set" }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5">
                    Phone :
                  </div>
                  <div class="col-7">
                    {{
                      salon["user"] ? salon["user"]["phone_number"] : "Not set"
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <h6>Shop Information</h6>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5">
                    Name :
                  </div>
                  <div class="col-7">
                    {{ salon["salon_name"] ? salon["salon_name"] : "Not set" }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5">
                    Phone :
                  </div>
                  <div class="col-7">
                    {{
                      salon["user"] ? salon["user"]["phone_number"] : "Not set"
                    }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5">
                    Region :
                  </div>
                  <div class="col-7">
                    {{ salon["region"] ? salon["region"] : "Not set" }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5">
                    Location :
                  </div>
                  <div class="col-7">
                    {{ salon["location"] ? salon["location"] : "Not set" }}
                  </div>
                </div>
                <!--                <div class="row mb-3">-->
                <!--                  <div class="col-md-5">-->
                <!--                    Description :-->
                <!--                  </div>-->
                <!--                  <div class="col-md-7">-->
                <!--                    {{ salon["description"] }}-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="col-md-7">
                <h4>Services</h4>
                <hr />
                <div v-for="(s, i) in salon['services']" :key="i">
                  <div
                    class="media mb-4 border-bottom"
                    :class="s['inactive_category'] ? 'bg-disabled' : ''"
                  >
                    <img
                      :src="
                        s['images'].length > 0
                          ? s['images'][0]['image']
                          : '../img/avatar.png'
                      "
                      style="width: 70px"
                      class="mr-3 img-fluid"
                      alt="..."
                    />
                    <div class="media-body">
                      <h5 class="mt-0 mr-3 mb-0 text-capitalize">
                        {{ s["service_name"] }}
                        <span style="font-size: 16px;"
                          >£{{ s["service_price"] }}</span
                        >
                      </h5>
                      <div>Time taken : {{ s["service_time_taken"] }} mins</div>
                      <!--                      <h6>GHC {{ s["service_price"] }}</h6>-->
                      <p>{{ s["service_description"] }}</p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="((salon || {})['services'] || []).length < 1"
                  class="text-center"
                >
                  <img
                    src="@/assets/images/no_booking.png"
                    class="img-fluid"
                    style="width: 300px;"
                    alt=""
                  />
                  <h6>No Services at the moment</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <admin-mobile-footer />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
const { mapActions } = createNamespacedHelpers("salon");
import { update, index } from "@/api/resource";
import Swal from "sweetalert2";
import {apisService} from "@/api/apis.service";

export default {
  data() {
    return {
      salon: {},
      isLoading: false,
      shop_id: ""
    };
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },

  computed: {
    ...mapGetters({
      profile: "salon_user"
    })
  },
  methods: {
    ...mapActions(["shopDetails"]),
    getSalonDetails(id) {
      this.isLoading = true;
      index("/api/get-salon-by-id/" + id)
        .then(res => {
          this.salon = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    approveShop(id, status) {
      let r = confirm(
        `Are you sure you want to ${status === 1 ? "disapprove this shop" : "approve this shop"}`
      );
      if (r) {
        this.isLoading = true;
        update("/api/approve-salon", id, { status: status === 1 ? 0 : 1 })
          .then(res => {
            this.salon["approve"] = res.data["approve"];
            let message_status = status === 1 ? "disapproved" : "approved";
            console.log(this.salon["approve"]);
            Swal.fire({
              title: "Success",
              text: "Salon has been " + message_status + " successfully",
              icon: "success"
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

      confirmDelete(salonId) {
          if (window.confirm('Are you sure you want to delete this salon?')) {
              this.deleteShop(salonId);
          }
      },

      deleteShop(salonId){
          apisService.deleteShop(salonId).then((response) => {
              const {success, message, data} = response;
              if (success) {
                  alert(message)
                  this.$router.push('/super-admin/home');
              } else {
                  alert("Error - " + message)
              }
          });

      },
    falseLoginToSalon() {
      this.$store.dispatch("user/falseLoginToSalon", this.salon).then(res => {
          this.$router.push("/admin/dashboard");
      }).catch(() => {
        Swal.fire({
          title: "Error",
          text: "Ooops! There was an unexpected error",
          icon: "error"
        });
      });
    }
  },
  created() {
    this.shop_id = this.$route.params["id"];
    if (this.shop_id) {
      this.getSalonDetails(this.shop_id);
    }
  }
};
</script>

import Schedules from "../views/adminPages/Schedules";
import Dashboard from "../views/customerDashboard/Dashboard";
import CustomerPanel from "../layouts/customerDashboardPanel/CustomerPanel";
import BookingDetail from "Views/customerDashboard/BookingDetail";

const Bookings = () => import("../views/customerDashboard/Bookings");
const Profile = () => import("../views/customerDashboard/Profile");
const PersonalProfile = () => import("../views/customerDashboard/PersonalProfile");

export default {
  path: "/customer",
  component: CustomerPanel,
  redirect: "/customer/dashboard",
  children: [
    {
      path: "/customer/dashboard",
      component: Dashboard,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Dashboard"
      }
    },
    {
      path: "/booking-details/:id/:name",
      component: BookingDetail,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Booking Details"
      }
    },

    {
      path: "/admin/personal-profile",
      component: PersonalProfile,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Personal Profile"
      }
    },
    {
      path: "/admin/schedules",
      component: Schedules,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Schedules"
      }
    },

    {
      path: "/admin/bookings",
      component: Bookings,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Bookings"
      }
    },
    {
      path: "/admin/profile",
      component: Profile,
      meta: {
        requiresAuth:true,
        requiresConsumer:true,
        title: "Profile"
      }
    }
  ]
};

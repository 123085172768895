<template>
  <div class="dashboard-content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    />
    <div class="row">
      <!-- Profile -->
      <div class="col-lg-12">
        <button class="btn btn-link" @click="$router.go(-1)">
          <i class="fa fa-arrow-left" /> Back to salon Info
        </button>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <form @submit.prevent="update">
              <div class="my-profile">
                <h4 class="mb-3">{{ profile.salon_name }} Details</h4>
                <div class="form-group">
                  <div>Business Category</div>
                  <div class="checkboxes in-row margin-bottom-20">
                    <span v-for="(c, i) in categories" :key="i">
                      <input
                        :id="'check' + i"
                        type="checkbox"
                        :value="c"
                        v-model="profile['salon_category']"
                      /><label :for="'check' + i">{{
                        c.service_category_name
                      }}</label>
                    </span>
                  </div>
                </div>
                <div class="row with-forms">
                  <div class="col-md-6">
                    <label>Shop Name</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="profile.salon_name"
                        required=""
                        id="salon_name"
                        placeholder="Salon Name"
                        class="form-control form-control-alternative"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label>Phone</label>
                    <div class="form-group">
                      <input
                        type="text"
                        placeholder="Phone"
                        v-model="profile.phone_number"
                        required=""
                        class="form-control form-control-alternative"
                      />
                    </div>
                  </div>
                </div>

                <div class="add-listing-headline">
                  <h3>Location</h3>
                </div>
                <div class="submit-section">
                  <!-- Row -->
                  <div class="row with-forms">
                    <!-- City -->
                    <div class="col-md-6">
                      <label>Region</label>
                      <select
                        required=""
                        class="form-control form-control-alternative"
                        v-model="profile.region"
                      >
                        <option selected>Region</option>
                        <option>AHAFO</option>
                        <option>ASHANTI</option>
                        <option>BONO EAST</option>
                        <option>BRONG AHAFO</option>
                        <option>CENTRAL</option>
                        <option>EASTERN</option>
                        <option>GREATER ACCRA</option>
                        <option>NORTH EAST</option>
                        <option>NORTHERN</option>
                        <option>OTI</option>
                        <option>SAVANNAH</option>
                        <option>UPPER EAST</option>
                        <option>UPPER WEST</option>
                        <option>WESTERN</option>
                        <option>WESTERN NORTH</option>
                        <option>VOLTA</option>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Place</label>
                        <input
                          required=""
                          type="text"
                          id="autocomplete"
                          placeholder="Street Name"
                          class="form-control form-control-alternative"
                          v-model="profile.location"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="add-listing-headline">
                  <h3 class="mt-2">Images</h3>
                </div>
                <div class="row with-forms">
                  <div class="col-md-12">
                    <dropzone
                      aria-required="true"
                      ref="myVueDropzone"
                      id="dropzone"
                      class="mb-2"
                      required=""
                      :options="dropzoneOptions"
                      @vdropzone-success="upload_avatar"
                      @vdropzone-file-added="addingFiles"
                      @vdropzone-error="errorFile"
                      @vdropzone-removed-file="removedFile"
                    >
                    </dropzone>
                  </div>
                </div>

                <!--  get long and lat-->
                <div class="add-listing-headline">
                  <h3>Social Media Handles</h3>
                </div>
                <div class="row with-forms">
                  <!-- Phone -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="twitter">Twitter</label>
                      <input
                        id="twitter"
                        type="text"
                        placeholder="Twitter"
                        class="form-control form-control-alternative"
                        v-model="profile.twitter"
                      />
                    </div>
                  </div>
                  <!-- Website -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="facebook">Facebook</label>
                      <input
                        type="text"
                        id="facebook"
                        placeholder="Facebook"
                        class="form-control form-control-alternative"
                        v-model="profile.facebook"
                      />
                    </div>
                  </div>
                  <!-- Email Address -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="instagram">Instagram</label>
                      <input
                        type="text"
                        placeholder="Instagram"
                        id="instagram"
                        class="form-control form-control-alternative"
                        v-model="profile.instagram"
                      />
                    </div>
                  </div>
                </div>

                <label for="description">Description</label>
                <textarea
                  class="form-control form-control-alternative mb-4"
                  id="description"
                  cols="5"
                  rows="5"
                  v-model="profile.description"
                >
                Description of your salon
              </textarea
                >
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="disableButton"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
      <mobilefooter />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Provider from "../../components/Profile/Provider.vue";
import Swal from "sweetalert2";
import FileUpload from "../../components/inputs/FileUpload";
import Dropzone from "vue2-dropzone";
import "vue-loading-overlay/dist/vue-loading.css";
import { index } from "@/api/resource";

export default {
  name: "Admin-salon-profile",
  data() {
    return {
      categories: [],
      salonCategory: [],
      disableButton: false,
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      profile: {},
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 5,
        headers: { "My-Awesome-Header": "header value" },
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,",
        addRemoveLinks: true
      },
      autocomplete: ""
    };
  },
  components: {
    Provider,
    FileUpload,
    Dropzone,
    loading: () => import("vue-loading-overlay"),
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },
  methods: {
    ...mapMutations({
      addSalonData: "user/SET_SALON"
    }),
    executeMap() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          componentRestrictions: {
            country: "uk"
          },
          fields: [
            "address_components",
            "geometry",
            "icon",
            "name",
            "formatted_address"
          ]
        }
      );
      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      this.profile["latitude"] = place.geometry.location.lat();
      this.profile["longitude"] = place.geometry.location.lng();
      this.profile.location = place.name + " (" + place.formatted_address + ")";
    },
    upload_avatar(file) {
      this.disableButton = false;
      if (!file["manuallyAdded"]) {
        this.profile["salon_images"].push(file);
      }
    },

    errorFile(file, message) {
      this.$refs.myVueDropzone.removeFile(file);
      this.disableButton = false;
    },

    removedFile(file) {
      this.profile["salon_images"].splice(
        this.profile["salon_images"].indexOf(file),
        1
      );
    },

    addingFiles() {
      this.disableButton = true;
    },

    getSaloonDetail() {
      if (this.$route.params.id) {
        this.isLoading = true;
        index("/api/get-salon-by-id/" + this.$route.params.id)
          .then(response => {
            const { data } = response;
            this.profile = data;
            console.log(data);
            if (this.profile["salon_images"]) {
              this.profile["salon_images"].forEach((e, i) => {
                let file = { size: "", name: "image " + i, type: "image/png" };
                this.$refs.myVueDropzone.manuallyAddFile(file, e["image"]);
              });
              this.disableButton = false;
            }
            this.profile["salon_category"] = this.profile["salon_category"].map(
              e => {
                delete e["services"];
                return e;
              }
            );
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    getCategory() {
      this.$store.dispatch("serviceCategory/index").then(res => {
        this.categories = res;
      });
    },

    update() {
      if (this.profile["salon_images"].length < 3) {
        alert("Upload at least 3 images of your shop to proceed");
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("salon/update", this.profile)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Your profile has been updated successfully",
            icon: "success"
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.getCategory();
    this.getSaloonDetail();
    this.$loadScript(
      "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.VUE_APP_GOOGLE_MAPS_KEY +
        "&libraries=places"
    )
      .then(() => {
        this.executeMap();
      })
      .catch(() => {});
  }
};
</script>

<style scoped>
.add-listing-headline {
  padding-bottom: 10px;
}
</style>

<template>
  <div class="dashboard-content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    />
    <div class="row">
      <div class="col-lg-12">
        <form id="add-listing" @submit.prevent="addService">
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>Add A New Service</h3>
            </div>
            <!-- Title -->
            <div class="row with-forms">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="service-name">Service Name</label>
                  <input
                    required
                    id="service-name"
                    type="text"
                    placeholder="Name of Service"
                    class="form-control form-control-alternative"
                    v-model="service.service_name"
                  />
                </div>
              </div>
            </div>
            <!-- Row -->
            <div class="row with-forms">
              <!-- Status -->
              <div class="col-md-6">
                <label for="Category">Category</label>
                <select
                  required
                  class="custom-select form-control-alternative mb-3"
                  v-model="service.service_category_id"
                  id="Category"
                >
                  <option
                    v-for="item in salonDetails['salon_category']"
                    :key="item.service_category_id"
                    :label="item.service_category_name"
                    :value="item.service_category_id"
                    >{{ item.service_category_name }}</option
                  >
                </select>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="price">Price</label>
                  <input
                    type="number"
                    id="price"
                    required
                    min="5"
                    placeholder="Price"
                    class="form-control form-control-alternative"
                    v-model="service.service_price"
                  />
                </div>
              </div>

              <!-- Type -->
            </div>

            <div class="row with-forms">
              <div class="col-md-6">
                <label for="">Time Taken</label>
                <select
                  required
                  class="custom-select form-control-alternative mb-3"
                  v-model="service.service_time_taken"
                >
                  <option label="Estimated Time Taken">Select Time</option>
                  <option value="15">15 minutes</option>
                  <option value="30">30 minutes</option>
                  <option value="45">45 minutes</option>
                  <option value="60">1 hour</option>
                  <option value="75">1.5 hours</option>
                  <option value="120">2 hours</option>
                  <option value="150">2.5 hours</option>
                  <option value="180">3 hours</option>
                  <option value="210">3.5 hours</option>
                  <option value="240">4 hours</option>
                  <option value="270">4.5 hours</option>
                  <option value="300">5 hours</option>
                  <option value="390">6.5 hours</option>
                  <option value="420">7 hours</option>
                  <option value="450">7.5 hours</option>
                  <option value="480">8 hours</option>
                </select>
              </div>
            </div>
            <!-- Row / End -->
          </div>
          <!-- Section / End -->
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <label>Images</label>
            <!-- Dropzone -->
            <dropzone
              aria-required="true"
              id="myVueDropzone"
              ref="myVueDropzone"
              class="mb-2"
              :options="dropzoneOptions"
              @vdropzone-success="upload_avatar"
              @vdropzone-file-added="addingFiles"
              @vdropzone-error="errorFile"
            >
            </dropzone>
          </div>
          <!-- Section / End -->
          <!-- Section -->
          <div class="add-listing-section mb-4">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3>Details</h3>
            </div>
            <div class="form">
              <textarea
                class="form-control form-control-alternative"
                placeholder="Description"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="service.service_description"
              />
            </div>
            <div class="text-center mb-5">
              <button
                class="btn btn-success"
                type="submit"
                :disabled="disableButton"
              >
                Save <i class="fa fa-arrow-circle-right" />
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- Copyrights -->
      <mobilefooter />
    </div>
  </div>
</template>

<script>
import Dropzone from "vue2-dropzone";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { index } from "@/api/resource";

export default {
  components: {
    Dropzone,
    mobilefooter: () => import("@/components/Footer/MobileFooter"),
    Loading: () => import("vue-loading-overlay")
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 2,
        headers: { "My-Awesome-Header": "header value" },
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,",
        addRemoveLinks: true
      },
      disableButton: false,
      service: {
        image: [],
        service_name: "",
        service_description: "",
        service_price: 0,
        service_time_taken: 15,
        service_category_id: ""
      },
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      salonDetails: {},
      salon_id: this.$route.params.salon_name,
    };
  },
  computed: {
    serviceCategories() {
      return this.$store.getters.serviceCategories;
    }
  },
  created() {
    this.getList().then(() => {
      this.getSaloonDetail();
    });
  },
  methods: {
    getList() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("serviceCategory/index")
          .then(() => {
            resolve("done");
          })
          .catch(() => {
            reject("err");
          });
      });
    },

    getSaloonDetail() {
      this.isLoading = true;
      index("/api/get-salon-by-id/" + this.salon_id)
        .then(response => {
          const { data } = response;
          this.salonDetails = data;
          if (this.salonDetails["salon_category"].length > 0) {
            this.service["service_category_id"] = this.salonDetails[
              "salon_category"
            ][0]["service_category_id"];
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    errorFile(file, message) {
      this.$refs.myVueDropzone.removeFile(file);
      this.disableButton = false;
      alert(message);
    },

    addingFiles() {
      this.disableButton = true;
    },

    upload_avatar(file) {
      this.service.image.push(file);
      this.disableButton = false;
    },

    addService() {
      this.isLoading = true;
      let data = {
        service: this.service,
        user_id: this.salonDetails["user_id"]
      };
      this.$store
        .dispatch("service/store", data)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Service has been added successfully",
            icon: "success"
          }).then(res => {
            if (res.isConfirmed) {
              this.$router.push(
                "/shop-details/" +
                  this.salon_id +
                  "/services/" +
                  this.salonDetails["salon_id"]
              );
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

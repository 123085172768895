<template>
  <div class="main-wrapper">
    <div class="content" style="min-height:90vh">
      <div class="container">
        <div class="contact-wrapper">
          <div class=" mb-50">
            <section class="block-space contact-info">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-8 offset-md-2 mb-4">
                    <div class="contact-media" style=" border-bottom: none">
                      <div>
                        <form @submit.prevent="submit">
                          <div class="row">
                            <div class="col-12 text-center">
                              <router-link to="#">
                                <img
                                  src="/static/images/logo.png"
                                  width="65"
                                  class="mb-4"
                                  alt=""
                                />
                              </router-link>
                              <h1>CHRISTMAS GIVEAWAY</h1>
                              <h3>
                                Win a free mani-pedi at <br />
                                Amari Nail & Wax Bar
                              </h3>
                              <!--                            <h3></h3>-->
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mb-4">
                                <div
                                  class="input-group input-group-alternative"
                                >
                                  <input
                                    class="form-control"
                                    placeholder="Name"
                                    required
                                    v-model="data.name"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mb-6">
                                <div class="input-group mb-2 mr-sm-2">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style="border: none"
                                    >
                                      @
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    style="border: none"
                                    v-model="data.social_media_handle"
                                    placeholder="Social media handle"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mb-4">
                                <div
                                  class="input-group input-group-alternative"
                                >
                                  <select
                                    required
                                    class="form-control"
                                    v-model="data.social_media"
                                  >
                                    <option value="Facebook">Facebook</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Snapchat">Snapchat</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mb-4">
                                <div
                                  class="input-group input-group-alternative"
                                >
                                  <input
                                    required
                                    v-model="data.email"
                                    class="form-control"
                                    placeholder="Email"
                                    type="email"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              :disabled="isLoading"
                              type="submit"
                              class="btn btn-danger btn-round btn-lg"
                            >
                              {{ isLoading ? "submitting..." : "Submit Entry" }}
                            </button>
                          </div>
                        </form>
                      </div>
                      <hr style="border-top:2px solid #32325d94" />
                      <div class="text-right pb-4 pr-2">
                        <div style="font-size: 20px">
                          <a
                            href="https://docs.google.com/document/d/1yf3F5jre41I-_d4olGWAYH5yBihTbOOG-s6ZXKgNeFM/edit"
                            target="_blank"
                            >Ts & Cs Apply</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from "Components/globalFrontendComponents/TitleBar";
import { store } from "@/api/resource";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      data: {
        name: "",
        social_media: "Instagram",
        social_media_handle: "",
        email: ""
      },
      isLoading: false
    };
  },
  components: {
    TitleBar: TitleBar
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.data.social_media_handle = "@" + this.data.social_media_handle;
      store("/api/give-away", this.data)
        .then(response => {
          const { data } = response;
          Swal.fire({
            title: "Success!",
            text: "Submited successfully...",
            icon: "success",
            confirmButtonText: "ok"
          }).then(res => {
            if (res.isConfirmed) {
              this.$router.push("/");
            }
          });
        })
        .catch(error => {
          Swal.fire({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "ok"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.input-group {
  border-bottom: 2px solid #32325d94 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.main-wrapper {
  background-image: url("../../../public/static/images/give-away-back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 567px) {
  h1 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 15px !important;
  }
}

/* common */

/*.ribbon-top-left span {*/
/*  right: -25px;*/
/*  top: 30px;*/
/*  transform: rotate(-45deg);*/
/*}*/

/*.ribbon {*/
/*  position: absolute;*/
/*  left: 15px;*/
/*  transform: rotate(-30deg);*/
/*  top: 30px;*/
/*}*/
</style>

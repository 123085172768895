<template>
  <div class="container pt-5">
    <div class="row">
      <div class="col-12 mb-3">
        <router-link to="/customer/dashboard"><i class="fa fa-arrow-left"></i> Back</router-link>
      </div>
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="db-tile">
            <h4>Booking Details</h4>
          </div>
          <div class="card-body" v-if="!isLoading">
            <div class="mb-2">Booking Reference: {{booking.appointment_reference}}</div>

            <div v-if="booking.status" class="badge badge-success text-light badge-pill text-capitalize mb-2"><i
                class="fa fa-clock-o"></i> {{ booking.status }}
            </div>
            <h4 class="d-md-none d-sm-block">{{ booking.date_time | toSmBookingDateTime }} at {{
                booking.start_time
              }}</h4>
            <h4 class="d-md-block d-none">{{ booking.date_time | toBookingDateTime }} at {{ booking.start_time }}</h4>
            <div class="row">
              <div class="col-md-10 mt-4 mb-1">
                <router-link :to="'/salon/' + ((booking || {}).salon || {}).slug" class="text-dark">
                 <i class="fa fa-building-o" /> {{ booking.salon.salon_name }}
                </router-link>
                <div>
                  <a
                      :href="
                          'https://www.google.com/maps/search/?api=1&query=' +
                            ((booking || {}).salon || {}).latitude +
                            ',' +
                            ((booking || {}).salon || {}).longitude
                        "
                      target="_blank"
                  >{{ ((booking || {}).salon || {})["location"] }}</a
                  >
                </div>
              </div>
              <div class="col-md-2">
                <router-link :to="'/salon/' + ((booking || {}).salon || {}).slug">
                  <div v-if="booking.service.length > 0">
                    <img
                        :src="booking.service[0]['images'][0]['image']"
                        style="height: 94px; object-fit: cover"
                        alt=""
                        class="img-fluid w-100"
                    />
                  </div>

                </router-link>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 mt-3 mb-3">
                <h3>Appointment Details</h3>
              </div>
              <div class="col-md-12">
                <div class="row" v-for="(s,i) in booking.service" :key="i">
                  <div class="col-9">
                    <div>
                      {{ s.service_name }}
                    </div>
                  </div>
                  <div class="col-3 text-right">
                    {{ s.service_price | toCurrencyFormatRaw}}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <hr class="mt-3">
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-8">
                    <h6>Sub Total</h6>
                  </div>
                  <div class="col-4">
                    <h6 class="text-right">{{ booking.sub_total | toCurrencyFormatRaw}}</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <h6>Service Charge</h6>
                  </div>
                  <div class="col-4">
                    <h6 class="text-right">{{ booking.service_charge | toCurrencyFormatRaw }}</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <h4>Total</h4>
                  </div>
                  <div class="col-4">
                    <h5 class="text-right text-primary">{{ booking.total | toCurrencyFormatRaw}}</h5>
                  </div>
                </div>
                <div class="row" v-if="booking.deposit >0">
                  <div class="col-8">
                    <h6>Deposit</h6>
                  </div>
                  <div class="col-4">
                    <h6 class="text-right text-danger"> {{ booking.deposit | toCurrencyFormatRaw}}</h6>
                  </div>
                </div>
                <div class="row" v-if="booking.outstanding_balance >0">
                  <div class="col-8">
                    <h4>Outstanding Balance</h4>
                  </div>
                  <div class="col-4">
                    <h5 class="text-right text-primary">{{ booking.outstanding_balance| toCurrencyFormatRaw}}</h5>
                  </div>
                </div>

              </div>


            </div>

            <div class="row mt-4">
              <div class="col-12">
                <h5>Your review</h5>
              </div>
              <div class="col-12" v-if="(booking ||{}).rating">
                <div class="mt-2 mb-2">
                  <i class="fa fa-star mr-1 text-warning"
                     v-for="(v,a) in Number(((booking ||{}).rating || {}).rating_value)" :key="'r'+a"/>
                  <i class="fa fa-star mr-1 text-ash" v-for="(v,b) in 5-((booking ||{}).rating || {}).rating_value"
                     :key="'b'+b"/>
                  {{ Math.round(((booking || {}).rating || {}).rating_value) }}/5 Great
                </div>
                <p>{{ ((booking || {}).rating || {}).comment }}</p>
              </div>
              <div class="col-12" v-else>
                <p>No rating at the moment.</p>
              </div>
            </div>

          </div>
          <div class="card-body" v-else>
            <div class="lines shine"/>
            <div class="lines shine"/>
            <div class="lines shine mt-5"/>
            <div class="lines shine"/>
            <div class="lines shine"/>
            <div class="lines shine mt-5"/>
            <div class="lines shine"/>
            <div class="lines shine"/>

          </div>
        </div>
      </div>
    </div>
    <mobilefooter/>

  </div>
</template>

<script>
import moment from "moment";

import {apisService} from "@/api/apis.service";

export default {
  data() {
    return {
      booking: '',
      isLoading: false,
      value: 1,
      appointmentDetails: '',
      comment: '',
      rating: '',
      getCurrency: process.env.VUE_APP_PRICE_SYMBOL,
    };
  },
  components: {
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },

  methods: {
    formatDate(d) {
      return d ? moment(d).format("Do MMMM, YYYY") : "";
    },
    bookingDetails() {
      this.isLoading = true;
      apisService.getBookingDetails(this.$route.params.id).then((response) => {
        const {success, message, data} = response;
        if (success) {
          this.booking = data;
        } else {
          alert("Error - " + message)
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
  created() {
    this.bookingDetails();
  }
};
</script>

<style scoped>
.badge-success {
  background: #1aae6f
}

.text-warning {
  color: #ffc61c !important;
}
</style>

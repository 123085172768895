import request from '@/utils/request'

export function index(url) {
  return request({
    url: url,
    method: 'get',
    params: {}
  })
}

export function indexparam(url,params) {
  return request({
    url: url,
    method: 'get',
    params: params
  })
}

export function destroy(url, id) {
  return request({
    url: url+'/'+id,
    method: 'delete',
    // params: {id}
  })
}

export function update(url, id, data) {
  return request({
    url: url + '/' + id,
    method: 'post',
    // params: {id},
    data
  })
}

export function putUpdate(url ,data) {
  return request({
    url: url,
    method: 'put',
    data
  })
}

export function store(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content mt-4"},[_c('div',{staticClass:"text-primary mb-3 cursor-pointer",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Back ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-2"},[_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Perform an action ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item cursor-pointer",on:{"click":function($event){return _vm.falseLoginToSalon()}}},[_vm._v("Login to Shop")]),_c('router-link',{staticClass:"dropdown-item cursor-pointer",attrs:{"to":'/shop-details/' +
                _vm.shop_id +
                '/personal-info/' +
                _vm.salon['salon_name']}},[_vm._v("Manage Shop Info")]),_c('router-link',{staticClass:"dropdown-item cursor-pointer",attrs:{"to":'/booking-settings/' + _vm.shop_id + '/' + _vm.salon['salon_name'],"href":"#"}},[_vm._v("Manage Booking Settings")]),_c('router-link',{staticClass:"dropdown-item cursor-pointer",attrs:{"to":'/shop-details/' + _vm.shop_id + '/services/' + _vm.salon['salon_id'],"href":"#"}},[_vm._v("Manage Services")]),_c('router-link',{staticClass:"dropdown-item cursor-pointer",attrs:{"to":'/salon-personal-info/' + _vm.shop_id + '/' + _vm.salon['salon_name']}},[_vm._v("Manage Personal Info")]),_c('a',{staticClass:"dropdown-item cursor-pointer",class:[_vm.salon['approve'] === 1 ? 'text-danger' : 'text-success'],on:{"click":function($event){return _vm.approveShop(_vm.salon['salon_id'], _vm.salon['approve'])}}},[_vm._v(_vm._s(_vm.salon["approve"] === 1 ? "Disapprove Shop" : "Approve Shop"))]),_c('a',{staticClass:"dropdown-item cursor-pointer text-danger",on:{"click":function($event){return _vm.confirmDelete(_vm.salon['salon_id'])}}},[_c('i',{staticClass:"fa fa-trash mr-0"}),_vm._v(" Delete ")])],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 border-right"},[_c('h4',[_vm._v(" Shop details "),_c('span',{staticClass:"badge badge-pill",class:[
                    _vm.salon['approve'] === 0 ? 'badge-warning' : 'badge-success'
                  ]},[_vm._v(_vm._s(_vm.salon["approve"] === 0 ? "Pending approval" : "approved"))])]),_c('hr'),_c('span',[_vm._v("Category : ")]),_vm._l((_vm.salon['salon_category']),function(s,i){return _c('span',{key:i,staticClass:"badge badge-pill badge-primary mr-2"},[_vm._v(_vm._s(s["service_category_name"]))])}),_c('div',{staticClass:"row mb-3 mt-4"},[_vm._m(0),_c('div',{staticClass:"col-5"},[_vm._v(" Name : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["user"] ? _vm.salon["user"]["name"] : "Not set")+" ")])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_vm._v(" Phone : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["user"] ? _vm.salon["user"]["phone_number"] : "Not set")+" ")])]),_vm._m(1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_vm._v(" Name : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["salon_name"] ? _vm.salon["salon_name"] : "Not set")+" ")])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_vm._v(" Phone : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["user"] ? _vm.salon["user"]["phone_number"] : "Not set")+" ")])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_vm._v(" Region : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["region"] ? _vm.salon["region"] : "Not set")+" ")])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_vm._v(" Location : ")]),_c('div',{staticClass:"col-7"},[_vm._v(" "+_vm._s(_vm.salon["location"] ? _vm.salon["location"] : "Not set")+" ")])])],2),_c('div',{staticClass:"col-md-7"},[_c('h4',[_vm._v("Services")]),_c('hr'),_vm._l((_vm.salon['services']),function(s,i){return _c('div',{key:i},[_c('div',{staticClass:"media mb-4 border-bottom",class:s['inactive_category'] ? 'bg-disabled' : ''},[_c('img',{staticClass:"mr-3 img-fluid",staticStyle:{"width":"70px"},attrs:{"src":s['images'].length > 0
                        ? s['images'][0]['image']
                        : '../img/avatar.png',"alt":"..."}}),_c('div',{staticClass:"media-body"},[_c('h5',{staticClass:"mt-0 mr-3 mb-0 text-capitalize"},[_vm._v(" "+_vm._s(s["service_name"])+" "),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("£"+_vm._s(s["service_price"]))])]),_c('div',[_vm._v("Time taken : "+_vm._s(s["service_time_taken"])+" mins")]),_c('p',[_vm._v(_vm._s(s["service_description"]))])])])])}),(((_vm.salon || {})['services'] || []).length < 1)?_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"300px"},attrs:{"src":require("@/assets/images/no_booking.png"),"alt":""}}),_c('h6',[_vm._v("No Services at the moment")])]):_vm._e()],2)])])])])]),_c('admin-mobile-footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v("Business Owner Information")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v("Shop Information")])])])}]

export { render, staticRenderFns }
<template>
  <div class="dashboard-content">
    <div class="row">
      <!-- Invoices -->
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-md-2">Bookings</h4>
            <div class="row mb-md-2">
              <div class="col-6 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <span class="mr-1">Show</span>
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >
                    <span class="ml-1">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-6 col-md-6">
                <div
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">

                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  table-class="table table-centered w-100"
                  :busy="!isLoading"
                  :items="bookings"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template v-slot:cell(Service)="data">
                  {{
                    ((data.item["appointment_service"] || {})["service"] || {})[
                        "service_name"
                        ]
                  }}
                </template>

                <template v-slot:cell(Business)="data">
                  <router-link
                      :to="'/shop-details/' + (data.item.salon || {})['salon_id']"
                  >{{
                      (data.item.salon || {})["salon_name"]
                          ? (data.item.salon || {})["salon_name"]
                          : "N/A"
                    }}
                  </router-link>
                </template>

                <template v-slot:cell(Customer)="data">
                  {{ (data.item.user || {})["name"] ? (data.item.user || {})["name"] : "N/A" }}
                </template>

                <template v-slot:cell(price)="data">
                  £{{
                    ((data.item["appointment_service"] || {})["service"] || {})[
                        "service_price"
                        ]
                  }}
                </template>

                <template v-slot:cell(Status)="data">
                                      <span
                                          class="badge badge-pill badge-danger text-uppercase"
                                          v-if="(data.item ||{}).status === 'rejected'"
                                      >rejected</span
                                      >
                  <span
                      class="badge badge-pill badge-warning text-uppercase"
                      v-if="(data.item ||{}).status === null || (data.item || {}).status === ''"
                  >Upcoming</span
                  >
                  <span
                      class="badge badge-pill badge-info text-uppercase"
                      v-if="(data.item ||{}).status === 'accepted'"
                  >Pending</span
                  >
                  <span
                      class="badge badge-pill badge-success text-uppercase"
                      v-if="(data.item ||{}).status === 'completed'"
                  >Completed</span
                  >
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <!--          <div class="db-tile">-->
          <!--            <h4>All Bookings</h4>-->
          <!--          </div>-->
          <!--          <div class="dashboard-list-box table-responsive invoices with-icons">-->
          <!--            <table class="table table-hover">-->
          <!--              <thead>-->
          <!--                <tr>-->
          <!--                  <th>Service</th>-->
          <!--                  <th>Business</th>-->
          <!--                  <th>Customer</th>-->
          <!--                  <th>Booking Date</th>-->
          <!--                  <th>Price ()</th>-->
          <!--                  <th>Status</th>-->
          <!--                </tr>-->
          <!--              </thead>-->
          <!--              <tbody v-if="!isLoading">-->
          <!--                <tr v-for="(b, i) in bookings" :key="i">-->
          <!--                  <td>-->
          <!--                    <div class="text-capitalize">-->
          <!--                      {{-->
          <!--                        ((b["appointment_service"] || {})["service"] || {})[-->
          <!--                          "service_name"-->
          <!--                        ]-->
          <!--                      }}-->
          <!--                    </div>-->
          <!--                  </td>-->
          <!--                  <td>-->
          <!--                    <div class="text-capitalize">-->
          <!--                      <router-link-->
          <!--                        :to="'/shop-details/' + (b.salon || {})['salon_id']"-->
          <!--                        >{{-->
          <!--                          (b.salon || {})["salon_name"]-->
          <!--                            ? (b.salon || {})["salon_name"]-->
          <!--                            : "N/A"-->
          <!--                        }}</router-link-->
          <!--                      >-->
          <!--                    </div>-->
          <!--                  </td>-->
          <!--                  <td>-->
          <!--                    <div class="text-capitalize">-->
          <!--                      {{-->
          <!--                        (b.user || {})["name"] ? (b.user || {})["name"] : "N/A"-->
          <!--                      }}-->
          <!--                    </div>-->
          <!--                  </td>-->
          <!--                  <td>-->
          <!--                    {{ formatDate(b.date_time) }} @-->
          <!--                    {{ b.start_time + " - " + b.end_time }}-->
          <!--                  </td>-->
          <!--                  <td>-->
          <!--                    £{{-->
          <!--                      ((b["appointment_service"] || {})["service"] || {})[-->
          <!--                        "service_price"-->
          <!--                      ]-->
          <!--                    }}-->
          <!--                  </td>-->
          <!--                  <td>-->
          <!--                    <span-->
          <!--                      class="badge badge-pill badge-danger text-uppercase"-->
          <!--                      v-if="b.status === 'rejected'"-->
          <!--                      >rejected</span-->
          <!--                    >-->
          <!--                    <span-->
          <!--                      class="badge badge-pill badge-warning text-uppercase"-->
          <!--                      v-if="b.status === null || b.status === ''"-->
          <!--                      >Upcoming</span-->
          <!--                    >-->
          <!--                    <span-->
          <!--                      class="badge badge-pill badge-info text-uppercase"-->
          <!--                      v-if="b.status === 'accepted'"-->
          <!--                      >Pending</span-->
          <!--                    >-->
          <!--                    <span-->
          <!--                      class="badge badge-pill badge-success text-uppercase"-->
          <!--                      v-if="b.status === 'completed'"-->
          <!--                      >Completed</span-->
          <!--                    >-->
          <!--                  </td>-->
          <!--                </tr>-->
          <!--                <tr v-if="bookings.length < 1">-->
          <!--                  <td colspan="4" class="text-center">-->
          <!--                    <img-->
          <!--                      src="../../../public/static/images/no_bookings.png"-->
          <!--                      style="width: 200px"-->
          <!--                      class="img-fluid"-->
          <!--                      alt=""-->
          <!--                    />-->
          <!--                    No bookings at the moment-->
          <!--                  </td>-->
          <!--                </tr>-->
          <!--              </tbody>-->
          <!--              <tbody v-else>-->
          <!--                <tr v-for="(a, i) in 5" :key="i">-->
          <!--                  <td><div class="lines shine" /></td>-->
          <!--                  <td><div class="lines shine" /></td>-->
          <!--                  <td><div class="lines shine" /></td>-->
          <!--                  <td><div class="lines shine" /></td>-->
          <!--                </tr>-->
          <!--              </tbody>-->
          <!--            </table>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <!-- <admin-mobile-footer/> -->
  </div>
</template>

<script>
import moment from "moment";
import {index} from "@/api/resource";

export default {
  data() {
    return {
      bookings: [],
      isLoading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      fields: [
        'Service',
        'Business',
        'Customer',
        {
          key: "created_at",
          sortable: true,
          label: "Booking Date",

        },
        {
          key: "price",
          sortable: true,
          label: `Price (${process.env.VUE_APP_PRICE_SYMBOL})`,

        },

        'Status',
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.bookings.length;
    },
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAppointments() {
      this.isLoading = true;
      index("/api/all-appointments")
          .then(res => {
            this.bookings = res.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    formatDate(d) {
      return d ? moment(d).format("Do MMMM, YYYY") : "";
    }
  },
  created() {
    this.getAppointments();
  }
};
</script>

import { update, index, store, destroy } from "@/api/resource";

import {
  setSalon,
  getSalon
} from "@/utils/auth";
import {mapMutations} from "vuex";


const state = {
  salon: getSalon() || null,
};

const mutations = {
  SET_SALON: (state, salon) => {
    state.salon = salon;
  },
  SET_SALONS: (state, salons) => {
    state.salons = salons;
  },
};

const actions = {
  // get Asset Condition
  index({ commit }) {
    return new Promise((resolve, reject) => {
      index("/api/salon")
        .then(response => {
          const { data } = response;
          commit("SET_SALONS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store({ commit }, salon) {
    return new Promise((resolve, reject) => {
      store("/api/salon", salon)
        .then(response => {
          const { data } = response;
          commit("ADD_SALON", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update({ commit }, salon) {
    return new Promise((resolve, reject) => {
      update("/api/salon/update", salon.salon_id, salon)
        .then(response => {
          const { data } = response;
          if (data) {
            commit("SET_SALON", data);
            setSalon(data);
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updatePayment({ commit }, salon) {
    return new Promise((resolve, reject) => {
      store("/api/salon/update-payment", salon)
        .then(response => {
          const { data } = response;
            // console.log(data)
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addService({ commit }, service) {
    return new Promise((resolve, reject) => {
      store("/api/service/create-service", service)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateService({ commit }, service) {
    return new Promise((resolve, reject) => {
      store("/api/service/update-service", service)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeService({ commit }, service_id) {
    return new Promise((resolve, reject) => {
      store("/api/service/delete-service", service_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateSalonBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      store("/api/salon/update-salon-booking", data)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  allshops() {
    return new Promise((resolve, reject) => {
      index("/api/all-shops")
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  shopDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      index("/api/get-salon-by-id/" + id)
          .then(response => {
            const { data } = response;
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
    });
  },

  shopDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      index("/api/salon/" + id)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  delete({ commit }, salon_id) {
    return new Promise((resolve, reject) => {
      destroy("/api/salon", salon_id)
        .then(response => {
          commit("DELETE_SALON", salon_id);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

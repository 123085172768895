let salonInfo = "styleme-salon-info";
let TokenKey = "styleme-access-token";
let userInfo = "styleme-user-info";

export const useBearerTokenHeaders = (hasFile = false) => {

    const userItem = JSON.parse(localStorage.getItem(userInfo)) || null;
    if (userItem) {

        let mHeaders = {
            Authorization: 'Bearer ' + localStorage.getItem(TokenKey),
        }

        if (hasFile) {
            mHeaders['content-type'] = 'multipart/form-data'
        }

        return {
            headers: mHeaders
        }
    } else return {}

}

export function getUser() {
    // return Cookies.getJSON(userInfo);
    return JSON.parse(localStorage.getItem(userInfo)) || null;

}

export function setUser(data) {
    localStorage.setItem(userInfo, JSON.stringify(data));

    // return Cookies.set(userInfo, data);
}

export function removeUser() {
    localStorage.removeItem(userInfo);

    // return Cookies.remove(userInfo);
}

export function getSalon() {
    if(localStorage.getItem(salonInfo) == 'undefined'){
        localStorage.removeItem(salonInfo);
    }
    return JSON.parse(localStorage.getItem(salonInfo)) || null;
}

export function setSalon(data) {
    return localStorage.setItem(salonInfo, JSON.stringify(data));
}

export function removeSalon() {
    localStorage.removeItem(salonInfo);
}

export function getToken() {
    return localStorage.getItem(TokenKey)
    // return Cookies.get(TokenKey);
}

export function setToken(token) {
    localStorage.setItem(TokenKey, token);

    // return Cookies.set(TokenKey, token);
}

export function removeToken() {
    localStorage.removeItem(TokenKey);

    // return Cookies.remove(TokenKey);
}

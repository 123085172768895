<!-- Header Structure -->
<template>
  <div id="footer" class="sticky-footer" style="background-color: white;">
    <div class="row">
      <div class="col-md-12">
        <div class="copyrights text-dark">
          ©
          {{ new Date().getFullYear() }} Style Me. All Rights Reserved.
          <div style="font-size: 14px;">
            <span class="mr-3"
              ><a
                class="text-primary"
                href="https://docs.google.com/forms/d/10QgqcHTkhD_o5taLAIbzHB4XCACdyoWAzn5QpXZd8_o/edit"
                target="_blank"
                >Feedback</a
              ></span
            >
            <span class="mr-3"
              ><a
                href="https://docs.google.com/document/d/1yf3F5jre41I-_d4olGWAYH5yBihTbOOG-s6ZXKgNeFM/edit"
                target="_blank"
                >Terms and conditions</a
              ></span
            >
            <span class="mr-3"
              ><router-link to="/contact-us">Contact</router-link></span
            >
            <!--              <span class="mr-3"><router-link to='/session/ComingSoon'>Coming soon </router-link></span>-->
            <span class="mr-3">
              <a
                class="text-primary"
                href="https://www.instagram.com/stylemeghana/"
                target="_blank"
                ><i class="fa fa-instagram" /> </a
            ></span>
            <span class="mr-3">
              <a
                class="text-primary"
                href="https://www.twitter.com/stylemeghana/"
                target="_blank"
                ><i class="fa fa-twitter" /> </a
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<template>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="row">
      <!-- Invoices -->
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="db-tile">
            <h4>GiveAways</h4>
          </div>
          <div class="dashboard-list-box table-responsive invoices with-icons">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Social Media</th>
                  <th>Social Media Handle</th>
                </tr>
              </thead>
              <tbody v-if="!isLoading">
                <tr v-for="(s, i) in give_aways" :key="i">
                  <td>
                    <div class="text-capitalize">
                      {{ s.name ? s.name : "Not set" }}
                    </div>
                  </td>
                  <td>
                    {{ s.email }}
                  </td>
                  <td>
                    {{ s.social_media }}
                  </td>
                  <td>
                    {{
                      s.social_media_handle ? s.social_media_handle : "Not set"
                    }}
                  </td>
                </tr>
                <tr v-if="give_aways.length < 1">
                  <td colspan="6" class="text-center">
                    <img
                      src="../../../public/static/images/no_bookings.png"
                      style="width: 200px"
                      class="img-fluid"
                      alt=""
                    />
                    No giveaway users at the moment
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(a, i) in 5" :key="i">
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                  <td><div class="lines shine" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <admin-mobile-footer /> -->
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import moment from "moment";
const { mapActions } = createNamespacedHelpers("salon");
import { index } from "@/api/resource";
export default {
  data() {
    return {
      give_aways: [],
      isLoading: false,
      stats: {}
    };
  },
  components: {
    AdminMobileFooter: () => import("@/components/Footer/AdminMobileFooter")
  },
  methods: {
    getShops() {
      this.isLoading = true;
      index("/api/all-give-aways")
        .then(response => {
          const { data } = response;
          this.give_aways = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatDate(d) {
      return d ? moment(d).format("Do MMM, YYYY hh:mm A") : "";
    }
  },
  created() {
    this.getShops();
  }
};
</script>

<style>
div >>> .b-mobile-bar {
  bottom: 90px !important;
}
</style>

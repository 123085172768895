import Vue from "vue";
import moment from "moment";

Vue.filter('initials', (name) => {
    return name.split(" ").map((n)=>n[0]).join(".");
})

Vue.filter('toCurrencyFormat', (value) => {
    if(isNaN(value)){
        return value;
    }
    return process.env.VUE_APP_PRICE_SYMBOL + " " + (Math.round(value * 100) / 100).toFixed(2);
})

Vue.filter('toCurrencyFormatRaw', (value) => {
    if(isNaN(value)){
        return value;
    }
    return process.env.VUE_APP_PRICE_SYMBOL + value;
})

Vue.filter('toCurrency', (value) => {
    if(isNaN(value)){
        return (Math.round(value * 100) / 100).toFixed(2);
    }
    return value;
})

Vue.filter('capitalizeFirstLetter', (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
})

Vue.filter('formattedTime', (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
        if (remainingMinutes > 0) {
            return `${hours} hrs ${remainingMinutes} mins`;
        } else {
            return `${hours} hrs`;
        }
    } else {
        return `${minutes} mins`;
    }
})



Vue.filter('formatToK', (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
})

Vue.filter('to4DigitsLeadingZeros', (num, size = 4) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
})

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

Vue.filter('toFormattedDateTime', (value) => {
    return moment(value).format('MMMM Do YYYY h:mm a');
})

Vue.filter('toTime', (value) => {
    return moment(value).format('h:mm a');
})

Vue.filter('toBookingDateTime', (value) => {
    return moment(value).format('ddd, DD MMM YYYY');
})

Vue.filter('toSmBookingDateTime', (value) => {
    return moment(value).format('ddd, DD MMM YYYY');
})

Vue.filter('toFormattedDate', (value) => {
    return moment(value).format('MMM DD, YYYY');
})

<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header/>
    <div class="clearfix"></div>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar/>
      <!--Main Container -->
      <router-view/>
    </div>
    <!-- Modal -->
    <div v-if="isLoggedIn" class="modal fade" id="passwordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="update">
            <div class="modal-body">
              <div class="form-group" >
                <div>
                  <label for="old_password">Old Password *</label>
                  <input
                      type="password"
                      id="old_password"
                      placeholder="Old Password"
                      class="form-control form-control-alternative"
                      v-model="data.old_password"

                  />
                </div>

                <label class="mt-3" for="new_password">New Password *</label>
                <input
                    type="password"
                    id="new_password"
                    placeholder="New Password"
                    class="form-control form-control-alternative"
                    v-model="data.new_password"
                    minlength=8
                    required
                />
                <label class="mt-3" for="confirm_new_password">Confirm New Password *</label>
                <input
                    type="password"
                    id="confirm_new_password"
                    placeholder="Confirm New Password"
                    class="form-control form-control-alternative"
                    v-model="data.confirm_new_password"
                    minlength=8
                    required
                />
              </div>

            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Update Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "Components/AdminHeader/AdminHeader.vue";
import AdminSidebar from "Components/AdminSidebar/AdminSidebar.vue";
// import { setSalon } from "@/utils/auth";
import {mapGetters} from "vuex";
import {putUpdate, store} from "@/api/resource";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  components: {
    appAdminHeader: AdminHeader,
    appAdminSidebar: AdminSidebar
  },
  data() {
    return {
      data: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
        id: this.$store.state.user.salon.user.id
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "user_data",
      isLoggedIn: "isLoggedIn"

    })
  },
  methods: {

    update() {
      if (this.data.new_password === this.data.confirm_new_password) {
        // this.isLoading = true;
        putUpdate("/api/update-password", this.data).then(response => {
          const {success, message} = response;
          $('#passwordModal').modal('hide')
          if (success) {
            Swal.fire({
              title: "Success",
              text: message,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: message,
              icon: "error",
            });
          }
          this.data.confirm_new_password = '';
          this.data.old_password = '';
          this.data.new_password = '';

        }).catch(() => {
          $('#passwordModal').modal('hide')
          Swal.fire({
            title: "Error",
            text: "Ooops! There was an unexpected error",
            icon: "error",
          });
        });
      } else {
        alert("New passwords don't match");
      }
    }
    // getSalonInfo() {
    //   index("/api/salon/" + this.profile["salon_id"]).then(response => {
    //     const { data } = response;
    //     if (data["user"]["sign_up_stage"] < 7) {
    //       this.$router.push("/setup-profile");
    //     } else {
    //       setSalon(data);
    //     }
    //   });
    // }
  },
  created() {
    // if (this.profile["sign_up_stage"] < 7) {
    //   this.$router.push("/setup-profile");
    // }
  }
};
</script>

import Swal from "sweetalert2";
<template>
  <div class="dashboard-content">
    <div class="row responsive-row">
      <div class="col-lg-12">
        <button
          @click="$router.push('/shop-details/' + salon_name)"
          class="btn btn-link"
        >
          <i class="fa fa-arrow-left" /> Back to salon Info
        </button>
      </div>
      <!-- Listings -->
      <div class="col-lg-12 col-md-12">
        <div class="text-right mb-3">
          <router-link
            class="btn btn-info text-right"
            :to="'/add-new-service/' + salon_name"
            >Add New Service
          </router-link>
        </div>
        <div class="dashboard-list-box mb-5">
          <div class="db-tile ">
            <h4>Services</h4>
          </div>
          <div class="dashboard-list-box table-responsive invoices with-icons">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">Name</th>
                  <th scope="col">Price({{ getCurrency }})</th>
                  <th class="d-none d-sm-block">Category</th>
                  <th>Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="i" v-for="(list, i) in service_data">
                  <td
                    :class="list['inactive_category'] ? 'bg-disabled' : ''"
                    @click="
                      $router.push('/admin/update-service/' + list.service_id)
                    "
                  >
                    <div
                      class="text-center
"
                      v-if="list['images'].length > 0"
                    >
                      <img
                        :src="list['images'][0]['image']"
                        alt=""
                        class="img-fluid"
                        style="width: 40px"
                      />
                    </div>
                  </td>
                  <td
                    :class="list['inactive_category'] ? 'bg-disabled' : ''"
                    @click="
                      $router.push('/admin/update-service/' + list.service_id)
                    "
                  >
                    {{ list.service_name }}
                  </td>
                  <td
                    :class="list['inactive_category'] ? 'bg-disabled' : ''"
                    @click="
                      $router.push('/admin/update-service/' + list.service_id)
                    "
                  >
                    £{{ list.service_price }}
                  </td>
                  <td
                    :class="list['inactive_category'] ? 'bg-disabled' : ''"
                    @click="
                      $router.push('/admin/update-service/' + list.service_id)
                    "
                    class="d-none d-sm-block"
                  >
                    {{ list.service_category["service_category_name"] }}
                  </td>

                  <td :class="list['inactive_category'] ? 'bg-disabled' : ''">
                    <div class="custom-control custom-switch">
                      <input
                        :checked="list.status === 'active'"
                        :id="'customSwitch' + i"
                        :disabled="list['inactive_category']"
                        @change="updateService(list)"
                        class="custom-control-input"
                        type="checkbox"
                        v-model="list.check_box_status"
                      />
                      <label
                        :for="'customSwitch' + i"
                        class="custom-control-label"
                        v-if="list.status === 'active'"
                        >Active</label
                      >
                      <label
                        :for="'customSwitch' + i"
                        class="custom-control-label"
                        v-else
                        >Disabled</label
                      >
                    </div>
                  </td>
                  <td :class="list['inactive_category'] ? 'bg-disabled' : ''">
                    <router-link
                      :to="
                        '/update-service/' +
                          list.service_id +
                          '/salon/' +
                          salon_name
                      "
                    >
                      <i class="fa fa-edit fa-2x"
                    /></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <mobilefooter />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import Swal from "sweetalert2";

const { mapActions } = createNamespacedHelpers("service");
import { index } from "@/api/resource";

export default {
  data() {
    return {
      service_data: [],
      api_error: false,
      salon_id: this.$route.params.id,
      salon_name: this.$route.params.name
    };
  },
  components: {
    mobilefooter: () => import("@/components/Footer/MobileFooter")
  },
  mounted() {
    this.getSaloonDetail();
  },

  computed: {
    ...mapGetters({
      // data: "services",
      host: "host",
      profile: "salon_user"
    }),
    getCurrency() {
      return process.env.VUE_APP_PRICE_SYMBOL
    },
    image() {
      return image =>
        image.length > 0 ? this.host + image[image.length - 1].image : "";
    }
  },

  methods: {
    ...mapActions(["delete"]),

    getSaloonDetail() {
      if (this.salon_id) {
        this.isLoading = true;
        index("/api/services/" + this.salon_id)
          .then(response => {
            const { data } = response;
            this.service_data = data;
            this.service_data.forEach(e => {
              e.check_box_status = e.status === "active";
            });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteService(id) {
      let r = confirm("Are you sure you want to delete the service?");
      if (r) {
        this.delete(id);
      }
    },
    updateService(service) {
      if (service.check_box_status) {
        service.status = "active";
      } else {
        service.status = "disabled";
      }
      this.$store.dispatch("service/update", service).then(() => {
        Swal.fire({
          title: "Success!",
          text: "Service status has been updated successfully",
          icon: "success",
          confirmButtonText: "close"
        });
      });
    }
  }
};
</script>

import axios from "../axios/axios-stylemeadmin";
import {useBearerTokenHeaders} from "@/utils/auth";

export const apisService = {
    addToWaitingList,
    getWaitingList,
    getShopRatings,
    changeRatingStatus,
    rateShop,
    getShopReviews,
    getShopOpeningHours,
    getBookingDetails,
    createPayment,
    getShopPaymentInfo,
    updateShopPaymentInfo,
    saveShopAppointment,
    getBookingByStatus,
    getBillingContact,
    deleteShop
}


function addToWaitingList(formData) {
    return new Promise((resolve) => {
        axios.post('/api/save-waiting-list', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function saveShopAppointment(formData) {
    return new Promise((resolve) => {
        axios.post('/api/appointments', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function updateShopPaymentInfo(formData) {
    return new Promise((resolve) => {
        axios.post('/api/salon/update-payment', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function createPayment(formData) {
    return new Promise((resolve) => {
        axios.post('/api/payment', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function rateShop(formData) {
    return new Promise((resolve) => {
        axios.post('/api/user-rating', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function changeRatingStatus(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/api/change-rating-status', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function deleteShop(id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/delete-shop/${id}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}


function getWaitingList() {
    return new Promise((resolve) => {
        axios.get('/api/get-waiting-list', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));

    })
}

function getBookingByStatus(status) {
    return new Promise((resolve) => {
        axios.get(`/api/get-booking-by-status?status=${status ? status : ''}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));

    })
}

function getBillingContact(user_id) {
    return new Promise((resolve) => {
        axios.get(`/api/get-billing-contact?user_id=${user_id}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));

    })
}

function getBookingDetails(id) {
    return new Promise((resolve) => {
        axios.get(`/api/booking-details/${id}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));

    })
}

function getShopRatings() {
    return new Promise((resolve, reject) => {
        axios.get('/api/get-shop-ratings', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));

    })
}

function getShopPaymentInfo(shop_id) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/salon/payment-information/${shop_id}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve({success: false, message: err.response.message})
        });

    })
}

function getShopReviews(shop_id) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/get-shop-ratings/${shop_id}`)
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}

function getShopOpeningHours(salon_id) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/get-shop-opening-hours/${salon_id}`,)
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({success: false, message: err}));
    })
}


<!-- Header Structure -->
<template>
  <div>
    <header class="header-global bg-primary admin-header">
      <nav
        id="navbar-main"
        class="navbar fixed-top navbar-main bg-primary navbar-expand-lg navbar-transparent navbar-light headroom "
      >
        <div class="container-fluid">
          <div class="logo">
            <router-link to="/"
              ><img src="/static/images/logo.png" width="65" alt="" class="logo"
            /></router-link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon" />
          </button>
          <div class="navbar-collapse collapse" id="navbar_global">
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <div class="logo">
                    <router-link to="/"
                      ><img src="/static/images/logo.png" width="65" alt=""
                    /></router-link>
                  </div>
                </div>
                <div class="col-6 collapse-close">
                  <button
                    type="button"
                    class="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbar_global"
                    aria-controls="navbar_global"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <div></div>
            <!--          <ul-->
            <!--            class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto"-->
            <!--          >-->
            <!--            <app-menu />-->
            <!--          </ul>-->
            <ul class="navbar-nav ml-lg-auto">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbar-primary_dropdown_1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><img
                    :src="
                      (profile ||{})['user_image']
                        ? profile['user_image']
                        : '../img/avatar.png'
                    "
                    class="img-fluid rounded-circle shadow-lg mr-1"
                    style="width: 40px; height: 40px; object-fit: cover;"
                    alt=""
                  />{{ (profile ||{})["name"]?profile["name"]:'' }}</a
                >
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbar-primary_dropdown_1"
                >
                  <router-link
                    to="/admin/personal-profile"
                    class="dropdown-item"
                    >Profile</router-link
                  >
                  <router-link
                    to="/admin/settings"
                    class="dropdown-item cursor-pointer"
                    >Settings</router-link
                  >
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item cursor-pointer" @click="logout"
                    >Logout</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import Menu from "Components/Menu/Menu.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    appMenu: Menu
  },
  computed: {
    ...mapGetters({
      profile: "user_data",
      salon_profile: "salon_user"
    }),
    role() {
      return this.$store.getters.roles;
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    console.log(this.$store.state.salon,'salon info')
  }
};
</script>

<style scoped>
@media screen and (max-width: 567px) {
  .logo {
    width: 40px !important;
  }
}
.navbar {
  padding: 0.2rem 1rem !important;
}
</style>

<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar d-none d-md-block d-lg-block">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <router-link v-if="this.$store.state.user.user !=null && $store.state.user.user.role ==='ADMIN'"
            to="/super-admin/home"
            class="text-dark pl-2"
            style="font-size:20px"
            ><i class="fa fa-arrow-left" /> Back to Admin Dashboard</router-link
          >
          <!--				<ul data-submenu-title="Main">-->
          <ul>
            <li class="active">
              <router-link to="/admin/dashboard"
                ><i class="fa fa-tachometer" /> Dashboard</router-link
              >
            </li>
            <li>
              <router-link to="/admin/bookings"
                ><i class=" fa fa-users" /> Appointments</router-link
              >
            </li>
            <li>
              <router-link to="/admin/advanced-booking-settings"
                ><i class=" fa fa-calendar" /> Calendar</router-link
              >
            </li>

            <li>
              <router-link to="/admin/settings"
                ><i class="fa fa-cogs" /> Settings</router-link
              >
            </li>

            <li @click.prevent="logout">
              <router-link to="/"
                ><i class="fa fa-sign-out" /> Logout</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <i class="fa fa-reorder" />
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch(error => {
          console.log(error);
        });
    },

    toggleMenu() {
      if ($("#wrapper").hasClass("sidebar-in")) {
        $("#wrapper").removeClass("sidebar-in");
      } else {
        $("#wrapper").addClass("sidebar-in");
      }
    }
  }
};
</script>

<style scoped>
li a {
  font-size: 25px !important;
}

.back-to-admin {
  font-size: 18px !important;
}

.dashboard-nav ul li a {
  padding: 20px 27px;
}

i {
  margin-right: 10px !important;
}
</style>

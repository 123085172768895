<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-customer-header />

    <div class="clearfix"></div>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <slot name="sidebar" />
      <!--Main Container -->
      <router-view />
    </div>
  </div>
</template>

<script>
import CustomerHeader from "Components/Header/Header.vue";
import AdminSidebar from "Components/Customer/CustomerSidebar.vue";

export default {
  components: {
    appCustomerHeader: CustomerHeader,
    appAdminSidebar: AdminSidebar
  }
};
</script>
